import { Col, Form, FormInstance, Row, message } from 'antd';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { dkoObrazacStore } from 'modules/obrasci/store/dko/dko.store';
import { IGetDkoObrazac, PotvrdaOdjava } from 'modules/obrasci/store/dko/dko.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './DkoOpsteWizardTab.module.scss';
import { authStore } from 'store';
import { Button } from 'components/Button/Button';
import dayjs from 'dayjs';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
// import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';

type FormProps = {
  form: FormInstance;
};

export const DkoOpsteWizardTab: React.FC<FormProps> = observer(({ form }) => {
  const { t } = useTranslation();
  const [indexNumber, setIndexNumber] = useState(false);

  useEffect(() => {
    setIndexNumber(!!form.getFieldValue('indeksni_broj_otpada_id'));
    if (
      obrasciStore.opste.preduzece_id &&
      obrasciStore.opste.postrojenje_id &&
      form.getFieldValue('indeksni_broj_otpada_id')
    ) {
      obrasciStore.setDisabledTabButton(false);
    }
  }, [obrasciStore.opste, Form.useWatch('indeksni_broj_otpada_id')]);

  useEffect(() => {
    if (dkoObrazacStore.trebaPotvrditi) {
      dkoObrazacStore.handleChange('trebaPotvrditi', false);
      message.warning(t('OBRASCI.DKO.POTREBNA_POTVRDA'));
    }
  }, [dkoObrazacStore.trebaPotvrditi]);

  // const checkIfObrazacExists = useObrazacExistsCheck();

  const wasteCatalogueOptions = (options: IGetWasteCatalogue[]) => {
    return options.map(wasteOption => ({
      label: `${wasteOption.index_number} ${wasteOption.waste_sr.toUpperCase()}`,
      value: wasteOption.id,
    }));
  };

  const defaultTypeOfWaste: ISifarnikInitial | undefined = dkoObrazacStore.getInitialState.indeksni_broj_otpada_id
    ? {
        value: dkoObrazacStore.getInitialState.indeksni_broj_otpada_id,
        label: `${(dkoObrazacStore.getInitialState as IGetDkoObrazac).indeksni_broj_otpada.index_number} ${(
          dkoObrazacStore.getInitialState as IGetDkoObrazac
        ).indeksni_broj_otpada.waste_sr.toUpperCase()}`,
      }
    : undefined;

  const handlePreduzeceChange = (_: any, option: any) => {
    form.setFieldValue('proizvodjac_vlasnik_otpada_preduzece', option.item.kraci_naziv);
  };

  const checkDateisAfter = () => {
    if (dkoObrazacStore.bDatumPredaje) {
      return dayjs(dkoObrazacStore.bDatumPredaje).isAfter(dkoObrazacStore.currentDate, 'day');
    }
  };

  const checkDateisBefore = () => {
    if (dkoObrazacStore.bDatumPredaje) {
      return dayjs(dkoObrazacStore.bDatumPredaje).isBefore(dkoObrazacStore.currentDate, 'day');
    }
  };

  const handleStatusObrasca = () => {
    if (dkoObrazacStore.initialState.potvrda_dokumenta) return <h4 style={{ color: 'green' }}>Potvrđen</h4>;
    else if (dkoObrazacStore.initialState.odjava_kretanja)
      return <h4 style={{ color: 'red' }}>Odjavljen od strane korisnika</h4>;
    else if (dkoObrazacStore.initialState.admin_odjava_dokumenta)
      return <h4 style={{ color: 'red' }}>Odjavljen od strane administratora</h4>;
    return <h4>Najavljen</h4>;
  };

  const potvrdiDko = async () => {
    const potvrdaPayloadFields = ['masa_otpada', 'registarski_broj_prevoznog_sredstva', 'napomena'];
    try {
      await form.validateFields(potvrdaPayloadFields);
      dkoObrazacStore.putPotvrdaOdjava(PotvrdaOdjava.Potvrda, form.getFieldsValue(potvrdaPayloadFields));
    } catch (errorInfo: any) {
      if (!errorInfo?.errorFields) return;
      const parsedErrorFields = errorInfo.errorFields.map((field: { name: string[] }, index: number) => (
        <li key={index}>
          {field.name[0]
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(' ')}
        </li>
      ));
      const errorMessageContent = (
        <div>
          <span>Pogrešan unos za polja:</span>
          <ul>{parsedErrorFields}</ul>
        </div>
      );
      return message.warning(errorMessageContent);
    }
  };

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje
        withPostrojenje={true}
        withoutGodina={true}
        store={dkoObrazacStore}
        onChangePreduzece={handlePreduzeceChange}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'indeksni_broj_otpada_id'}
        fetchOptinsList={dkoObrazacStore.fetchWasteCatalougeList}
        labelOptionAccessor="index_number"
        valueOptionAccessor="id"
        rules={[REQUIRED_FIELD_RULE(true)]}
        inputName={'indeksni_broj_otpada_id'}
        label={t('OBRASCI.KOM_1.TYPE_OF_WASTE')}
        colon={true}
        defaultOption={defaultTypeOfWaste}
        placeholder={''}
        disabled={!!indexNumber}
        optionsMaker={wasteCatalogueOptions}
        // onChange={value => checkIfObrazacExists({ indeksni_broj_otpada_id: value })}
      />
      {(dkoObrazacStore.initialState as IGetDkoObrazac).id && (
        <>
          <Row className={styles.statusDiv}>
            <p>Broj dokumenta:</p>
            <h4>{dkoObrazacStore.initialState.broj_dokumenta}</h4>
          </Row>
          <Row className={styles.statusDiv}>
            <p>Trenutni status dokumenta:</p>
            {handleStatusObrasca()}
          </Row>
          <div className={styles.buttonDiv}>
            <Popconfirm
              title={undefined}
              placement="leftTop"
              okText={t('YES')}
              icon={<span />}
              cancelText={t('NO')}
              description={t('OBRASCI.DKO.POTVRDA_PITANJE')}
              onConfirm={potvrdiDko}
              name="potvrdi-obrazac"
              okButtonProps={{ disabled: false }}
              cancelButtonProps={{ disabled: false }}
            >
              <Button
                name={'potvrda'}
                disabled={
                  checkDateisAfter() ||
                  (dkoObrazacStore.initialState as IGetDkoObrazac).potvrda_dokumenta ||
                  (dkoObrazacStore.initialState as IGetDkoObrazac).odjava_kretanja ||
                  (dkoObrazacStore.initialState as IGetDkoObrazac).admin_odjava_dokumenta
                }
              >
                Potvrda
              </Button>
            </Popconfirm>
            {!authStore.isAdmin ? (
              <Popconfirm
                title={undefined}
                placement="leftTop"
                okText={t('YES')}
                icon={<span />}
                cancelText={t('NO')}
                description={t('OBRASCI.DKO.ODJAVA_PITANJE')}
                onConfirm={() => dkoObrazacStore.putPotvrdaOdjava(PotvrdaOdjava.Odjava)}
                name="odjavi-obrazac"
                okButtonProps={{ disabled: false }}
                cancelButtonProps={{ disabled: false }}
              >
                <Button
                  name={'odjava'}
                  danger={true}
                  disabled={
                    checkDateisBefore() ||
                    (dkoObrazacStore.initialState as IGetDkoObrazac).potvrda_dokumenta ||
                    (dkoObrazacStore.initialState as IGetDkoObrazac).odjava_kretanja ||
                    (dkoObrazacStore.initialState as IGetDkoObrazac).admin_odjava_dokumenta
                  }
                >
                  Odjava
                </Button>
              </Popconfirm>
            ) : (
              <>
                <Popconfirm
                  title={undefined}
                  placement="leftTop"
                  okText={t('YES')}
                  icon={<span />}
                  cancelText={t('NO')}
                  description={t('Da li ste sigurni da želite da vratite obrazac u status najave?')}
                  onConfirm={() => dkoObrazacStore.putPotvrdaOdjava(PotvrdaOdjava.Najava)}
                  okButtonProps={{ disabled: false }}
                  cancelButtonProps={{ disabled: false }}
                  name="vrati-u-najavu-admin"
                >
                  <Button
                    name={'najava'}
                    danger={true}
                    disabled={
                      !(dkoObrazacStore.initialState as IGetDkoObrazac).potvrda_dokumenta &&
                      !(dkoObrazacStore.initialState as IGetDkoObrazac).odjava_kretanja &&
                      !(dkoObrazacStore.initialState as IGetDkoObrazac).admin_odjava_dokumenta
                    }
                  >
                    Vrati u status najave
                  </Button>
                </Popconfirm>
                <Popconfirm
                  title={undefined}
                  placement="leftTop"
                  okText={t('YES')}
                  icon={<span />}
                  cancelText={t('NO')}
                  description={t('OBRASCI.DKO.ODJAVA_PITANJE')}
                  onConfirm={() => dkoObrazacStore.putPotvrdaOdjava(PotvrdaOdjava.AdminOdjava)}
                  name="odjavi-obrazac-admin"
                  okButtonProps={{ disabled: false }}
                  cancelButtonProps={{ disabled: false }}
                >
                  <Button
                    name={'admin_odjava'}
                    danger={true}
                    disabled={
                      (dkoObrazacStore.initialState as IGetDkoObrazac).potvrda_dokumenta ||
                      (dkoObrazacStore.initialState as IGetDkoObrazac).odjava_kretanja ||
                      (dkoObrazacStore.initialState as IGetDkoObrazac).admin_odjava_dokumenta
                    }
                  >
                    Admin odjava
                  </Button>
                </Popconfirm>
              </>
            )}
          </div>
        </>
      )}
    </Col>
  );
});
