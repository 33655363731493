import { axios } from 'modules/axios';

import {
  CheckForNewIntersection,
  BaseIntersectionResponse,
  EvropskiIzvestajiAllReports,
  EvropskiIzvestajTypeEnum,
  IEvropskiIzvestajFilters,
} from './evropskiIzvestaji.types';
import { ITablePagination } from 'components/TableData/TableData';
import { IPaginatedApiResponse } from '../../typescript/NrizTypes';

/**
 * Evropski Izvestaji Repo class
 */
class EvropskiIzvestajiRepo {
  /**
   * Base url
   * @type string
   */
  private izvestajiBaseUrl = '/evropski-izvestaji';

  /**
   * Method that calls api to check if admin can create a new intersection
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   * @return { Promise<CheckForNewIntersection> }
   */
  public checkVisibilityOfIntersection = (
    izvestajType: EvropskiIzvestajTypeEnum,
    godina: number
  ): Promise<CheckForNewIntersection> => {
    return axios.post(`${this.izvestajiBaseUrl}/proveri-kreiran-presek`, {
      type: izvestajType,
      godina,
    });
  };

  /**
   * Method that calls api to create a new intersection
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   * @return { Promise<BaseIntersectionResponse> }
   */
  public createIntersection = (
    izvestajType: EvropskiIzvestajTypeEnum,
    godina: number
  ): Promise<BaseIntersectionResponse> => {
    return axios.post(`${this.izvestajiBaseUrl}/kreiraj-presek`, {
      type: izvestajType,
      godina,
    });
  };

  /**
   * Method that calls api for new data
   * @param { IEvropskiIzvestajFilters } payload
   * @param { ITablePagination } pagination
   * @return { IPaginatedApiResponse<EvropskiIzvestajiAllReports> }
   */
  public getEvropskiIzvestajByType = (
    payload: IEvropskiIzvestajFilters,
    pagination: ITablePagination
  ): IPaginatedApiResponse<EvropskiIzvestajiAllReports> => {
    return axios.post(`${this.izvestajiBaseUrl}/`, payload, { params: pagination });
  };

  /**
   * Method that calls api to change status
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   * @param { string } id
   * @param { string } status
   * @return { Promise<void> }
   */
  public changeStatus = (
    izvestajType: EvropskiIzvestajTypeEnum,
    godina: number,
    id: string,
    status: string
  ): Promise<void> => {
    return axios.put(`${this.izvestajiBaseUrl}/izmeni-status-postrojenja`, {
      type: izvestajType,
      godina,
      id,
      status,
    });
  };

  /**
   * Method that calls api to cancel intersection
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   * @return { Promise<BaseIntersectionResponse> }
   */
  public cancelIntersection = (
    izvestajType: EvropskiIzvestajTypeEnum,
    godina: number
  ): Promise<BaseIntersectionResponse> => {
    return axios.delete(`${this.izvestajiBaseUrl}/odustani-od-preseka`, {
      data: {
        type: izvestajType,
        godina,
      },
    });
  };

  /**
   * Method that calls api to generate the Excel file
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   * @return { Promise<Blob> }
   */
  public generateExcel = (izvestajType: EvropskiIzvestajTypeEnum, godina: number): Promise<Blob> => {
    return axios.post(
      `${this.izvestajiBaseUrl}/stampaj-presek`,
      {
        type: izvestajType,
        godina,
      },
      {
        responseType: 'blob',
      }
    );
  };
}

/**
 * Evropski Izvestaji Repo constant as instance of EvropskiIzvestajiRepo class
 */
export const evropskiIzvestajiRepo: EvropskiIzvestajiRepo = new EvropskiIzvestajiRepo();
