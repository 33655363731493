import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { OPERATERI_FILTER_OPTIONS } from 'modules/registarDozvola/operateri/operateri.constants';
import { operateriStore } from 'modules/registarDozvola/operateri/operateri.store';
import { FiltersNameEnum } from 'store/filteri.store';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const OperateriFilters = observer(() => {
  const [form] = useForm();

  const data: IFilterTableProps = {
    form,
    initialState: {},
    filterStoreName: FiltersNameEnum.DOZVOLE_OPERATERI,
    fetchTableList: () => operateriStore.fetchOperateriList(paginationInitialState),
    filterOptions: OPERATERI_FILTER_OPTIONS,
  };

  return (
    <div id="obrasciFilters">
      <FilterTableForm data={data} />
    </div>
  );
});
