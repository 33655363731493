import { ColumnType } from 'antd/es/table';
import { INacinPostupanjaSaOtpadom, IOtpadRequest, IOtpadResponse } from './otpad.types';
import { Checkbox } from 'antd';
import i18n from 'translations/i18n.config';
import {
  CheckboxGroupSifarniciFormProps,
  ISifarniciTypeOfForm,
  SearchScrollSifarniciFormProps,
  SIFARNICI_ROUTES,
} from 'modules/sifarnici/sifarnici.types';

export const otpadTypes: (keyof IOtpadRequest)[] = [
  'sakupljanje',
  'skladistenje',
  'transport',
  'tretman_odlaganje',
  'tretman_ponovno_iskoriscenje',
];

export const nacinPostupanjaColumns: ColumnType<INacinPostupanjaSaOtpadom>[] = [
  {
    title: 'D/R oznaka',
    render: (_: any, record: INacinPostupanjaSaOtpadom) => (
      <span>{`${record.dr_oznaka?.name} ${record.dr_oznaka?.description_sr}`}</span>
    ),
    key: 'dr_oznaka',
  },
  {
    title: 'Kapacitet u jednom trenutku (t)',
    dataIndex: 'kapacitet_u_jednom_trenutku',
    key: 'kapacitet_u_jednom_trenutku',
  },
  {
    title: 'Količina godišnje (t)',
    dataIndex: 'kolicina_godisnje',
    key: 'kolicina_godisnje',
  },
];

export const otpadListColumns: ColumnType<IOtpadResponse>[] = [
  {
    title: 'Indeksni broj otpada',
    render: (_: any, record: IOtpadResponse) => (
      <span>{`${record?.indeksni_broj_otpada.index_number} ${record?.indeksni_broj_otpada.waste_sr}`}</span>
    ),
    key: 'indeksni_broj_otpada',
  },

  ...otpadTypes.map(otpadType => {
    const title = i18n.t(`DOZVOLE_ZA_OTPAD.DOZVOLE.VRSTA_DOZVOLE.${otpadType.toUpperCase()}`);

    return {
      title,
      render: (_: any, record: IOtpadResponse) => <Checkbox checked={!!record[otpadType]} disabled={true} />,
      key: otpadType,
      width: 150,
      //@ts-ignore hack because ts doesnt see 'center' as valid align type
      align: 'center' as unknown as undefined,
    };
  }),
];

export const OTPAD_INITIAL_STATE: IOtpadRequest = {
  indeksni_broj_otpada_id: '',
  sakupljanje: false,
  transport: false,
  skladistenje: false,
  tretman_odlaganje: false,
  tretman_ponovno_iskoriscenje: false,
  dozvola_id: '',
  nacin_postupanja_sa_otpadom: [],
};

const VRSTA_DOZVOLE_FILTER: CheckboxGroupSifarniciFormProps = {
  type_of_form: 'checkbox-group',
  groupName: 'Vrsta dozvole',
  filterColSpan: 16,
  checkboxConfig: [
    {
      label: 'Sakupljanje',
      name: 'sakupljanje',
    },
    {
      label: 'Transport',
      name: 'transport',
    },
    {
      label: 'Skladištenje',
      name: 'skladistenje',
    },
    {
      label: 'Tretman - odglaganje',
      name: 'tretman_odlaganje',
    },
    {
      label: 'Tretman - ponovno iskorišćenje',
      name: 'tretman_ponovno_iskoriscenje',
    },
  ],
};

const INDEKSNI_BROJ_OTPADA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'indeksni_broj_otpada_id',
  fetchOptinsList: SIFARNICI_ROUTES.WASTE_CATALOGUE,
  labelOptionAccessor: ['index_number', 'waste_sr'],
  valueOptionAccessor: 'id',
  inputName: 'indeksni_broj_otpada_id',
  label: 'Indeksni broj otpada',
  filterColSpan: 8,
};

export const OTPAD_DOZVOLE_FILTER_OPTIONS: ISifarniciTypeOfForm[] = [INDEKSNI_BROJ_OTPADA_FILTER, VRSTA_DOZVOLE_FILTER];
