/**
 * Method that returns file picker instance
 * @param { string } fileName
 * @param { string } fileType
 * @param { string } fileExtension
 */
export const getSaveFilePicker = async (fileName: string, fileType: string, fileExtension: string) => {
  const opts = {
    suggestedName: fileName,
    types: [
      {
        accept: { [fileType]: [`.${fileExtension}`] },
      },
    ],
  };
  return await (<any>window).showSaveFilePicker(opts);
};
