export interface IGetPzvPreseciList {
  broj_preduzeca: string;
  broj_zapisa: string;
  broj_izvestaja: string;
  datum_izvestaja: string | Date;
  datum_slanja: string | Date;
  datum_preseka: string | Date;
  id: string;
  ukupna_količina_proizvoda_deterdženti: number;
  ukupna_količina_fosfata_deterdženti: number;
  ukupna_količina_proizvoda_mineralna_đubriva: number;
  ukupna_količina_azota_mineralna_đubriva: number;
  ukupna_količina_proizvoda_sredstva_zaštitu_bilja: number;
  ukupna_količina_aktivne_sredstva_zaštitu_bilja: number;
}

export interface IFiltersPzvPreseciList {
  datum_slanja__gte?: string | Date;
  datum_slanja__lte?: string | Date;
  preduzece_id?: string;
  grupa_proizvoda: GrupaProizvodaPzvEnum | null;
}

export interface IGetPzvSinglePresek {
  datum_preseka: string | Date;
  pib: string;
  maticni_broj: string;
  naziv_preduzeca: string;
  mesto_preduzeca: string;
  opstina_preduzeca: string;
  ulica_preduzeca: string;
  iskljucen: boolean;
  broj_izvestaja: string;
  id: string;
  status: string;
  automatski_iskljucen: boolean;
  godina: string | Date;
  grupa_proizvoda: GrupaProizvodaPzvEnum;
  ukupna_kolicina_grupu_proizvoda: string;
  ukupna_kolicina_materije: string;
}

export interface IFiltersPzvSinglePresek {
  maticni_broj__ilike?: string;
  godina?: string;
  pib__ilike?: string;
  preduzece_id?: string;
  grupa_proizvoda?: GrupaProizvodaPzvEnum | null;
  iskljucen?: boolean | null;
}

export enum GrupaProizvodaPzvEnum {
  DETERDZENTI = 'deterdzenti',
  MINERALNA_DJUBRIVA = 'mineralna_djubriva',
  ZASTITA_BILJA = 'zastita_bilja',
}
