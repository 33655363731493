import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import {
  DOZVOLE_FILTER_OPTIONS,
  DOZVOLE_FILTERS_INITIAL_STATE,
} from 'modules/registarDozvola/dozvole/dozvole.constants';
import { dozvoleStore } from 'modules/registarDozvola/dozvole/dozvole.store';
import { operateriStore } from 'modules/registarDozvola/operateri/operateri.store';
import { FiltersNameEnum } from 'store/filteri.store';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const DozvoleFilters = observer(() => {
  const [form] = useForm();

  const data: IFilterTableProps = {
    form,
    initialState: DOZVOLE_FILTERS_INITIAL_STATE,
    filterStoreName: FiltersNameEnum.DOZVOLE_DOZVOLE,
    fetchTableList: () => dozvoleStore.fetchDozvoleList(paginationInitialState, operateriStore.selectedOperater.id),
    filterOptions: DOZVOLE_FILTER_OPTIONS(form),
    showHideFilters: true,
  };

  return (
    <div id="obrasciFilters">
      <FilterTableForm data={data} />
    </div>
  );
});
