import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';
import { IOtpadRequest, IOtpadResponse } from './otpad.types';

const OTPAD_BASE_ROUTE = `${ROUTES.REGISTAR_DOZVOLA}-otpad/`;

const fetchOtpadList = (params: any): IPaginatedApiResponse<IOtpadResponse> => {
  const query = queryString.stringify(params);
  return axios.get(`${OTPAD_BASE_ROUTE}?${query}`);
};

const createOtpad = (payload: IOtpadRequest): ApiResponse<{ id: string }> => {
  return axios.post(OTPAD_BASE_ROUTE, payload);
};

const updateOtpadInfo = (id: string, payload: Partial<IOtpadResponse>) => {
  return axios.put(`${OTPAD_BASE_ROUTE}${id}`, payload);
};

const fetchOtpadById = (id: string): ApiResponse<IOtpadResponse> => {
  return axios.get(`${OTPAD_BASE_ROUTE}${id}`);
};

const deleteOtpad = (id: string) => {
  return axios.delete(`${OTPAD_BASE_ROUTE}${id}`);
};

export const otpadRepo = {
  fetchOtpadList,
  createOtpad,
  updateOtpadInfo,
  fetchOtpadById,
  deleteOtpad,
};
