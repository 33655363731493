export enum EvropskiIzvestajTypeEnum {
  EU_REGISTAR = 'eu_registar',
  EU_INTEGRATED_REPORT = 'eu_integrated_reports',
}

export enum EvropskiIzvestajLists {
  STATUS = 0,
  GENERATE_EXCEL = 1,
}

export interface CheckForNewIntersection {
  kreiran: boolean;
  message?: string | null;
}

export interface BaseIntersectionResponse {
  message: string;
}

export interface EuRegistarFilters {
  pib: string;
  maticni_broj: string;
  naziv_preduzeca: string;
  naziv_postrojenja: string;
  istorijski_id: string;
  nacionalni_id: string;
  status: string;
}

export interface EuIntegratedReportFilters {
  pib: string;
}

export type EvropskiIzvestajiAllFilters = EuRegistarFilters | EuIntegratedReportFilters;

export interface IEvropskiIzvestajFilters {
  type: EvropskiIzvestajTypeEnum;
  godina: number;
  filter_params: EvropskiIzvestajiAllFilters;
}

export interface EuRegistar {
  id: string;
  datum_preseka: string;
  godina: number;
  pib: string;
  maticni_broj: string;
  naziv_preduzeca: string;
  naziv_postrojenja: string;
  istorijski_id_postrojenja: string;
  nacionalni_id_postrojenja: string;
  koordinate_lat: number;
  koordinate_long: number;
  status: string;
}

export interface EuIntegratedReport {
  pib: string;
}
export type EvropskiIzvestajiAllReports = EuRegistar[] | EuIntegratedReport[];
