import { axios } from 'modules/axios';
import { IPregledDozvoleRow } from './pregledDozvola.types';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';

const fetchDozvoleList = (params: any): IPaginatedApiResponse<IPregledDozvoleRow> => {
  const query = queryString.stringify(params);
  return axios.get(`${ROUTES.REGISTAR_DOZVOLA}-pregled/?${query}`);
};

export const pregledDozvolaRepo = {
  fetchDozvoleList,
};
