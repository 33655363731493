import { ColumnsType } from 'antd/es/table';
import { IConfidentialityFilters, IGetConfidentiality, IPostConfidentiality } from './confidentiality.types';
import { FormInstance } from 'antd';
import { CODE_TABLE, DESCRIPTION_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';
import { CODE_FORM, DESCRIPTION_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const CONFIDENTIALITY_INITIAL_STATE: IPostConfidentiality = {
  code: '',
  description: '',
  is_active: true,
};
export const CONFIDENTIALITY_FILTERS_INITIAL_STATE: IConfidentialityFilters = {
  code__ilike: '',
  description__ilike: '',
  is_active: null,
};
export const CONFIDENTIALITY_TABLE_DATA: ColumnsType<IGetConfidentiality> = [CODE_TABLE, DESCRIPTION_TABLE];
export const CONFIDENTIALITY_FORM_OPTIONS = (_: FormInstance<IGetConfidentiality>): ISifarniciTypeOfForm[] => {
  return [{ ...CODE_FORM, type_of_form: 'input', rules: [MAX_LENGTH_RULE(10)] }, DESCRIPTION_FORM(100)];
};
