import to from 'await-to-js';
import { makeAutoObservable } from 'mobx';
import dayjs from 'dayjs';

import {
  CheckForNewIntersection,
  BaseIntersectionResponse,
  EvropskiIzvestajiAllReports,
  EvropskiIzvestajTypeEnum,
} from './evropskiIzvestaji.types';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IPaginatedResponse, IPagination } from 'typescript/NrizTypes';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { evropskiIzvestajiService } from './evropskiIzvestaji.service';
import { ITablePagination } from 'components/TableData/TableData';
import { isChromeOrEdge } from 'utils/helpers/browser-system.helper';
import { saveBlob } from 'utils/fileDownload';

/**
 * Evropski izvestaji store
 */
class EvropskiIzvestajiStore {
  /**
   * Evropski izvestaj
   * @type {  IPaginatedResponse<EvropskiIzvestajiAllReports> }
   */
  public evropskiIzvestaj: IPaginatedResponse<EvropskiIzvestajiAllReports> | null = null;

  /**
   * Evropski izvestaj pagination
   * @type {  IPagination }
   */
  public evropskiIzvestajPagination: IPagination = paginationInitialState;

  /**
   * Cane create intersection
   * @type { CheckForNewIntersection }
   */
  public visibilityOfIntersection: CheckForNewIntersection | null = null;

  /**
   * Writable instance
   * @type { }
   */
  private writable: any = null;

  /**
   * Evropski izvestaji store constructor
   */
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Method that sets evropski izvestaj
   * @param { IPaginatedResponse<EvropskiIzvestajiAllReports> } izvestaj
   */
  public setEvropskiIzvestaj = (izvestaj: IPaginatedResponse<EvropskiIzvestajiAllReports>) => {
    this.evropskiIzvestaj = izvestaj;
  };

  /**
   * Method that sets pagination
   * @param { IPagination } pagination
   */
  public setEvropskiIzvestajPagination = (pagination: IPagination) => {
    this.evropskiIzvestajPagination = pagination;
  };

  /**
   * Method that sets indicator which enables/disables to create a new intersection
   * @param { CheckForNewIntersection } visibilityOfIntersection
   */
  public setVisibilityOfIntersection = (visibilityOfIntersection: CheckForNewIntersection) => {
    this.visibilityOfIntersection = visibilityOfIntersection;
  };

  /**
   * Method that sets writable instance
   * @param { any } writable
   */
  public setWritable = (writable: any) => {
    this.writable = writable;
  };

  /**
   * Method that calls method from service to check if new intersection can be make
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   */
  public checkVisibilityForNewIntersection = async (izvestajType: EvropskiIzvestajTypeEnum, godina: number) => {
    const [err, res] = await to<CheckForNewIntersection>(
      evropskiIzvestajiService.checkVisibilityOfIntersection(izvestajType, godina)
    );
    if (err) return Promise.reject(err);
    this.setVisibilityOfIntersection(res);
    if (res.kreiran === false) {
      await to<any>(this.getEvropskiIzvestaj(izvestajType, godina, paginationInitialState));
    }
  };

  /**
   * Method that calls method from service to create a new intersection
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   */
  public createIntersection = async (izvestajType: EvropskiIzvestajTypeEnum, godina: number) => {
    const [err, res] = await to<BaseIntersectionResponse>(
      evropskiIzvestajiService.createIntersection(izvestajType, godina)
    );
    if (err) return Promise.reject(err);
    if (res) {
      this.setVisibilityOfIntersection({
        kreiran: false,
        message: null,
      });
      await to<IPaginatedResponse<EvropskiIzvestajiAllReports>>(
        this.getEvropskiIzvestaj(izvestajType, godina, paginationInitialState)
      );
    }
  };

  /**
   * Method that calls method from service to create a new intersection
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   * @param { string } id
   * @param { string } status
   */
  public changeStatus = async (izvestajType: EvropskiIzvestajTypeEnum, godina: number, id: string, status: string) => {
    const [err] = await to<void>(evropskiIzvestajiService.changeStatus(izvestajType, godina, id, status));
    if (err) return Promise.reject(err);
    await to<IPaginatedResponse<EvropskiIzvestajiAllReports>>(
      this.getEvropskiIzvestaj(izvestajType, godina, paginationInitialState)
    );
  };

  /**
   * Method that gets new data
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { godina } godina
   * @param { IPagination } pagination
   */
  public getEvropskiIzvestaj = async (
    izvestajType: EvropskiIzvestajTypeEnum,
    godina: number,
    pagination?: ITablePagination
  ) => {
    const [err, res] = await to<IPaginatedResponse<EvropskiIzvestajiAllReports>>(
      evropskiIzvestajiService.getEvropskiIzvestajByType(
        {
          type: izvestajType,
          godina,
          filter_params: evropskiIzvestajiService.parseFilters(
            filtersStore.getFilters[FiltersNameEnum.EVROPSKI_IZVESTAJI]
          ),
        },
        {
          page: pagination?.page || this.evropskiIzvestajPagination.page,
          size: pagination?.size || this.evropskiIzvestajPagination.size,
        }
      )
    );
    if (err) return Promise.reject(err);
    const { page, pages, size, total } = res;
    this.setEvropskiIzvestaj(res);
    this.setEvropskiIzvestajPagination({ page, size, pages, total });
    return Promise.resolve(res);
  };

  /**
   * Method that cancels subtract
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   */
  public cancelSubtract = async (izvestajType: EvropskiIzvestajTypeEnum, godina: number) => {
    const [err] = await to<any>(evropskiIzvestajiService.cancelSubtract(izvestajType, godina));
    if (err) return Promise.reject(err);
    this.clearObrazacIzvestaj();
  };

  /**
   * Method that calls method from service to generate an Excel file
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   */
  public generateExcel = async (izvestajType: EvropskiIzvestajTypeEnum, godina: number) => {
    const [err, res] = await to<Blob>(evropskiIzvestajiService.generateExcel(izvestajType, godina));
    if (err) return Promise.reject(err);
    if (isChromeOrEdge()) {
      if (this.writable) {
        await this.writable.write(res);
        await this.writable.close();
      }
    } else {
      saveBlob(res, `eu_registar_${dayjs().format('DD-MM-YYYY')}`);
    }
    return Promise.resolve(res);
  };

  /**
   * Method that clears data
   */
  public clearObrazacIzvestaj = () => {
    this.evropskiIzvestaj = null;
    this.visibilityOfIntersection = null;
    this.setEvropskiIzvestajPagination(paginationInitialState);
  };
}

export const evropskiIzvestajiStore: EvropskiIzvestajiStore = new EvropskiIzvestajiStore();
