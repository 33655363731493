import React, { useMemo } from 'react';

import { Form, FormInstance, Modal, Row } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { EvropskiIzvestajLists } from 'modules/evropskiIzvestaji/evropskiIzvestaji.types';
import { StatusPostrojenjaForm } from '../StatusPostrojenjaForm/StatusPostrojenjaForm';
import { GenerateExcelForm } from '../GenerateExcelForm/GenerateExcelForm';
import { FormListConfig, FormListType } from 'typescript/NrizTypes';
import { Button } from 'components/Button/Button';
import { modalStore } from 'store';

interface FormModalProps<T> {
  parentForm: FormInstance<T>;
  name: string;
  title: string;
  initialValues?: any;
  buttons: Array<any>;
  configPath: FormListConfig;
}

/**
 * Evropski Izvestaji Form function component
 */
export const EvropskiIzvestajiForm = observer(
  <T extends Record<string, unknown>>({
    parentForm,
    name,
    title,
    initialValues,
    buttons,
    configPath,
  }: FormModalProps<T>) => {
    const { t } = useTranslation();
    const { formListType, formListSubType } = configPath;

    const handleFormItemCancel = () => {
      modalStore.clearModal(`${name}-modal`);
    };

    const FORM_TYPE = {
      [FormListType.EvropskiIzvestaji]: {
        [EvropskiIzvestajLists.STATUS]: <StatusPostrojenjaForm></StatusPostrojenjaForm>,
        [EvropskiIzvestajLists.GENERATE_EXCEL]: <GenerateExcelForm></GenerateExcelForm>,
      },
    };

    const component = useMemo(() => {
      // @ts-ignore
      return FORM_TYPE[formListType][formListSubType];
    }, [configPath]);

    /**
     * Method that returns tsx template
     */
    return (
      <Modal
        open={modalStore.getCurrentOpenedModal.includes(`${name}-modal`)}
        destroyOnClose
        onCancel={() => {
          parentForm.resetFields();
          modalStore.clearModal(`${name}-modal`);
        }}
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        title={t(title)}
        modalRender={formChildren => {
          return (
            <Form
              form={parentForm}
              name={'evropski_izvestaj-form-in-modal'}
              initialValues={initialValues}
              labelAlign="left"
            >
              {formChildren}
            </Form>
          );
        }}
      >
        {component && <div>{component}</div>}
        <Row style={{ justifyContent: 'right', gap: '15px' }}>
          {buttons.map((button: any) => {
            return (
              <Button name={`${name}-modal-submit-button`} onClick={button.onClick || (() => handleFormItemCancel())}>
                {t(button.title)}
              </Button>
            );
          })}
        </Row>
      </Modal>
    );
  }
);
