import i18n from 'translations/i18n.config';
import {
  DateSifarniciFormProps,
  InputSifarniciFormProps,
  SearchScrollSifarniciFormProps,
  SIFARNICI_ROUTES,
  SwitchSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { RuleConfig } from 'typescript';
import { FormInstance } from 'antd';

interface IFiltersPresekProps {
  ilike?: boolean;
  rules?: RuleConfig[];
}

export const DATUM_SLANJA_GTE_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'datum_slanja__gte',
  picker: 'date',
  format: 'DD-MM-YYYY',
  label: i18n.t('PRESECI.PERIOD_SLANJA_OD'),
};

export const DATUM_SLANJA_LTE_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'datum_slanja__lte',
  picker: 'date',
  format: 'DD-MM-YYYY',
  label: i18n.t('PRESECI.PERIOD_SLANJA_DO'),
};

export const DATUM_I_VREME_SLANJA_GTE_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'datum_i_vreme_slanja__gte',
  picker: 'date',
  format: 'DD-MM-YYYY',
  label: i18n.t('PRESECI.PERIOD_SLANJA_OD'),
};

export const DATUM_I_VREME_SLANJA_LTE_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'datum_i_vreme_slanja__lte',
  picker: 'date',
  format: 'DD-MM-YYYY',
  label: i18n.t('PRESECI.PERIOD_SLANJA_DO'),
};

export const ISKLJUCEN_FILTER: SwitchSifarniciFormProps = {
  type_of_form: 'switch',
  name: 'iskljucen',
  label: i18n.t('PRESECI.ISKLJUCEN'),
};

export const NAZIV_PREDUZECE_FILTER = (form?: FormInstance): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    label: i18n.t('PRESECI.NAZIV_PREDUZECA'),
    formName: 'obrazac_preduzece_id',
    labelOptionAccessor: ['naziv'],
    valueOptionAccessor: 'id',
    fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
    onChange: () => {
      form?.setFieldValue('postrojenje_id', '');
    },
  };
};

export const PIB_FILTER = ({ ilike = false, rules = [] }: IFiltersPresekProps = {}): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: i18n.t('PRESECI.PIB_PREDUZECA'),
    name: ilike ? 'pib__ilike' : 'pib',
    inputName: ilike ? 'pib__ilike' : 'pib',
    rules,
  };
};

export const PIB_PREDUZECA_FILTER = ({
  ilike = false,
  rules = [],
}: IFiltersPresekProps = {}): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: i18n.t('PRESECI.PIB_PREDUZECA'),
    name: ilike ? 'pib_preduzeca__ilike' : 'pib_preduzeca',
    inputName: ilike ? 'pib_preduzeca__ilike' : 'pib_preduzeca',
    rules,
  };
};

export const MATICNI_BROJ_FILTER = ({
  ilike = false,
  rules = [],
}: IFiltersPresekProps = {}): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: i18n.t('COMPANY.ENTERPRISE_ID_NUMBER'),
    name: ilike ? 'maticni_broj__ilike' : 'maticni_broj',
    inputName: ilike ? 'maticni_broj__ilike' : 'maticni_broj',
    rules,
  };
};

export const GODINA_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'godina',
  label: i18n.t('YEAR'),
  picker: 'year',
  format: 'YYYY',
};

export const NACIONALNI_ID_POSTROJENJA_FILTER = ({
  ilike = false,
}: IFiltersPresekProps = {}): InputSifarniciFormProps => {
  return {
    type_of_form: 'input',
    label: i18n.t('PRESECI.NACIONALNI_ID'),
    name: ilike ? 'nacionalni_id__ilike' : 'nacionalni_id',
    inputName: ilike ? 'nacionalni_id__ilike' : 'nacionalni_id',
  };
};

export const NAZIV_POSTROJENJA_FILTER = (form: FormInstance): SearchScrollSifarniciFormProps => {
  return {
    type_of_form: 'searchScroll',
    label: i18n.t('PRESECI.NAZIV_POSTROJENJA'),
    filtersForFetch: {
      preduzece_id: form.getFieldValue('preduzece_id'),
    },
    formName: 'postrojenje_id',
    labelOptionAccessor: ['naziv_postrojenja'],
    valueOptionAccessor: 'id',
    fetchOptinsList: SIFARNICI_ROUTES.POSTROJENJE,
  };
};
