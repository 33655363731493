import { Table as ANTTable, Pagination, TableProps } from 'antd';
import styles from './TableData.module.scss';
import { observer } from 'mobx-react-lite';
import { IPagination } from 'typescript';
import { nrizService } from 'modules/nriz/nriz.service';
import { localeEmpty } from 'modules/nriz/nriz.constants';
import { cloneDeep } from 'lodash';

import { GetComponentProps } from 'rc-table/lib/interface';
import { getTableScrollHeight } from 'utils/getTableScrollHeight';
import { ColumnProps } from 'antd/es/table';
import { ExpandableConfig } from 'antd/es/table/interface';
import { IPageSizePagination } from 'typescript/NrizTypes';

export interface ITablePagination {
  page: number;
  size: number;
}

interface ITableProps extends TableProps<any> {
  columns: ColumnProps<any>[] | any[];
  dataSource: any[];
  refetchData?: (pagination: IPageSizePagination) => void;
  rowKey?: string | number;
  onChange?: () => void;
  scrollX?: number;
  virtual?: boolean;
  name: string;
  rowClassName?: (e: any) => string;
  onRow?: GetComponentProps<any>;
  scrollConfig?: string[];
  subtractTableHeight?: number;
  expandable?: ExpandableConfig<any>;
  storePagination?: IPagination | undefined;
}

const TableData: React.FC<ITableProps> = ({ ...props }) => {
  const loadData = async (page: number, size: number) => {
    props.refetchData && (await props.refetchData({ page, size }));
  };

  const handlePaginationChange = (page: any, pageSize: any) => {
    loadData(page, pageSize);
  };

  const defaultOnRow = (_: any, index?: number) => ({
    'data-test': `${props.name}-row-${index}`,
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.tableWrapper}>
        <ANTTable
          onChange={() => props.onChange}
          columns={props.columns}
          scroll={{
            y: props.scrollConfig
              ? `calc(100vh - ${getTableScrollHeight(props.scrollConfig)}px - ${props.subtractTableHeight ?? 0}px) `
              : '400px',
          }}
          dataSource={cloneDeep(nrizService.addKey(props.dataSource))}
          virtual={props.virtual}
          bordered={true}
          locale={localeEmpty}
          pagination={false}
          rowClassName={record => (props.rowClassName ? `${styles[props.rowClassName(record)]}` : '')}
          rowKey={props.rowKey}
          expandable={props.expandable}
          //@ts-ignore
          onRow={props.onRow || defaultOnRow}
          data-test={props.name}
        />
        {props.storePagination && props.storePagination.total > 0 && (
          <div className={styles.pagination}>
            <Pagination
              current={props.storePagination?.page}
              pageSize={props.storePagination?.size}
              total={props.storePagination?.total}
              onChange={handlePaginationChange}
              showSizeChanger={true}
              showTotal={(_, range) =>
                `${range[0]}-${range[1]} od ${props.storePagination && props.storePagination?.total}`
              }
            />
          </div>
        )}
      </div>
    </div>
  );
};
export default observer(TableData);
