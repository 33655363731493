import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Button } from 'components/Button/Button';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormInput } from 'components/FormInput/FormInput';
import { Modal } from 'components/Modal/Modal';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { POSALJI_PTP2_PRESEK_FORM_INITIAL_STATE, PreseciTypeEnum } from 'modules/preseci/preseci.constants';
import { preseciStore } from 'modules/preseci/preseci.store';
import { IPosaljiPtp2PresekForm } from 'modules/preseci/preseci.types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import { modalStore } from 'store';

export const ModalPtp2PosaljiIzvestaj = observer(() => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigate = useNavigate();
  const onOdustani = () => {
    form.resetFields();
    modalStore.removeAllModals();
  };

  const posaljiIzvestaj = async (values: IPosaljiPtp2PresekForm) => {
    const validDatum = dayjs(values.datum_dopisa).format('YYYY-MM-DD');
    const poslat = await preseciStore.posaljiSinglePresek(PreseciTypeEnum.PTP2, {
      ...values,
      datum_dopisa: validDatum,
    });
    if (poslat) navigate(`${RoutePathEnum.LISTA_PRESEKA}/${PreseciTypeEnum.PTP2}`);
    onOdustani();
  };

  return (
    <>
      {modalStore.getCurrentOpenedModal.includes(`posalji-ptp2-izvestaj-modal`) && (
        <Modal
          name={`posalji-izvestaj-modal`}
          open={true}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          closable={true}
          onCancel={onOdustani}
          title={t('Podaci o izveštaju')}
          footer={null}
          destroyOnClose={true}
          maskClosable={true}
          width={'40%'}
        >
          <Form
            form={form}
            onFinish={posaljiIzvestaj}
            initialValues={POSALJI_PTP2_PRESEK_FORM_INITIAL_STATE}
            labelCol={{ span: 8 }}
            labelAlign="left"
          >
            <FormInput
              inputName={'zavodni_broj_dopisa'}
              name={'zavodni_broj_dopisa'}
              rules={[REQUIRED_FIELD_RULE(true)]}
              label={'Zavodni broj dopisa'}
            />
            <FormDatePicker
              name={'datum_dopisa'}
              format="DD-MM-YYYY"
              label={'Datum dopisa'}
              picker="date"
              rules={[REQUIRED_FIELD_RULE(true)]}
            />
            <Row justify={'end'} gutter={20}>
              <Col>
                <Button name="odustani-od-slanja-ptp2-izvestaja-btn" danger onClick={onOdustani}>
                  Odustani
                </Button>
              </Col>
              <Col>
                <Button htmlType="submit" name="posalji-ptp2-izvestaj-btn">
                  Pošalji izveštaj
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
});
