import { Form, FormInstance, Select } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { StatusPostrojenja } from './status-postrojenja.type';

/**
 * Status Postojenja Form component
 */
export const StatusPostrojenjaForm = observer(() => {
  const form: FormInstance = Form.useFormInstance();
  const { t } = useTranslation();

  const statusOptions = Object.keys(StatusPostrojenja).map((key: string) => {
    return {
      label: t(StatusPostrojenja[key as keyof typeof StatusPostrojenja]),
      value: key,
    };
  });

  return (
    <div>
      <Form.Item
        name={'status'}
        label={t('EVROPSKI_IZVESTAJI.STATUS_POSTROJENJA')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      >
        <Select options={statusOptions} value={form?.getFieldValue('status')} />
      </Form.Item>
    </div>
  );
});
