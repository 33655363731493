import { IDozvoleZaOtpadTranslations } from 'translations/translation.types';

export const dozvoleZaOtpadTranslationsEngleski: IDozvoleZaOtpadTranslations = {
  TITLE: 'Waste-management permits',
  OVERVIEW: 'Overview',
  DATA_PROCESSING: 'Data processing',
  OPERATERI: {
    TITLE: 'Operators',
  },
  DOZVOLE: {
    TITLE: 'Permits',
    VRSTA_DOZVOLE: {
      SAKUPLJANJE: 'Collecting',
      SKLADISTENJE: 'Storage',
      TRANSPORT: 'Transportation',
      TRETMAN_ODLAGANJE: 'Discarding',
      TRETMAN_PONOVNO_ISKORISCENJE: 'Recycling',
    },
  },
};
