import { FormConfig } from 'modules/obrazacIzvestaji/obrazacIzvestajiFilters.constants';
import {
  InputSifarniciFormProps,
  ISifarniciTypeOfForm,
  SearchScrollSifarniciFormProps,
  SIFARNICI_ROUTES,
} from 'modules/sifarnici/sifarnici.types';
import {
  IGetAao2PreseciList,
  IFiltersAao2PreseciList,
  IFiltersAao2SinglePresek,
  IGetAao2SinglePresek,
} from './aao2Presek.types';
import { IPreseciColumnProps } from '../preseci.types';
import {
  DATUM_SLANJA_COLUMN,
  BROJ_ZAPISA_COLUMN,
  BROJ_OPERATERA_COLUMN,
  BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS,
  PIB_PREDUZECA_COLUMN,
  NAZIV_PREDUZECA_COLUMN,
  OPSTINA_PREDUZECA_COLUMN,
  BROJ_PREDUZECA_COLUMN,
  ULICA_PREDUZECA_COLUMN,
  MATICNI_BROJ_PREDUZECA_COLUMN,
  TELEFON_PREDUZECA_COLUMN,
  EMAIL_PREDUZECA_COLUMN,
} from '../preseciColumns.constants';
import {
  DATUM_SLANJA_GTE_FILTER,
  DATUM_SLANJA_LTE_FILTER,
  NAZIV_PREDUZECE_FILTER,
  PIB_PREDUZECA_FILTER,
} from '../preseciFilters.constants';
import i18n from 'translations/i18n.config';
import { IGetOperatorsPackageWasteManage } from 'modules/sifarnici/store/operatorsPackageWasteManage/operatorsPackageWasteManage.types';

const NAZIV_OPERATERA_ILIKE_FILTER: SearchScrollSifarniciFormProps = {
  label: i18n.t('PRESECI.NAZIV_OPERATERA'),
  type_of_form: 'searchScroll',
  formName: 'operater_id',
  labelOptionAccessor: ['naziv'],
  valueOptionAccessor: 'id',
  optionsMaker: (options: IGetOperatorsPackageWasteManage[]) => {
    return options.map(entity => ({
      label: entity?.preduzece?.naziv,
      value: entity.id,
    }));
  },
  fetchOptinsList: SIFARNICI_ROUTES.OPERATORS_PACKAGE_WASTE_MANAGE,
};

const PIB_OPERATERA_ILIKE_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: i18n.t('PRESECI.PIB_OPERATERA'),
  name: 'pib_operatera__ilike',
  inputName: 'pib_operatera__ilike',
};
//#region LISTA P
export const PRESECI_AAO_2_COLUMNS: IPreseciColumnProps<IGetAao2PreseciList>[] = [
  DATUM_SLANJA_COLUMN as IPreseciColumnProps<IGetAao2PreseciList>,
  BROJ_ZAPISA_COLUMN as IPreseciColumnProps<IGetAao2PreseciList>,
  BROJ_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao2PreseciList>,
  BROJ_OPERATERA_COLUMN as IPreseciColumnProps<IGetAao2PreseciList>,
  ...(BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS as IPreseciColumnProps<IGetAao2PreseciList>[]),
];

export const AAO2_PRESECI_LIST_FILTERS_INITIAL_STATE: IFiltersAao2PreseciList = {
  datum_slanja__gte: '',
  datum_slanja__lte: '',
  obrazac_preduzece_id: '',
  pib_preduzeca__ilike: '',
  operater_id: '',
  pib_operatera__ilike: '',
};

export const AAO2_PRESECI_LIST_FILTERS_OPTIONS: FormConfig = (form): ISifarniciTypeOfForm[] => [
  DATUM_SLANJA_GTE_FILTER,
  DATUM_SLANJA_LTE_FILTER,
  PIB_PREDUZECA_FILTER(),
  NAZIV_PREDUZECE_FILTER(form),
  PIB_OPERATERA_ILIKE_FILTER,
  NAZIV_OPERATERA_ILIKE_FILTER,
];

//#endregion LISTA P

//#region PRESEK
export const SINGLE_PRESEK_AAO_2_COLUMNS = (): IPreseciColumnProps<IGetAao2SinglePresek>[] => [
  {
    title: `${i18n.t('PRESECI.PIB_OPERATERA')}`,
    dataIndex: 'pib_operatera',
    key: 'pib_operatera',
    width: 150,
  },
  {
    title: `${i18n.t('PRESECI.NAZIV_OPERATERA')}`,
    dataIndex: 'naziv_operatera',
    key: 'naziv_operatera',
    width: 250,
  },
  NAZIV_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao2SinglePresek>,
  PIB_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao2SinglePresek>,
  MATICNI_BROJ_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao2SinglePresek>,
  OPSTINA_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao2SinglePresek>,
  ULICA_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao2SinglePresek>,
  TELEFON_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao2SinglePresek>,
  EMAIL_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao2SinglePresek>,
];

export const AAO2_SINGLE_PRESEK_FILTER_INITIAL_STATE: IFiltersAao2SinglePresek = {
  obrazac_preduzece_id: '',
  iskljucen: null,
  pib_preduzeca__ilike: '',
  operater_id: '',
  pib_operatera__ilike: '',
  vrsta_izvestaja: '1',
};

export const AAO2_SINGLE_PRESEK_FILTERS_OPTIONS: FormConfig = (): ISifarniciTypeOfForm[] => [
  PIB_PREDUZECA_FILTER({ ilike: true }),
  NAZIV_PREDUZECE_FILTER(),
  PIB_OPERATERA_ILIKE_FILTER,
  NAZIV_OPERATERA_ILIKE_FILTER,
];

// todo: prevod
export const AAO2_SEGMENT_OPTIONS: { label: string; value: '1' | '2' }[] = [
  { label: 'Preduzeća sa jednim operaterom', value: '1' },
  { label: 'Preduzeća sa više operatera', value: '2' },
];
//#endregion PRESEK
