import { Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Button } from 'components/Button/Button';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSelect } from 'components/FormSelect/FormSelect';
import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import { preseciStore } from 'modules/preseci/preseci.store';
import { RAZLOG_ISKLJUCENJA_OPTIONS } from 'modules/preseci/ptp2Presek/ptp2Presek.constants';
import { IGetPtp2SinglePresek } from 'modules/preseci/ptp2Presek/ptp2Presek.types';
import { modalStore } from 'store';

const Ptp2IskljucivanjeForm = observer(() => {
  const [form] = useForm<IGetPtp2SinglePresek>();

  const handleFormSubmit = async (values: IGetPtp2SinglePresek) => {
    try {
      await preseciStore.iskljucivanjePtp2Prijave(values.razlog_iskljucenja);
      modalStore.removeAllModals();
    } catch (error) {
      console.error(error);
    }
  };

  const disabledRazlog =
    preseciStore.ptp2PrijavaZaIskljucivanje?.iskljucen ||
    !!preseciStore.ptp2PrijavaZaIskljucivanje?.datum_i_vreme_preseka;

  return (
    <Form
      form={form}
      onFinish={handleFormSubmit}
      // disabled
      wrapperCol={{ span: 19 }}
      labelCol={{ span: 5 }}
      labelAlign="left"
      initialValues={preseciStore.ptp2PrijavaZaIskljucivanje}
    >
      <FormInput name={'godina'} inputName={'godina'} label={'Godina'} disabled />
      <FormInput name={'naziv_preduzeca'} inputName={'naziv_preduzeca'} label={'Naziv preduzeća'} disabled />
      <FormInput name={'vrsta_proizvoda'} inputName={'vrsta_proizvoda'} label={'Vrsta proizvoda'} disabled />
      <FormInput name={'proizvod'} inputName={'proizvod'} label={'Naziv proizvoda'} disabled />
      <FormSelect
        name={'razlog_iskljucenja'}
        options={RAZLOG_ISKLJUCENJA_OPTIONS}
        label="Razlog isključenja"
        disabled={disabledRazlog}
      />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px', gap: '10px' }}>
        {!disabledRazlog && (
          <Button name="iskljuci-ptp2-prijavu-btn" htmlType="submit">
            Isključi
          </Button>
        )}
        <Button name="close-iskljuci-ptp2-prijavu-btn" danger onClick={modalStore.removeAllModals}>
          {disabledRazlog ? 'Zatvori' : 'Odustani'}
        </Button>
      </div>
    </Form>
  );
});

export const Ptp2IskljucivanjeModal = observer(() => {
  return (
    <>
      {modalStore.getCurrentOpenedModal.includes(`ptp2-presek-iskljucivanje-modal`) && (
        <Modal
          name={`ptp2-presek-iskljucivanje-modal`}
          open={true}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          closable={true}
          onCancel={() => {
            modalStore.removeAllModals();
          }}
          title={'Isključivanje ptp2 prijave'}
          footer={null}
          destroyOnClose={true}
          maskClosable={true}
          width={'60%'}
        >
          <Ptp2IskljucivanjeForm />
        </Modal>
      )}
    </>
  );
});
