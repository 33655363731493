import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormScrollWithObjInside } from 'components/FormScrollWithObjInside/FormScrollWithObjInside';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';

export const NacinPostupanjaForm = observer(() => {
  return (
    <div>
      <FormScrollWithObjInside
        placeholder=""
        name="dr_oznaka_id"
        formName="dr_oznaka_id"
        label="D/R Oznaka"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        fetchOptinsList={SIFARNICI_ROUTES.DR_LIST}
        filtersForFetch={{ size: 30 }}
        labelOptionAccessor={['name', 'description_sr']}
        valueOptionAccessor="id"
        disabledAccessor="is_active"
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormInputNumber
        inputName={'kapacitet_u_jednom_trenutku'}
        name={'kapacitet_u_jednom_trenutku'}
        label="Kapacitet u jednom trenutku (t)"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      <FormInputNumber
        inputName={'kolicina_godisnje'}
        name={'kolicina_godisnje'}
        label="Količina godišnje (t)"
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
    </div>
  );
});
