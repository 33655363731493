import { FormInstance } from 'antd';
import {
  PRESECI_AAO_1_COLUMNS,
  AAO1_PRESECI_LIST_FILTERS_OPTIONS,
  AAO1_PRESECI_LIST_FILTERS_INITIAL_STATE,
  SINGLE_PRESEK_AAO_1_COLUMNS,
  AAO1_SINGLE_PRESEK_FILTERS_OPTIONS,
  AAO1_SINGLE_PRESEK_FILTER_INITIAL_STATE,
  AAO1_MODAL_SINGLE_PRESEK_FORM_OPTIONS,
  AAO1_MODAL_SINGLE_PRESEK_FORM_INITIAL_STATE,
} from './aao1Presek/aao1Presek.constants';
import {
  PRESECI_AAO_2_COLUMNS,
  AAO2_PRESECI_LIST_FILTERS_OPTIONS,
  AAO2_PRESECI_LIST_FILTERS_INITIAL_STATE,
  SINGLE_PRESEK_AAO_2_COLUMNS,
  AAO2_SINGLE_PRESEK_FILTERS_OPTIONS,
  AAO2_SINGLE_PRESEK_FILTER_INITIAL_STATE,
} from './aao2Presek/aao2Presek.constants';
import {
  IPosaljiPresekForm,
  IPosaljiPtp2PresekForm,
  IPreseciColumnProps,
  IPreseciConfig,
  IPreseciConfigKeyEnumValueString,
  IPreseciSelectType,
  ModalUkljuciIskljuciPreseciKeys,
} from './preseci.types';
import {
  OTV_MODAL_SINGLE_PRESEK_FORM_INITIAL_STATE,
  OTV_MODAL_SINGLE_PRESEK_FORM_OPTIONS,
  OTV_PRESECI_LIST_FILTERS_INITIAL_STATE,
  OTV_PRESECI_LIST_FILTERS_OPTIONS,
  OTV_SINGLE_PRESEK_FILTER_INITIAL_STATE,
  OTV_SINGLE_PRESEK_FILTERS_OPTIONS,
  PRESECI_OTV_COLUMNS,
  SINGLE_PRESEK_OTV_COLUMNS,
} from './otvPresek/otvPresek.constants';
import {
  PRESECI_PZV_COLUMNS,
  PZV_PRESECI_LIST_FILTERS_INITIAL_STATE,
  PZV_PRESECI_LIST_FILTERS_OPTIONS,
  PZV_SINGLE_PRESEK_FILTER_INITIAL_STATE,
  PZV_SINGLE_PRESEK_FILTERS_OPTIONS,
  SINGLE_PRESEK_PZV_COLUMNS,
} from './pzvPresek/pzvPresek.constants';
import {
  PRESECI_PTP_2_COLUMNS,
  PTP2_PRESECI_LIST_FILTERS_OPTIONS,
  PTP2_SINGLE_PRESEK_FILTERS_OPTIONS,
  SINGLE_PRESEK_PTP_2_COLUMNS,
} from './ptp2Presek/ptp2Presek.constants';
import {
  PRESECI_VELIKI_ZAGADJIVACI_COLUMNS,
  SINGLE_PRESEK_VELIKI_ZAGADJIVACI_COLUMNS,
  VELIKI_ZAGADJIVACI_PRESECI_LIST_FILTERS_INITIAL_STATE,
  VELIKI_ZAGADJIVACI_PRESECI_LIST_FILTERS_OPTIONS,
  VELIKI_ZAGADJIVACI_SINGLE_PRESEK_FILTER_INITIAL_STATE,
  VELIKI_ZAGADJIVACI_SINGLE_PRESEK_FILTERS_OPTIONS,
} from './velikiZagadjivaciPresek/velikiZagadjivaciPresek.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export enum PreseciTypeEnum {
  PTP2 = 'ptp2',
  AAO1 = 'aao1',
  AAO2 = 'aao2',
  OTV = 'otv',
  PZV = 'pzv',
  VELIKI_ZAGADJIVACI = 'veliki-zagadjivaci',
}

//#region LISTA P

export const PRESECI_TYPE_OPTIONS: IPreseciSelectType[] = [
  { value: PreseciTypeEnum.PTP2, label: 'PTP2' },
  { value: PreseciTypeEnum.AAO1, label: 'AAO1 (bez operatera)' },
  { value: PreseciTypeEnum.AAO2, label: 'AAO2' },
  { value: PreseciTypeEnum.OTV, label: 'OTV' },
  { value: PreseciTypeEnum.PZV, label: 'PZV' },
  { value: PreseciTypeEnum.VELIKI_ZAGADJIVACI, label: 'Veliki zagađivači' },
];

export const PRESECI_LIST_POSLATO_TYPE_API_URL_MAP: IPreseciConfigKeyEnumValueString = {
  [PreseciTypeEnum.PTP2]: 'ptp2-poslato',
  [PreseciTypeEnum.AAO1]: 'aao1-presek-poslato',
  [PreseciTypeEnum.AAO2]: 'aao2-presek-poslato',
  [PreseciTypeEnum.OTV]: 'otv-poslato',
  [PreseciTypeEnum.PZV]: 'pzv-presek-poslato',
  [PreseciTypeEnum.VELIKI_ZAGADJIVACI]: 'veliki-zagadjivaci-poslato',
};

export const PRESECI_LIST_COLUMN_MAP: {
  [key in PreseciTypeEnum]: () => IPreseciColumnProps[];
} = {
  [PreseciTypeEnum.PTP2]: () => PRESECI_PTP_2_COLUMNS,
  [PreseciTypeEnum.AAO1]: () => PRESECI_AAO_1_COLUMNS,
  [PreseciTypeEnum.AAO2]: () => PRESECI_AAO_2_COLUMNS,
  [PreseciTypeEnum.OTV]: () => PRESECI_OTV_COLUMNS,
  [PreseciTypeEnum.PZV]: () => PRESECI_PZV_COLUMNS,
  [PreseciTypeEnum.VELIKI_ZAGADJIVACI]: () => PRESECI_VELIKI_ZAGADJIVACI_COLUMNS,
};

export const PRESECI_LIST_FILTERS_MAP: {
  [key in PreseciTypeEnum]: IPreseciConfig;
} = {
  [PreseciTypeEnum.PTP2]: {
    components: (form: FormInstance) => PTP2_PRESECI_LIST_FILTERS_OPTIONS(form),
    initialState: AAO1_PRESECI_LIST_FILTERS_INITIAL_STATE,
  },
  [PreseciTypeEnum.AAO1]: {
    components: (form: FormInstance) => AAO1_PRESECI_LIST_FILTERS_OPTIONS(form),
    initialState: AAO1_PRESECI_LIST_FILTERS_INITIAL_STATE,
  },
  [PreseciTypeEnum.AAO2]: {
    components: (form: FormInstance) => AAO2_PRESECI_LIST_FILTERS_OPTIONS(form),
    initialState: AAO2_PRESECI_LIST_FILTERS_INITIAL_STATE,
  },
  [PreseciTypeEnum.OTV]: {
    components: (form: FormInstance) => OTV_PRESECI_LIST_FILTERS_OPTIONS(form),
    initialState: OTV_PRESECI_LIST_FILTERS_INITIAL_STATE,
  },
  [PreseciTypeEnum.PZV]: {
    components: (form: FormInstance) => PZV_PRESECI_LIST_FILTERS_OPTIONS(form),
    initialState: PZV_PRESECI_LIST_FILTERS_INITIAL_STATE,
  },
  [PreseciTypeEnum.VELIKI_ZAGADJIVACI]: {
    components: (form: FormInstance) => VELIKI_ZAGADJIVACI_PRESECI_LIST_FILTERS_OPTIONS(form),
    initialState: VELIKI_ZAGADJIVACI_PRESECI_LIST_FILTERS_INITIAL_STATE,
  },
};
//#endregion LISTA P

//#region PRESEK
export const SINGLE_PRESEK_TYPE_API_URL_MAP: IPreseciConfigKeyEnumValueString = {
  [PreseciTypeEnum.PTP2]: 'ptp2-presek',
  [PreseciTypeEnum.AAO1]: 'aao1-presek',
  [PreseciTypeEnum.AAO2]: 'aao2-presek',
  [PreseciTypeEnum.OTV]: 'otv-presek',
  [PreseciTypeEnum.PZV]: 'pzv-presek',
  [PreseciTypeEnum.VELIKI_ZAGADJIVACI]: 'veliki-zagadjivaci-presek',
};

export const SINGLE_PRESEK_LIST_COLUMN_MAP: {
  [key in PreseciTypeEnum]: (presekType?: PreseciTypeEnum, datumPreseka?: string) => IPreseciColumnProps<any>[];
} = {
  [PreseciTypeEnum.PTP2]: () => SINGLE_PRESEK_PTP_2_COLUMNS,
  [PreseciTypeEnum.AAO1]: () => SINGLE_PRESEK_AAO_1_COLUMNS(),
  [PreseciTypeEnum.AAO2]: () => SINGLE_PRESEK_AAO_2_COLUMNS(),
  [PreseciTypeEnum.OTV]: () => SINGLE_PRESEK_OTV_COLUMNS(),
  [PreseciTypeEnum.PZV]: () => SINGLE_PRESEK_PZV_COLUMNS(),
  [PreseciTypeEnum.VELIKI_ZAGADJIVACI]: () => SINGLE_PRESEK_VELIKI_ZAGADJIVACI_COLUMNS(),
};

export const SINGLE_PRESEK_LIST_FILTERS_MAP: {
  [key in PreseciTypeEnum]: IPreseciConfig;
} = {
  [PreseciTypeEnum.PTP2]: {
    components: PTP2_SINGLE_PRESEK_FILTERS_OPTIONS,
    initialState: AAO1_SINGLE_PRESEK_FILTER_INITIAL_STATE,
  },
  [PreseciTypeEnum.AAO1]: {
    components: AAO1_SINGLE_PRESEK_FILTERS_OPTIONS,
    initialState: AAO1_SINGLE_PRESEK_FILTER_INITIAL_STATE,
  },
  [PreseciTypeEnum.AAO2]: {
    components: AAO2_SINGLE_PRESEK_FILTERS_OPTIONS,
    initialState: AAO2_SINGLE_PRESEK_FILTER_INITIAL_STATE,
  },
  [PreseciTypeEnum.OTV]: {
    components: OTV_SINGLE_PRESEK_FILTERS_OPTIONS,
    initialState: OTV_SINGLE_PRESEK_FILTER_INITIAL_STATE,
  },
  [PreseciTypeEnum.PZV]: {
    components: PZV_SINGLE_PRESEK_FILTERS_OPTIONS,
    initialState: PZV_SINGLE_PRESEK_FILTER_INITIAL_STATE,
  },
  [PreseciTypeEnum.VELIKI_ZAGADJIVACI]: {
    components: VELIKI_ZAGADJIVACI_SINGLE_PRESEK_FILTERS_OPTIONS,
    initialState: VELIKI_ZAGADJIVACI_SINGLE_PRESEK_FILTER_INITIAL_STATE,
  },
};

export const SINGLE_PRESEK_LIST_ORDER_BY: {
  [key in PreseciTypeEnum]: string;
} = {
  [PreseciTypeEnum.PTP2]: '',
  [PreseciTypeEnum.AAO1]: '-godina,+pib,naziv_grupe_ambalaze',
  [PreseciTypeEnum.AAO2]: '',
  [PreseciTypeEnum.OTV]: '',
  [PreseciTypeEnum.PZV]: '',
  [PreseciTypeEnum.VELIKI_ZAGADJIVACI]: '',
};

//#endregion PRESEK

//#region MODAL

export const MODAL_SINGLE_PRESEK_CONFIG: {
  [key in ModalUkljuciIskljuciPreseciKeys]: IPreseciConfig;
} = {
  [PreseciTypeEnum.AAO1]: {
    components: (form: FormInstance) => AAO1_MODAL_SINGLE_PRESEK_FORM_OPTIONS(form),
    initialState: AAO1_MODAL_SINGLE_PRESEK_FORM_INITIAL_STATE,
  },
  [PreseciTypeEnum.OTV]: {
    components: (form: FormInstance, _presekType?: PreseciTypeEnum) => OTV_MODAL_SINGLE_PRESEK_FORM_OPTIONS(form),
    initialState: OTV_MODAL_SINGLE_PRESEK_FORM_INITIAL_STATE,
  },
  [PreseciTypeEnum.VELIKI_ZAGADJIVACI]: {
    components: function (_form: FormInstance, _presekType?: PreseciTypeEnum): ISifarniciTypeOfForm[] {
      throw new Error('Function not implemented.');
    },
    initialState: {},
  },
};

export const VRSTA_IZVESTAJA_SINGLE_PRESEK_TYPE = [PreseciTypeEnum.AAO2, PreseciTypeEnum.VELIKI_ZAGADJIVACI];

export const POSALJI_PRESEK_FORM_INITIAL_STATE: IPosaljiPresekForm = {
  broj_izvestaja: '',
  datum_izvestaja: '',
};

export const POSALJI_PTP2_PRESEK_FORM_INITIAL_STATE: IPosaljiPtp2PresekForm = {
  zavodni_broj_dopisa: '',
  datum_dopisa: '',
};
//#endregion MODAL
