import { ApiResponse, axios } from 'modules/axios';
import {
  PRESECI_LIST_POSLATO_TYPE_API_URL_MAP,
  PreseciTypeEnum,
  SINGLE_PRESEK_TYPE_API_URL_MAP,
} from './preseci.constants';
import queryString from 'query-string';
import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';
import { ROUTES } from 'modules/nriz/nriz.constants';
import {
  FiltersPreseciList,
  FiltersWithPaginationPreseciList,
  FiltersWithPaginationSinglePresek,
  GetPreseciListTypes,
  ICreatePresekPayload,
  IPosaljiPresekForm,
  IPosaljiPtp2PresekForm,
  IUkljuciIskljuciPreseciPoFilterima,
} from './preseci.types';
import { Ptp2ProductType, Ptp2RazlogIskljucenja } from './ptp2Presek/ptp2Presek.types';

const fetchPreseciList = (
  preseciType: PreseciTypeEnum,
  filters: FiltersWithPaginationPreseciList
): IPaginatedApiResponse<GetPreseciListTypes> => {
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.PRESECI}/${PRESECI_LIST_POSLATO_TYPE_API_URL_MAP[preseciType]}/?${query}`);
};

const exportPreseciListExcel = (preseciType: PreseciTypeEnum, filters: FiltersPreseciList): ApiResponse<Blob> => {
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.PRESECI}/${PRESECI_LIST_POSLATO_TYPE_API_URL_MAP[preseciType]}/export?${query}`, {
    responseType: 'blob',
  });
};

const fetchExistingSinglePresekList = <T>(
  preseciType: PreseciTypeEnum,
  datumPreseka: string,
  filters: FiltersWithPaginationSinglePresek
): IPaginatedApiResponse<T> => {
  // const query = queryString.stringify(filters);
  return axios.get(
    `${ROUTES.PRESECI}/${PRESECI_LIST_POSLATO_TYPE_API_URL_MAP[preseciType]}/detaljno/${`${datumPreseka}`}`,
    {
      params: filters,
      // paramsSerializer: { indexes: null }
    }
  );
};

const exportExistingSinglePresekListExcel = (
  preseciType: PreseciTypeEnum,
  datumPreseka: string,
  filters: FiltersPreseciList
): ApiResponse<Blob> => {
  const query = queryString.stringify(filters);
  return axios.get(
    `${ROUTES.PRESECI}/${
      PRESECI_LIST_POSLATO_TYPE_API_URL_MAP[preseciType]
    }/export-detaljno/${`${datumPreseka}`}?${query}`,
    {
      responseType: 'blob',
    }
  );
};

const fetchSinglePresekList = <T>(
  preseciType: PreseciTypeEnum,
  filters: FiltersWithPaginationSinglePresek
): IPaginatedApiResponse<T> => {
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/?${query}`);
};

const createSinglePresek = (
  preseciType: PreseciTypeEnum,
  payload?: ICreatePresekPayload
): ApiResponse<IPostResponse> => {
  return axios.post(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/`, payload);
};

const deleteSinglePresek = (preseciType: PreseciTypeEnum): ApiResponse<IEmptyResponse> => {
  if (preseciType === PreseciTypeEnum.PTP2)
    return axios.put(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/odustani-od-preseka`);
  return axios.delete(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/obrisi`);
};

const posaljiSinglePresek = (
  preseciType: PreseciTypeEnum,
  brojIzvestaja: IPosaljiPresekForm | IPosaljiPtp2PresekForm
): ApiResponse<IEmptyResponse> => {
  return preseciType === PreseciTypeEnum.PTP2
    ? axios.put(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/posalji-izvestaj`, brojIzvestaja)
    : axios.post(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/posalji`, brojIzvestaja);
};

const iskljuciStavkuPresekaPoId = (preseciType: PreseciTypeEnum, id: string): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/iskljuci/${id}`);
};

const iskljuciStavkePoFilterima = (
  preseciType: PreseciTypeEnum,
  filters: IUkljuciIskljuciPreseciPoFilterima
): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/iskljuci`, filters);
};

const ukljuciStavkuPresekaPoId = (preseciType: PreseciTypeEnum, id: string): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/ukljuci/${id}`);
};

const ukljuciStavkePoFilterima = (
  preseciType: PreseciTypeEnum,
  filters: IUkljuciIskljuciPreseciPoFilterima
): ApiResponse<IEmptyResponse> => {
  return axios.put(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/ukljuci`, filters);
};

const exportSinglePresekExcel = (preseciType: PreseciTypeEnum, filters: any): ApiResponse<Blob> => {
  const query = queryString.stringify(filters);
  return axios.get(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[preseciType]}/export?${query}`, {
    responseType: 'blob',
  });
};

const checkIfYearIncluded = (
  presekType: PreseciTypeEnum
): ApiResponse<{ ukljucena_aktuelna_izvestajna_godina: boolean }> => {
  return axios.get(
    `${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[presekType]}/ukljucena-aktuelna-izvestajna-godina`
  );
};

const automatskoIskljucivanjePtp2 = (product: Ptp2ProductType) => {
  return axios.put(
    `${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[PreseciTypeEnum.PTP2]}/automatsko-iskljucivanje-${product}`
  );
};

const iskljucivanjePtp2Prijave = (id: string, razlog_iskljucenja: Ptp2RazlogIskljucenja) => {
  return axios.put(`${ROUTES.PRESECI}/${SINGLE_PRESEK_TYPE_API_URL_MAP[PreseciTypeEnum.PTP2]}/iskljuci-prijavu/${id}`, {
    razlog_iskljucenja,
  });
};

export const preseciRepo = {
  fetchPreseciList,
  createSinglePresek,
  fetchSinglePresekList,
  fetchExistingSinglePresekList,
  deleteSinglePresek,
  posaljiSinglePresek,
  iskljuciStavkuPresekaPoId,
  iskljuciStavkePoFilterima,
  ukljuciStavkuPresekaPoId,
  ukljuciStavkePoFilterima,
  exportSinglePresekExcel,
  exportPreseciListExcel,
  exportExistingSinglePresekListExcel,
  checkIfYearIncluded,
  automatskoIskljucivanjePtp2,
  iskljucivanjePtp2Prijave,
};
