import { operateriRepo } from './operateri.repo';
import { IOperaterOtpadaRequest, IOperaterOtpadaResponse } from './operateri.types';

class OperateriService {
  fetchOperateriList = (params: any) => {
    return operateriRepo.fetchOperateriList(params);
  };

  createOperater = (payload: IOperaterOtpadaRequest) => {
    return operateriRepo.createOperater(payload);
  };

  updateOperaterInfo = (id: string, payload: Partial<IOperaterOtpadaResponse>) => {
    return operateriRepo.updateOperaterInfo(id, payload);
  };

  fetchOperaterById = (id: string) => {
    return operateriRepo.fetchOperaterById(id);
  };

  deleteOperater = (id: string) => {
    return operateriRepo.deleteOperater(id);
  };
}

export const operateriService = new OperateriService();
