import { ColumnProps } from 'antd/es/table';
import { ColumnGroupProps } from 'antd/es/table/ColumnGroup';

import { EuIntegratedReport, EuRegistar, EvropskiIzvestajTypeEnum } from './evropskiIzvestaji.types';

export interface IIzvestajiColumnProps<RecordType = unknown> extends Omit<ColumnProps<RecordType>, 'dataIndex'> {
  dataIndex?: keyof RecordType;
}

export interface IIzvestajiColumnGroupProps<RecordType = unknown>
  extends Omit<ColumnGroupProps<RecordType>, 'children'> {
  children?: IIzvestajiColumnOrGroupProps<RecordType> | IIzvestajiColumnOrGroupProps<RecordType>[];
}

export type IIzvestajiColumnOrGroupProps<RecordType = unknown> =
  | IIzvestajiColumnGroupProps<RecordType>
  | IIzvestajiColumnProps<RecordType>;

export const EU_REGISTAR_COLUMNS: IIzvestajiColumnProps<EuRegistar>[] = [
  { width: 100, dataIndex: 'godina', title: 'Godina' },
  { width: 200, dataIndex: 'pib', title: 'PIB preduzeća' },
  { width: 200, dataIndex: 'maticni_broj', title: 'MB preduzeća' },
  { width: 200, dataIndex: 'naziv_preduzeca', title: 'Naziv preduzeća' },
  { width: 200, dataIndex: 'naziv_postrojenja', title: 'Naziv postrojenja' },
  { width: 200, dataIndex: 'istorijski_id_postrojenja', title: 'Istorijski ID postrojenja' },
  { width: 200, dataIndex: 'nacionalni_id_postrojenja', title: 'Nacionalni ID postrojenja' },
  { width: 200, dataIndex: 'koordinate_lat', title: 'Geografska širina postrojenja' },
  { width: 200, dataIndex: 'koordinate_long', title: 'Geografska širina postrojenja' },
  { width: 200, dataIndex: 'status', title: 'Status postrojenja' },
];

export const EU_INTEGRATED_REPORT_COLUMNS: IIzvestajiColumnProps<EuIntegratedReport>[] = [];

export const EVROPSKI_IZVESTAJI_COLUMN_MAP: {
  [key in EvropskiIzvestajTypeEnum]: (data: any) => IIzvestajiColumnOrGroupProps<any>[];
} = {
  [EvropskiIzvestajTypeEnum.EU_REGISTAR]: () => EU_REGISTAR_COLUMNS,
  [EvropskiIzvestajTypeEnum.EU_INTEGRATED_REPORT]: () => EU_INTEGRATED_REPORT_COLUMNS,
};
