import { makeAutoObservable } from 'mobx';
import { PregledDozvolaType } from '../dozvole/dozvole.types';
import { IPregledDozvoleRow } from './pregledDozvola.types';
import to from 'await-to-js';
import { pregledDozvolaService } from './pregledDozvola.service';
import { ITablePagination } from 'components/TableData/TableData';
import { IPagination } from 'typescript';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { nrizService } from 'modules/nriz/nriz.service';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';

class PregledDozvolaStore {
  pregledDozvolaType: PregledDozvolaType = PregledDozvolaType.VrstaDozvole;

  dozvoleList: IPregledDozvoleRow[] = [];

  pregledDozvolaPagination: IPagination = paginationInitialState;
  constructor() {
    makeAutoObservable(this, {});
  }

  setPregledDozvolaType = (newType: PregledDozvolaType) => {
    this.pregledDozvolaType = newType;
  };

  setDozvoleList = (newList: IPregledDozvoleRow[]) => {
    this.dozvoleList = newList;
  };

  setPregledDozvolaPagination = (pagination: IPagination) => {
    this.pregledDozvolaPagination = pagination;
  };

  fetchDozvoleList = async (pagination?: ITablePagination) => {
    const { page, size } = pagination ?? this.pregledDozvolaPagination;
    const [err, res] = await to(
      pregledDozvolaService.fetchDozvoleList({
        page,
        size,
        ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.DOZVOLE_PREGLED]),
      })
    );

    if (err || !res) return Promise.reject(err);

    this.setDozvoleList(res.items);
    const { size: newSize, page: newPage, total, pages } = res;
    this.setPregledDozvolaPagination({ size: newSize, page: newPage, total, pages });
  };
}

export const pregledDozvolaStore = new PregledDozvolaStore();
