import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { FormScrollWithObjInside } from 'components/FormScrollWithObjInside/FormScrollWithObjInside';
import { FormTable } from 'components/FormTable/FormTable';
import { observer } from 'mobx-react-lite';
import { REQUIRED_AT_LEAST_ONE_LIST_ITEM, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { nacinPostupanjaColumns, otpadTypes } from 'modules/registarDozvola/otpad/otpad.constants';
import { otpadStore } from 'modules/registarDozvola/otpad/otpad.store';
import { DozvoleOtpadList, INacinPostupanjaSaOtpadom } from 'modules/registarDozvola/otpad/otpad.types';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { useTranslation } from 'react-i18next';
import { FormListType } from 'typescript/NrizTypes';

export const OtpadDozvolaForm = observer(() => {
  const form = useFormInstance();

  const { t } = useTranslation();

  const vrstaDozvoleRule = {
    validator() {
      const areAllCheckboxValuesFalsy = Object.values(form.getFieldsValue(otpadTypes)).every(value => !value);
      if (areAllCheckboxValuesFalsy)
        return Promise.reject(new Error('Potrebno je označiti barem jedan način upravljanja otpadom'));
      return Promise.resolve();
    },
  };

  const vrstaDozvoleCheckboxMap = () =>
    otpadTypes.map(otpad => {
      return (
        <FormCheckbox
          key={otpad}
          defaultChecked={!!otpadStore.selectedOtpad?.[otpad]}
          rules={[vrstaDozvoleRule]}
          name={otpad}
          label={t(`DOZVOLE_ZA_OTPAD.DOZVOLE.VRSTA_DOZVOLE.${otpad.toUpperCase()}`)}
          onCheckBoxChange={() => {
            form.validateFields(otpadTypes);
          }}
        />
      );
    });

  const shouldNacinPostupanjaBeValidated = Object.values(
    form.getFieldsValue(['skladistenje', 'tretman_odlaganje', 'tretman_ponovno_iskoriscenje'])
  ).some(value => value);

  return (
    <>
      <FormScrollWithObjInside
        placeholder=""
        name="indeksni_broj_otpada_id"
        formName="indeksni_broj_otpada_id"
        label="Indeksni broj otpada"
        fetchOptinsList={SIFARNICI_ROUTES.WASTE_CATALOGUE}
        filtersForFetch={{ order_by: '+index_number' }}
        labelOptionAccessor={['index_number', 'waste_sr']}
        valueOptionAccessor="id"
        disabledAccessor="is_active"
        rules={[REQUIRED_FIELD_RULE(true)]}
      />
      {vrstaDozvoleCheckboxMap()}
      <FormTable<INacinPostupanjaSaOtpadom>
        listName="nacin_postupanja_sa_otpadom"
        title="Način postupanja sa otpadom"
        listValidationRules={[REQUIRED_AT_LEAST_ONE_LIST_ITEM(shouldNacinPostupanjaBeValidated)]}
        tableColumns={nacinPostupanjaColumns}
        configPath={{ formListType: FormListType.Dozvole, formListSubType: DozvoleOtpadList.NacinPostupanja }}
      />
    </>
  );
});
