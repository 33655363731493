import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import {
  PREGLED_DOZVOLA_FILTER_OPTIONS,
  PREGLED_DOZVOLA_FILTERS_INITIAL_STATE,
} from 'modules/registarDozvola/pregled/pregledDozvola.constants';
import { pregledDozvolaStore } from 'modules/registarDozvola/pregled/pregledDozvola.store';
import { FiltersNameEnum } from 'store/filteri.store';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const PregledDozvolaFilters = observer(() => {
  const [form] = useForm();

  const data: IFilterTableProps = {
    form,
    initialState: PREGLED_DOZVOLA_FILTERS_INITIAL_STATE,
    filterStoreName: FiltersNameEnum.DOZVOLE_PREGLED,
    fetchTableList: () => pregledDozvolaStore.fetchDozvoleList(paginationInitialState),
    filterOptions: PREGLED_DOZVOLA_FILTER_OPTIONS(form, pregledDozvolaStore.pregledDozvolaType),
    showHideFilters: true,
  };

  return (
    <div id="obrasciFilters">
      <FilterTableForm data={data} />
    </div>
  );
});
