export interface IGetPtp2PreseciList {
  broj_preduzeca: number;
  broj_zapisa: number;
  broj_izvestaja: string;
  datum_izvestaja: string | Date;
  datum_i_vreme_slanja: string | Date;
  datum_i_vreme_preseka: string | Date;
  datum_dopisa: string | Date;
  zavodni_broj_dopisa: string;
  id: string;
  ukupna_kolicina_nova: number;
  ukupan_pdv_nova: number;
  ukupna_kolicina_korigovana: number;
  ukupan_pdv_korigovana: number;
  ukupna_kolicina_brisana: number;
  ukupan_pdv_brisana: number;
}

export interface IFiltersPtp2PreseciList {
  datum_slanja__gte?: string | Date;
  datum_slanja__lte?: string | Date;
  naziv_preduzeca__ilike?: string;
  vrsta_proizvoda__ilike?: string;
}

export interface IGetPtp2SinglePresek {
  datum_i_vreme_preseka: string | Date;
  godina: number;
  pib: string;
  maticni_broj: string;
  naziv_preduzeca: string;
  mesto_preduzeca: string;
  opstina_preduzeca: string;
  ulica_i_broj_preduzeca: string;
  telefon_preduzeca: string;
  email_preduzeca: string;
  vrsta_proizvoda: string;
  proizvod: string;
  proizvod_description: string;
  kolicina: number;
  jedinica_mere: string;
  pdv: number;
  status: string;
  iskljucen: boolean;
  razlog_iskljucenja: Ptp2RazlogIskljucenja;
  datum_iskljucenja: string | Date;
  obrazac_ptp2_proizvod_id: string;
  obrazac_ptp2_id: string;
  obrazac_preduzece_id: string;
  zavodni_broj_dopisa: string;
  datum_dopisa: string | Date;
  products_nr_id: string;
  products_type_id: string;
  priceor_percentageof_pdv: string;
  currencyor: string;
  the_order: number;
  p_class: string;
  id: string;
}

export interface IFiltersPtp2SinglePresek {
  maticni_broj__ilike?: string;
  godina?: string;
  pib__ilike?: string;
  naziv_preduzeca__ilike?: string;
  vrsta_proizvoda__ilike?: string;
  iskljucen?: boolean | null;
}
export interface Ptp2CreatePresekPayload {
  ukljucena_aktuelna_izvestajna_godina: boolean;
}
export enum Ptp2ProductType {
  Vozila = 'vozila',
  Guma = 'guma',
}

export enum Ptp2RazlogIskljucenja {
  Zakljucak = 'korekcija_nije_u_skladu_sa_zakljuckom_po_zalbi',
  DrugiZahtev = 'korekcija_nije_u_skladu_sa_drugim_zahtevom',
  PogresnaKolicina = 'pogresno_uneta_kolicina',
  CekaPotvrdu = 'ceka_se_potvrda_ispravnosti_unetih_proizvoda',
  AutomatskoIskljucivanje = 'automatsko_iskljucivanje_zbog_netacnih_podataka',
}
