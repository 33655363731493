import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { ITablePagination } from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { PreseciTypeEnum, SINGLE_PRESEK_LIST_FILTERS_MAP } from 'modules/preseci/preseci.constants';
import { IPreseciConfig } from 'modules/preseci/preseci.types';
import { ISKLJUCEN_FILTER } from 'modules/preseci/preseciFilters.constants';
import { FC, useEffect } from 'react';
import { filtersStore, FiltersNameEnum } from 'store/filteri.store';
import { IFilterTableProps } from 'typescript/NrizTypes';

interface IFiltersSinglePresekProps {
  presekType: PreseciTypeEnum;
  fetchPresekList: (pagination?: ITablePagination) => void;
}

export const FiltersSinglePresek: FC<IFiltersSinglePresekProps> = observer(({ presekType, fetchPresekList }) => {
  const [singlePresekForm] = useForm();

  const fetchPresekListFilters = () => {
    return fetchPresekList(paginationInitialState);
  };

  useEffect(() => {
    return () => filtersStore.setFilters(FiltersNameEnum.SINGLE_PRESEK, {});
  }, []);

  const filtersConfig: IPreseciConfig = SINGLE_PRESEK_LIST_FILTERS_MAP[presekType];
  const filtersOptions = [...filtersConfig.components(singlePresekForm), ISKLJUCEN_FILTER];

  const data: IFilterTableProps = {
    form: singlePresekForm,
    initialState: filtersStore.getFilters[FiltersNameEnum.SINGLE_PRESEK],
    filterStoreName: FiltersNameEnum.SINGLE_PRESEK,
    filterOptions: filtersOptions,
    fetchTableList: fetchPresekListFilters,
  };

  return <FilterTableForm data={data} />;
});
