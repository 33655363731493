import { ColumnsType } from 'antd/es/table';
import {
  ICompositionCommunalWasteFilters,
  IGetCompositionCommunalWaste,
  IPostCompositionCommunalWaste,
} from './compositionCommunalWaste.types';
import { FormInstance } from 'antd';
import {
  NAME_SR_TABLE,
  NAME_TABLE,
  THE_ORDER_TABLE,
  VERSION_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { NAME_FORM, NAME_SR_FORM, THE_ORDER_FORM, VERSION_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';

export const COMPOSITION_COMMUNAL_WASTE_INITIAL_STATE: IPostCompositionCommunalWaste = {
  name: '',
  name_sr: '',
  the_order: '',
  is_active: true,
  version: '',
};

export const COMPOSITION_COMMUNAL_WASTE_FILTERS_INITIAL_STATE: ICompositionCommunalWasteFilters = {
  name__ilike: '',
  name_sr__ilike: '',
  the_order__ilike: '',
  version__ilike: '',
  is_active: null,
};

export const COMPOSITION_COMMUNAL_WASTE_TABLE_DATA: ColumnsType<IGetCompositionCommunalWaste> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  THE_ORDER_TABLE,
  VERSION_TABLE,
];
export const COMPOSITION_COMMUNAL_WASTE_FORM_OPTIONS = (
  _: FormInstance<IGetCompositionCommunalWaste>
): ISifarniciTypeOfForm[] => {
  return [THE_ORDER_FORM, NAME_FORM(300), NAME_SR_FORM(300), VERSION_FORM];
};
