import { ColumnsType } from 'antd/es/table';
import { IFuelTypeFilters, IGetFuelType, IPostFuelType } from './fuelType.types';
import {
  APPROVED_TABLE,
  DESCRIPTION_TABLE,
  NAME_SR_TABLE,
  NAME_TABLE,
} from 'modules/sifarnici/sifarniciTableObj.constants';
import { FormInstance } from 'antd';
import dayjs from 'dayjs';
import i18n from 'translations/i18n.config';
import { APPROVED_FORM, DESCRIPTION_FORM, NAME_FORM, NAME_SR_FORM } from 'modules/sifarnici/sifarniciFormObj.constants';

import {
  DateSifarniciFormProps,
  ISifarniciObjectProps,
  ISifarniciTypeOfForm,
  InputNumberSifarniciFormProps,
  InputSifarniciFormProps,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE, MORE_THAN_OR_EQUAL_0_RULE } from 'modules/nriz/nriz.constants';

export const FUEL_TYPE_INITIAL_STATE: IPostFuelType = {
  validity_fromdate: '',
  validity_todate: '',
  fuel_type_code: '',
  name: '',
  name_sr: '',
  unit_id: '',
  description: '',
  display_fuel: '',
  display_fuel_sr: '',
  approved: false,
  is_active: true,
};
export const FUEL_TYPE_FILTERS_INITIAL_STATE: IFuelTypeFilters = {
  validity_fromdate__gte: '',
  validity_todate__lte: '',
  fuel_type_code__ilike: '',
  name__ilike: '',
  name_sr__ilike: '',
  description__ilike: '',
  display_fuel__ilike: '',
  display_fuel_sr__ilike: '',
  unit_id: '',
  approved: null,
  is_active: null,
};
export const FUEL_TYPE_TABLE_DATA: ColumnsType<IGetFuelType> = [
  NAME_TABLE,
  NAME_SR_TABLE,
  {
    title: i18n.t('OBRASCI.OBRAZAC_1.UNIT_OF_MEASURE'),
    width: '200px',
    dataIndex: ['unit', 'name_sr'],
    key: 'unit',
  },
  {
    title: i18n.t('SIFARNICI.OD_DATUMA'),
    width: '200px',
    dataIndex: 'validity_fromdate',
    key: 'validity_fromdate',
    render: (_: any, record: IGetFuelType) => (
      <span>{record.validity_fromdate ? dayjs(record.validity_fromdate).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  {
    title: i18n.t('SIFARNICI.DO_DATUMA'),
    width: '200px',
    dataIndex: 'validity_todate',
    key: 'validity_todate',
    render: (_: any, record: IGetFuelType) => (
      <span>{record.validity_todate ? dayjs(record.validity_todate).format('DD. MM. YYYY.') : ''}</span>
    ),
  },
  {
    title: 'Kod tipa goriva',
    width: '200px',
    dataIndex: 'fuel_type_code',
    key: 'fuel_type_code',
  },

  DESCRIPTION_TABLE,
  {
    title: 'Prikaz goriva',
    width: '200px',
    dataIndex: 'display_fuel',
    key: 'display_fuel',
  },

  {
    title: `Prikaz goriva ${i18n.t('NA_SRPSKOM')}`,
    width: '200px',
    dataIndex: 'display_fuel_sr',
    key: 'display_fuel_sr',
  },
  APPROVED_TABLE,
];
export const FUEL_TYPE_FORM_OPTIONS = (form: FormInstance<IGetFuelType>): ISifarniciTypeOfForm[] => {
  const queryParams = new URLSearchParams(location.search);
  const sifarnik_type = queryParams.get('sifarnik_type');

  return [
    NAME_FORM(300),
    NAME_SR_FORM(300),
    {
      type_of_form: 'searchScroll',
      label: i18n.t('OBRASCI.OBRAZAC_1.UNIT_OF_MEASURE'),
      formName: 'unit_id',
      fetchOptinsList: SIFARNICI_ROUTES.UNIT_CODE,
      // filtersForFetch: {},
      labelOptionAccessor: ['name_sr'],
      valueOptionAccessor: 'id',
      disabledAccessor: 'is_active',
      filtersForFetch: {
        order_by: ['-is_active'],
      },
    } as SearchScrollSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'validity_fromdate',
      filterName: 'validity_fromdate__gte',
      label: i18n.t('SIFARNICI.OD_DATUMA'),
      format: 'DD-MM-YYYY',
      picker: 'date',
      onChange: () => {
        form.setFieldValue('validity_todate', '');
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'date',
      name: 'validity_todate',
      filterName: 'validity_todate__lte',
      label: i18n.t('SIFARNICI.DO_DATUMA'),
      format: 'DD-MM-YYYY',
      disabled: !!(sifarnik_type && !form.getFieldValue('validity_fromdate')),
      picker: 'date',
      disabledDate: (current: any) => {
        if (form.getFieldValue('validity_fromdate')) {
          return current && current.endOf('day') < form.getFieldValue('validity_fromdate');
        }
      },
    } as DateSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'fuel_type_code',
      filterName: 'fuel_type_code__ilike',
      precision: 0,
      // todo: prevod
      label: 'Kod tipa goriva',
      inputName: 'fuel_type_code',
    } as InputSifarniciFormProps,

    DESCRIPTION_FORM(200),
    {
      type_of_form: 'input',
      name: 'display_fuel',
      filterName: 'display_fuel__ilike',
      // todo: prevod
      label: 'Prikaz goriva',
      rules: [MAX_LENGTH_RULE(100)],
      inputName: 'display_fuel',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'display_fuel_sr',
      filterName: 'display_fuel_sr__ilike',
      rules: [MAX_LENGTH_RULE(100)],
      // todo: prevod
      label: `Prikaz goriva ${i18n.t('NA_SRPSKOM')}`,
      inputName: 'display_fuel_sr',
    } as InputSifarniciFormProps,
    APPROVED_FORM,
    {
      type_of_form: 'title',
      title: 'Podaci za EU izveštaj: ',
    } as ISifarniciObjectProps,
    {
      type_of_form: 'input',
      name: 'fuel_input',
      filterName: 'fuel_input__ilike',
      rules: [MAX_LENGTH_RULE(100)],
      // todo: prevod
      label: 'Fuel input',
      inputName: 'fuel_input',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'other_solid_fuel',
      filterName: 'other_solid_fuel__ilike',
      rules: [MAX_LENGTH_RULE(100)],
      // todo: prevod
      label: 'Other solid fuel',
      inputName: 'other_solid_fuel',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'other_gaseous_fuel',
      filterName: 'other_gaseous_fuel__ilike',
      rules: [MAX_LENGTH_RULE(100)],
      // todo: prevod
      label: 'Other gaseous fuel',
      inputName: 'other_gaseous_fuel',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'energy_input_tj',
      filterName: 'energy_input_tj__ilike',
      rules: [MORE_THAN_OR_EQUAL_0_RULE],
      // todo: prevod
      label: 'Energy input',
      inputName: 'energy_input_tj',
    } as InputNumberSifarniciFormProps,
  ];
};
