import { Row, Col } from 'antd';
import { Button } from 'components/Button/Button';
import { ExcelButton } from 'components/Buttons/ExcelButton/ExcelButton';
import { Select } from 'components/Select/Select';
import { PresekCheckState } from 'hooks/usePresekCheck';
import { observer } from 'mobx-react-lite';
import { PreseciTypeEnum, PRESECI_TYPE_OPTIONS } from 'modules/preseci/preseci.constants';
import { preseciStore } from 'modules/preseci/preseci.store';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import styles from './PreseciListHeader.module.scss';
import { CreatePresekPopconfirm } from '../CreatePresekPopconfirm/CreatePresekPopconfirm';

interface IPreseciListHeader {
  presekState: PresekCheckState;
  handlePresekTypeChange: (newType: PreseciTypeEnum) => void;
  presekType: PreseciTypeEnum;
  updatePresekInfo: (presek: PreseciTypeEnum, checkKey: string, value: any) => void;
}

export const PreseciListHeader: FC<IPreseciListHeader> = observer(
  ({ presekState, handlePresekTypeChange, presekType, updatePresekInfo }) => {
    const navigate = useNavigate();

    const onNastaviPresek = () => {
      return navigate(`${RoutePathEnum.PRESEK}/${presekType}`);
    };

    const handleExportExcel = () => {
      preseciStore.exportExcelPreseciList(presekType);
    };

    const onSelectChange = async (value: PreseciTypeEnum) => handlePresekTypeChange(value);

    // todo: prevod

    return (
      <Row justify={'space-between'} align={'middle'} id="preseciHeader">
        <Col span={10}>
          <h1>Obrada i pregled izveštaja za MZŽS</h1>
        </Col>
        <Col span={14} style={{ display: 'flex', justifyContent: 'end' }}>
          <Select
            defaultValue={presekType}
            options={PRESECI_TYPE_OPTIONS}
            name="tip-preseka"
            onChange={onSelectChange}
            className={styles.select}
          />
          {!presekState[presekType].isPresekActive ? (
            <CreatePresekPopconfirm
              presekType={presekType}
              presekState={presekState}
              updatePresekInfo={updatePresekInfo}
            />
          ) : (
            <Button style={{ marginRight: '5px' }} name={'nastavi-presek'} onClick={onNastaviPresek}>
              Nastavi postojeći presek
            </Button>
          )}

          <ExcelButton name="preseci" onClick={handleExportExcel} />
        </Col>
      </Row>
    );
  }
);
