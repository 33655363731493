import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { PRESECI_LIST_FILTERS_MAP, PreseciTypeEnum } from 'modules/preseci/preseci.constants';
import { preseciStore } from 'modules/preseci/preseci.store';
import { IPreseciConfig } from 'modules/preseci/preseci.types';

import { FC } from 'react';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { IFilterTableProps } from 'typescript/NrizTypes';

interface IPreseciFiltersProps {
  presekType: PreseciTypeEnum;
}

export const PreseciListFilters: FC<IPreseciFiltersProps> = observer(({ presekType }) => {
  const [preseciListform] = useForm();

  const fetchList = () => {
    preseciStore.fetchPreseciList(presekType, paginationInitialState);
  };

  const presekConfig: IPreseciConfig = PRESECI_LIST_FILTERS_MAP[presekType];
  const filtersOptions = presekConfig.components(preseciListform, presekType);
  const initialState = { ...presekConfig.initialState, ...filtersStore.getFilters[FiltersNameEnum.LISTA_PRESEKA] };

  const data: IFilterTableProps = {
    form: preseciListform,
    initialState: initialState,
    filterStoreName: FiltersNameEnum.LISTA_PRESEKA,
    filterOptions: filtersOptions,
    resetForm: presekType,
    fetchTableList: fetchList,
  };

  return (
    <div id="filtersPreseci">
      <FilterTableForm data={data} />
    </div>
  );
});
