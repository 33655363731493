import { otpadRepo } from './otpad.repo';
import { IOtpadRequest, IOtpadResponse } from './otpad.types';

class OtpadService {
  fetchOtpadList = (params: any) => {
    return otpadRepo.fetchOtpadList(params);
  };

  createOtpad = (payload: IOtpadRequest) => {
    return otpadRepo.createOtpad(payload);
  };

  updateOtpadInfo = (id: string, payload: Partial<IOtpadResponse>) => {
    return otpadRepo.updateOtpadInfo(id, payload);
  };

  fetchOtpadById = (id: string) => {
    return otpadRepo.fetchOtpadById(id);
  };

  deleteOtpad = (id: string) => {
    return otpadRepo.deleteOtpad(id);
  };
}

export const otpadService = new OtpadService();
