import { IPreseciTranslation } from './preseciTranslation.types';

export const PRESECI_ENGLESKI_TRANSLATION: IPreseciTranslation = {
  PIB_PREDUZECA: 'Company PIB',
  MATICNI_BROJ_PREDUZECA: 'Company enterprise id number',
  NAZIV_PREDUZECA: 'Company name',
  OPSTINA: 'Company municipality',
  MESTO: 'Company location',
  ULICA_BROJ_PREDUZECA: 'Company street and number',
  BROJ_TELEFONA_PREDUZECA: 'Company phone number',
  GRUPA_AMBALAZA: 'Packaging group',
  UKUPNA_NEPOVRATNE: 'Total amount of non-returnable packaging (t)',
  UKUPNA_POVRATNE: 'Total amount of returnable packaging (t)',
  ISKLJUCEN: 'Excluded',
  AUTOMATSKI_ISKLJUCEN: 'Automatically excluded',
  UKUPNA_AMBALAZA: 'Total amount of packaging (t)',
  STATUS: 'Status',
  PERIOD_SLANJA_OD: 'Shipping period from',
  PERIOD_SLANJA_DO: 'Shipping period until',
  GRUPA_AMBALAZE: 'Packaging group',
  BROJ_ZAPISA: 'Record number',
  BROJ_PREDUZECA: 'Number of companies',
  BROJ_IZVESTAJA: 'Report number',
  DATUM_I_VREME_SLANJA_IZVESTAJA: 'Date and time of sending the report',
  DATUM_IZVESTAJA: 'Date of report',
  DATUM_PRESEKA: 'Cut-off date',
  PIB_OPERATERA: 'Operator PIB',
  NAZIV_OPERATERA: 'Operator name',
  BROJ_OPERATERA: 'Number of operators',
  TELEFON_PREDUZECA: 'Company phone number',
  EMAIL_PREDUZECA: 'Company email',
  NACIONALNI_ID: 'National ID',
  NAZIV_POSTROJENJA: 'Plant Name',
  NAZIV_ISPUSTA: 'Discharge Name',
  OZNAKA_ISPUSTA: 'Discharge Label',
  ZONA_SANITARNE_ZASTITE_IZVORISTA: 'Sanitary Protection Zone of the Source',
  VODNA_TELA_NAMENJENA_REKREACIJI: 'Water Bodies for Recreation',
  POV_POSTROJENJE_U_IZGRADNJI_ILI_REKONSTRUKCIJI: 'POV Facility Under Construction or Reconstruction',
  POSEBNA_CENA_U_SKLADU_SA_NAPOMENOM_IZ_TABELE_8_1_ZAKONA: 'Special Price According to Note from Table 8.1 of the Law',
  VREMENSKI_PERIOD_ISPUSTANJA_VODE_DAN_GOD: 'Water Discharge Period (day/year)',
  UKUPNA_KOLICINA_ISPUSTENE_VODE_M3_GOD: 'Total Discharged Water Volume (m³/year)',
  SREDNJI_DNEVNI_PROTOK_OTPADNE_VODE_M3_DAN: 'Average Daily Wastewater Flow (m³/day)',
  KAPACITET_PROIZVODNJE_T_GOD: 'Production Capacity (t/year)',
  ZAGADJUJUCA_MATERIJA: 'Pollutant',
  SREDNJA_GODISNJA_IZMERENA_VREDNOST_MG_L: 'Average Annual Measured Value (mg/L)',
  KOLICINA_PRI_REDOVNOM_RADU_KG_GOD: 'Quantity During Regular Operation (kg/year)',
  GVE: 'Maximum Allowed Value (GVE)',
  GVE_JEDINICA_MERE: 'GVE Unit of Measure',
  BROJ_ZAGADJUJUCIH_MATERIJA: 'Number of Pollutants',
  UKUPNA_KOLICINA_PROIZVODA_DETERDZENTI: 'Total product quantity (kg) - detergents',
  UKUPNA_KOLICINA_FOSFATA_DETERDZENTI: 'Total phosphate quantity (kg) - detergents',
  UKUPNA_KOLICINA_PROIZVODA_MINERALNA_DJUBRIVA: 'Total product quantity (kg) - mineral fertilizers',
  UKUPNA_KOLICINA_AZOTA_MINERALNA_DJUBRIVA: 'Total nitrogen quantity (kg) - mineral fertilizers',
  UKUPNA_KOLICINA_PROIZVODA_SREDSTVA_ZA_ZASTITU_BILJA: 'Total product quantity (kg) - plant protection products',
  UKUPNA_KOLICINA_AKTIVNE_MATERIJE_SREDSTVA_ZA_ZASTITU_BILJA:
    'Total active ingredient quantity (kg) - plant protection products',
  GRUPA_PROIZVODA: 'Product group',
  UKUPNA_KOLICINA_ZA_GRUPU_PROIZVODA: 'Total quantity for the product group (kg)',
  UKUPNA_KOLICINA_MATERIJE: 'Total quantity of material (kg)',
  UKUPNA_KOLICINA_SUMPORNI_OKSIDI: 'Total quantity: Sulfur oxides (SOx/SO2) (kg/year)',
  UKUPNA_KOLICINA_AZOTNI_OKSIDI: 'Total quantity: Nitrogen oxides (NOx/NO2) (kg/year)',
  UKUPNA_KOLICINA_SUSPENDOVANE_CESTICE: 'Total quantity: Suspended particles (PM10) (kg/year)',
  UKUPNA_KOLICINA_UKUPNE_PRASKASTE_MATERIJE: 'Total quantity: Total dust particles (kg/year)',
  UKUPNA_GODISNJA_KOLICINA_OTPAD: 'Total annual quantity of produced waste (t/year)',
  UKUPNA_KOLICINA_OTPAD_SKLADISTENJE: 'Total quantity of waste delivered for storage (t/year)',
  UKUPNA_KOLICINA_OTPAD_ODLAGANJE: 'Total quantity of waste delivered for disposal (t/year)',
  UKUPNA_KOLICINA_OTPAD_TRETMAN: 'Total quantity of waste delivered for treatment (t/year)',
  UKUPNA_KOLICINA_IZVEZENI_OTPAD: 'Total quantity of exported waste (t/year)',
  SUMPORNI_OKSIDI: 'Sulfur oxides (SOx/SO2) (kg/year)',
  AZOTNI_OKSIDI: 'Nitrogen oxides (NOx/NO2) (kg/year)',
  SUSPENDOVANE_CESTICE: 'Suspended particles (PM10) (kg/year)',
  UKUPNE_PRASKASTE_MATERIJE: 'Total dust particles (kg/year)',
  SOURCE_NUMBER: 'Source number',
  SOURCE_NAME: 'Source name',
  GODISNJA_KOLICINA_PROIZVEDENOG_OTPAD: 'Annual quantity of produced waste',
  KOLICINA_OTPAD_SKLADISTENJE: 'Quantity of waste delivered for storage',
  KOLICINA_OTPAD_ODLAGANJE: 'Quantity of waste delivered for disposal',
  KOLICINA_OTPAD_TRETMAN: 'Quantity of waste delivered for treatment',
  KOLICINA_IZVEZENOG_OTPAD: 'Quantity of exported waste',
  VRSTA_PROIZVODA: 'Type of product',
  PROIZVOD: 'Product',
  KOLICINA: 'Amount',
  JEDINICA_MERE: 'Unit',
  PDV: 'VAT',
  ZAVODNI_BROJ_DOPISA: 'Office number of the letter',
  DATUM_DOPISA: 'Date of the letter',
  DATUM_I_VREME_PRESEKA: 'Cut-off date and time',
  UKUPNA_KOLICINA_NOVE: 'Total quantity - new (kg)',
  UKUPAN_PDV_NOVE: 'Total VAT - new (RSD)',
  UKUPNA_KOLICINA_KORIGOVANE: 'Total quantity - corrected (kg)',
  UKUPAN_PDV_KORIGOVANE: 'Total VAT - corrected (RSD)',
  UKUPNA_KOLICINA_BRISANE: 'Total quantity - deleted (kg)',
  UKUPAN_PDV_BRISANE: 'Total VAT - deleted (RSD)',
};
