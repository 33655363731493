import i18n from 'translations/i18n.config';
import {
  GrupaProizvodaPzvEnum,
  IFiltersPzvPreseciList,
  IFiltersPzvSinglePresek,
  IGetPzvPreseciList,
  IGetPzvSinglePresek,
} from './pzvPresek.types';
import { IPreseciColumnProps } from '../preseci.types';
import { FormConfig } from 'modules/obrazacIzvestaji/obrazacIzvestajiFilters.constants';
import { ISifarniciTypeOfForm, SelectSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';
import {
  BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS,
  BROJ_PREDUZECA_COLUMN,
  BROJ_ZAPISA_COLUMN,
  DATUM_SLANJA_COLUMN,
  GODINA_COLUMN,
  MATICNI_BROJ_COLUMN,
  MESTO_PREDUZECA_COLUMN,
  NAZIV_PREDUZECA_COLUMN,
  OPSTINA_PREDUZECA_COLUMN,
  PIB_COLUMN,
  STATUS_COLUMN,
  ULICA_PREDUZECA_COLUMN,
} from '../preseciColumns.constants';
import {
  DATUM_SLANJA_GTE_FILTER,
  DATUM_SLANJA_LTE_FILTER,
  GODINA_FILTER,
  MATICNI_BROJ_FILTER,
  NAZIV_PREDUZECE_FILTER,
  PIB_FILTER,
} from '../preseciFilters.constants';

// todo:prevod

export const GRUPA_PROIZVODA_SELECT = [
  {
    label: 'Deterdženti',
    value: GrupaProizvodaPzvEnum.DETERDZENTI,
  },
  {
    label: 'Mineralna đubriva',
    value: GrupaProizvodaPzvEnum.MINERALNA_DJUBRIVA,
  },
  {
    label: 'Sredstva za zaštitu bilja',
    value: GrupaProizvodaPzvEnum.ZASTITA_BILJA,
  },
];

const GRUPA_PROIZVODA_FILTER: SelectSifarniciFormProps = {
  type_of_form: 'select',
  label: i18n.t('PRESECI.GRUPA_PROIZVODA'),
  name: 'grupa_proizvoda',
  options: GRUPA_PROIZVODA_SELECT,
};

//#region LISTA P
export const PRESECI_PZV_COLUMNS: IPreseciColumnProps<IGetPzvPreseciList>[] = [
  DATUM_SLANJA_COLUMN as unknown as IPreseciColumnProps<IGetPzvPreseciList>,
  BROJ_ZAPISA_COLUMN as IPreseciColumnProps<IGetPzvPreseciList>,
  BROJ_PREDUZECA_COLUMN as IPreseciColumnProps<IGetPzvPreseciList>,
  {
    width: 200,
    dataIndex: 'ukupna_količina_proizvoda_deterdženti',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_PROIZVODA_DETERDZENTI'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_količina_fosfata_deterdženti',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_FOSFATA_DETERDZENTI'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_količina_proizvoda_mineralna_đubriva',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_PROIZVODA_MINERALNA_DJUBRIVA'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_količina_azota_mineralna_đubriva',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_AZOTA_MINERALNA_DJUBRIVA'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_količina_proizvoda_sredstva_zaštitu_bilja',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_PROIZVODA_SREDSTVA_ZA_ZASTITU_BILJA'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_količina_aktivne_sredstva_zaštitu_bilja',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_AKTIVNE_MATERIJE_SREDSTVA_ZA_ZASTITU_BILJA'),
  },
  ...(BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS as IPreseciColumnProps<IGetPzvPreseciList>[]),
];

export const PZV_PRESECI_LIST_FILTERS_INITIAL_STATE: IFiltersPzvPreseciList = {
  datum_slanja__gte: '',
  datum_slanja__lte: '',
  preduzece_id: '',
  grupa_proizvoda: null,
};

export const PZV_PRESECI_LIST_FILTERS_OPTIONS: FormConfig = (_form): ISifarniciTypeOfForm[] => [
  DATUM_SLANJA_GTE_FILTER,
  DATUM_SLANJA_LTE_FILTER,
  NAZIV_PREDUZECE_FILTER(),
  GRUPA_PROIZVODA_FILTER,
];

//#endregion LISTA P

//#region PRESEK
export const SINGLE_PRESEK_PZV_COLUMNS = (): IPreseciColumnProps<IGetPzvSinglePresek>[] => [
  GODINA_COLUMN as IPreseciColumnProps<IGetPzvSinglePresek>,
  PIB_COLUMN as IPreseciColumnProps<IGetPzvSinglePresek>,
  MATICNI_BROJ_COLUMN as IPreseciColumnProps<IGetPzvSinglePresek>,
  NAZIV_PREDUZECA_COLUMN as IPreseciColumnProps<IGetPzvSinglePresek>,
  MESTO_PREDUZECA_COLUMN as IPreseciColumnProps<IGetPzvSinglePresek>,
  OPSTINA_PREDUZECA_COLUMN as IPreseciColumnProps<IGetPzvSinglePresek>,
  ULICA_PREDUZECA_COLUMN as IPreseciColumnProps<IGetPzvSinglePresek>,
  {
    title: `${i18n.t('PRESECI.GRUPA_PROIZVODA')}`,
    dataIndex: 'grupa_proizvoda',
    key: 'naziv_grupe_ambalaze',
    width: '200px',
  },
  {
    title: `${i18n.t('PRESECI.UKUPNA_KOLICINA_ZA_GRUPU_PROIZVODA')}`,
    dataIndex: 'ukupna_kolicina_grupu_proizvoda',
    key: 'ukupna_kolicina_nepovratne_ambalaze_t',
    width: '200px',
  },
  {
    title: `${i18n.t('PRESECI.UKUPNA_KOLICINA_MATERIJE')}`,
    dataIndex: 'ukupna_kolicina_materije',
    key: 'ukupna_kolicina_povratne_ambalaze_t',
    width: '200px',
  },

  STATUS_COLUMN as IPreseciColumnProps<IGetPzvSinglePresek>,
];

export const PZV_SINGLE_PRESEK_FILTER_INITIAL_STATE: IFiltersPzvSinglePresek = {
  maticni_broj__ilike: '',
  godina: '',
  preduzece_id: '',
  iskljucen: null,
  pib__ilike: '',
  grupa_proizvoda: null,
};

export const PZV_SINGLE_PRESEK_FILTERS_OPTIONS: FormConfig = (): ISifarniciTypeOfForm[] => [
  GODINA_FILTER,
  PIB_FILTER({ ilike: true }),
  MATICNI_BROJ_FILTER({ ilike: true }),
  NAZIV_PREDUZECE_FILTER(),
  GRUPA_PROIZVODA_FILTER,
];
//#endregion PRESEK
