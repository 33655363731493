import { Col } from 'antd';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';

import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { MAX_AND_MIN_NUMBER, MORE_THEN_0, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetGve } from 'modules/obrasci/store/gve/gve.types';

import styles from './NewGveDodatniPodaciForm.module.scss';
import { useEffect } from 'react';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';

import { obrasciStore } from 'modules/obrasci/obrasci.store';
import Decimal from 'decimal.js';

export const NewGveDodatniPodaciForm: React.FC<ICrudTypeModalProps> = observer(props => {
  const { t } = useTranslation();
  const form = props.form;

  const onGodisnjaEmisijaInputChange = () => {
    const sezoneArray = ['zima', 'prolece', 'leto', 'jesen'];
    form.validateFields(sezoneArray);
  };

  useEffect(() => {
    if (gveStore.showEmisijeUVazduh) {
      const srednjiProtok = gveStore.initialState.bilans.length
        ? gveStore.initialState.bilans[0].srednji_godisnji_protok_na_mernom_mestu
        : null;
      form.setFieldValue('srednji_godisnji_protok', srednjiProtok);
    }
  }, [gveStore.initialState.bilans.length]);

  const defaultWorkingRegime: ISifarnikInitial | undefined = gveStore.initialState.radni_rezim_ispusta_id
    ? {
        label: (gveStore.initialState as IGetGve).radni_rezim_ispusta.name_sr,
        value: (gveStore.initialState as IGetGve).radni_rezim_ispusta_id,
      }
    : undefined;

  const isDodatniPodaciRequired = gveStore.showEmisijeUVazduh && !!gveStore.initialState.kreiranje_godisnjeg_bilansa;

  const emisijePoSezoniRule = [
    {
      validator() {
        const sezoneArray = ['zima', 'prolece', 'leto', 'jesen'];

        const areSezoneFieldsEmpty = !sezoneArray.some(sezona => form.getFieldValue(sezona));

        if (areSezoneFieldsEmpty) {
          return isDodatniPodaciRequired ? Promise.reject(new Error(t('VALIDATION.IS_REQUIRED'))) : Promise.resolve();
        }
        // if (isDodatniPodaciRequired && areSezoneFieldsEmpty)
        //   return Promise.reject(new Error(t('VALIDATION.IS_REQUIRED')));

        let sum = new Decimal(0);

        sezoneArray.forEach(sezona => {
          sum = sum.plus(form.getFieldValue(sezona) ?? 0);
        });

        if (sum.toNumber() !== 100) {
          return Promise.reject(new Error(t('OBRASCI.GVE.ZBIR_UPOZORENJE')));
        }
        return Promise.resolve();
      },
    },
  ];

  return (
    <Col span={24} className={styles.wrapper}>
      <h3>{t('OBRASCI.GVE.PODACI_O_IZVORU')}</h3>
      <FormInputNumber
        name="godisnja_iskoriscenost_kapaciteta"
        label={t('OBRASCI.GVE.GODISNJA_ISKORISCENOST_KAPACITETA')}
        placeholder={t('OBRASCI.GVE.GODISNJA_ISKORISCENOST_KAPACITETA')}
        inputName={'godisnja_iskoriscenost_kapaciteta'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={MAX_AND_MIN_NUMBER(0, 100)}
      />
      <FormInputNumber
        name="srednja_godisnja_temperatura"
        label={t('OBRASCI.GVE.SREDNJA_GODISNJA_TEMPERATURA')}
        placeholder={t('OBRASCI.GVE.SREDNJA_GODISNJA_TEMPERATURA')}
        inputName={'srednja_godisnja_temperatura'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[...MORE_THEN_0, REQUIRED_FIELD_RULE(isDodatniPodaciRequired)]}
      />
      <FormInputNumber
        name="srednja_godisnja_brzina"
        label={t('OBRASCI.GVE.SREDNJA_GODISNJA_BRZINA')}
        placeholder={t('OBRASCI.GVE.SREDNJA_GODISNJA_BRZINA')}
        inputName={'srednja_godisnja_brzina'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[...MORE_THEN_0, REQUIRED_FIELD_RULE(isDodatniPodaciRequired)]}
      />
      <FormInputNumber
        name="srednji_godisnji_protok"
        label={t('OBRASCI.GVE.SREDNJI_GODISNJI_PROTOK')}
        placeholder={t('OBRASCI.GVE.SREDNJI_GODISNJI_PROTOK')}
        inputName={'srednji_godisnji_protok'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        disabled
        rules={[
          // ...MORE_THEN_0,
          REQUIRED_FIELD_RULE(isDodatniPodaciRequired),
        ]}
      />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'radni_rezim_ispusta_id'}
        fetchOptinsList={gveStore.fetchWorkingRegime}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        label={t('OBRASCI.GVE.RADNI_REZIM_IZVORA')}
        readOnly={false}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        inputName={'radni_rezim_ispusta_id'}
        defaultOption={defaultWorkingRegime}
        placeholder={t('OBRASCI.GVE.RADNI_REZIM_IZVORA')}
        rules={[REQUIRED_FIELD_RULE(isDodatniPodaciRequired)]}
      />

      <h3>{t('OBRASCI.GVE.PODACI_O_RADU')}</h3>
      <FormInputNumber
        name="broj_radnih_dana"
        label={t('OBRASCI.GVE.BROJ_RADNIH_DANA')}
        placeholder={t('OBRASCI.GVE.BROJ_RADNIH_DANA')}
        inputName={'broj_radnih_dana'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        rules={[...MAX_AND_MIN_NUMBER(0, 366), REQUIRED_FIELD_RULE(isDodatniPodaciRequired)]}
      />
      <FormInputNumber
        name="broj_radnih_sati"
        label={t('OBRASCI.GVE.BROJ_RADNIH_SATA')}
        placeholder={t('OBRASCI.GVE.BROJ_RADNIH_SATA')}
        inputName={'broj_radnih_sati'}
        colon={true}
        rules={[...MAX_AND_MIN_NUMBER(0, 24), REQUIRED_FIELD_RULE(isDodatniPodaciRequired)]}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      />
      <FormInputNumber
        name="ukupan_broj_radnih_sati_godisnje"
        label={t('OBRASCI.GVE.UKUPAN_BROJ_RADNIH_SATI')}
        placeholder={t('OBRASCI.GVE.UKUPAN_BROJ_RADNIH_SATI')}
        inputName={'ukupan_broj_radnih_sati_godisnje'}
        colon={true}
        labelCol={{ span: 12 }}
        disabled={!!gveStore.initialState.kreiranje_godisnjeg_bilansa || obrasciStore.zakljucanObrazac}
        wrapperCol={{ span: 12 }}
        rules={[REQUIRED_FIELD_RULE(isDodatniPodaciRequired), ...MAX_AND_MIN_NUMBER(1, 8784)]}
      />

      <h3>
        <span style={{ color: 'red' }}>{isDodatniPodaciRequired ? '*' : ''}</span>
        {t('OBRASCI.GVE.RASPODELA_EMISIJA')}
      </h3>

      <FormInputNumber
        name="zima"
        label={t('OBRASCI.GVE.ZIMA')}
        placeholder={t('OBRASCI.GVE.ZIMA')}
        inputName={'zima'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onInputChange={onGodisnjaEmisijaInputChange}
        rules={(MAX_AND_MIN_NUMBER(0, 100), emisijePoSezoniRule)}
      />
      <FormInputNumber
        name="prolece"
        label={t('OBRASCI.GVE.PROLECE')}
        placeholder={t('OBRASCI.GVE.PROLECE')}
        inputName={'prolece'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onInputChange={onGodisnjaEmisijaInputChange}
        rules={(MAX_AND_MIN_NUMBER(0, 100), emisijePoSezoniRule)}
      />
      <FormInputNumber
        name="leto"
        label={t('OBRASCI.GVE.LETO')}
        placeholder={t('OBRASCI.GVE.LETO')}
        inputName={'leto'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onInputChange={onGodisnjaEmisijaInputChange}
        rules={(MAX_AND_MIN_NUMBER(0, 100), emisijePoSezoniRule)}
      />
      <FormInputNumber
        name="jesen"
        label={t('OBRASCI.GVE.JESEN')}
        placeholder={t('OBRASCI.GVE.JESEN')}
        inputName={'jesen'}
        colon={true}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
        onInputChange={onGodisnjaEmisijaInputChange}
        rules={(MAX_AND_MIN_NUMBER(0, 100), emisijePoSezoniRule)}
      />
    </Col>
  );
});
