import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { observer } from 'mobx-react-lite';
import { FormCheckboxProps } from 'typescript/NrizTypes';
import styles from './FilterCheckboxGroup.module.scss';

export interface FilterCheckboxGroupProps {
  groupName: string;
  checkboxConfig: FormCheckboxProps[];
}

export const FilterCheckboxGroup: React.FC<FilterCheckboxGroupProps> = observer(({ checkboxConfig, groupName }) => {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{groupName}</p>
      <div className={styles.checkboxWrapper}>
        {checkboxConfig.map((checkboxProps, i) => {
          return <FormCheckbox {...checkboxProps} key={i} />;
        })}
      </div>
    </div>
  );
});
