import { makeAutoObservable, runInAction } from 'mobx';
import to from 'await-to-js';
import { nrizService } from 'modules/nriz/nriz.service';
import {
  ICompanyTipoviObrasca,
  IGetCompany,
  IPib,
  IPibMaticniModalData,
  IPostCompany,
} from 'modules/company/company.types';
import { IPagination } from 'typescript';
import { isEmpty } from 'lodash';
import { ITipObrasca } from 'modules/obrasci/obrasci.types';
import { handleSuccessNotify } from 'utils/succesHandling';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { authStore } from '../../store/AuthStore';

import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { CompanyStatus, KoraciRegistracijeKorisnika, companyInitialState } from './company.constants';
import { companyService } from './company.service';
import { IGetSifarnikPretezneDelatnosti } from 'modules/sifarnici/store/sifarnikPretezneDelatnosti/sifarnikPretezneDelatnosti.types';
import { unsanitaryLandfillStore } from 'modules/unsanitarylandfill/unsanitarylandfill.store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { userStore } from 'modules/user/user.store';
import { userService } from 'modules/user/user.service';
import dayjs from 'dayjs';
import { saveBlob } from 'utils/fileDownload';
import { facilityStore } from 'modules/facility/facility.store';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IEmptyResponse, IPaginatedResponse } from 'typescript/NrizTypes';
import { facilityResponsiblePersonMessage } from 'pages/Company/components/FacilityResponsiblePersonMessage/FacilityResponsiblePersonMessage';
import { toggleActivateItems } from 'utils/toggleActivateItems';

class CompanyStore {
  constructor() {
    makeAutoObservable(this);
  }
  company: IPostCompany | IGetCompany = companyInitialState;
  companiesList: IGetCompany[] = [];
  companyTipoviObrazaca: ITipObrasca[] = [];
  companyPagination: IPagination = paginationInitialState;

  initial_tipovi_ids: string[] = [];
  firstPageToShow: KoraciRegistracijeKorisnika | null = null;

  pibMbInfoModalData: IPibMaticniModalData | null = null;

  get getCompany() {
    return this.company;
  }

  get getCompanyTipoviObrazaca() {
    return this.companyTipoviObrazaca;
  }

  get getCompanyTipoviObrazacaIds() {
    return this.companyTipoviObrazaca?.map(tipObrasca => tipObrasca.id) || [];
  }

  get getCompaniesList() {
    return this.companiesList;
  }

  get getPageToShow() {
    return this.firstPageToShow;
  }

  handleChange(key: keyof CompanyStore, value: any) {
    (this as any)[key] = value;
  }

  setCompanyTipoviObrazaca = (selectedTipovi: ITipObrasca[]) => {
    this.companyTipoviObrazaca = selectedTipovi;
  };

  setPibMbInfoModalData = (data: IPibMaticniModalData | null) => {
    this.pibMbInfoModalData = data;
  };

  updateCompanyList = (companyId: string) => {
    this.handleChange('company', { ...this.company, status: 'aktivan' });
    const company = this.companiesList.find((company: IGetCompany) => company.id === companyId);
    if (company) runInAction(() => (company.status = 'aktivan'));
  };

  fetchCompany = async (id: string) => {
    const [err, res] = await to<IGetCompany>(companyService.fetchCompany(id));
    if (err || !res) return;
    this.handleChange('company', res);
  };

  fetchCompaniesList = async (pagination?: Partial<IPagination>) => {
    const [err, res] = await to<IPaginatedResponse<IGetCompany>>(
      companyService.fetchCompaniesList({
        page: pagination?.page || this.companyPagination.page,
        size: pagination?.size || this.companyPagination.size,
        ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.PREDUZECA]),
      })
    );
    if (err || !res) return;

    const { items, page, size, total, pages } = res;
    this.handleChange('companiesList', items);
    this.handleChange('companyPagination', { page, size, total, pages });
    return res;
  };

  exportCompanyExcel = async () => {
    const [err, res] = await to<Blob>(
      companyService.exportCompanyExcel(nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.PREDUZECA]))
    );
    if (err || !res) return;

    const downloadName = `${'Preduzeća'}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, downloadName);
  };

  fetchMesto = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(
      SIFARNICI_ROUTES.MESTO,
      nrizService.pickFields({
        page: pagination.page,
        size: pagination.size,
        search: search,
        location_code__neq: authStore.isAdmin && 999999,
        order_by: '+display_name',
      })
    );

    return response;
  };

  fetchPretezneDelatnostiList = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetSifarnikPretezneDelatnosti>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetSifarnikPretezneDelatnosti>(
      SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
        version__neq: 0,
        order_by: '+code',
      }
    );
    return response;
  };

  fetchCompanyTipoviObrasca = async (id: string) => {
    const [err, res] = await to(companyService.fetchCompanyTipObrasca(id));
    if (err || !res) return;

    this.setCompanyTipoviObrazaca(res);
    const ids = res.map((item: ITipObrasca) => item.id);
    this.handleChange('initial_tipovi_ids', ids);
  };

  postCompanyTipoviObrasca = async (selectedTipovi: ICompanyTipoviObrasca) => {
    if (!(this.company as IGetCompany).id) return;
    const [err, res] = await to(companyService.postCompanyTipObrasca((this.company as IGetCompany).id, selectedTipovi));
    if (err || !res) return Promise.reject();
    handleSuccessNotify();
    const arrObj = obrasciStore.allTipoviObrazacaMapPoId;
    const newArray = selectedTipovi.tipovi_obrazaca.map((id: string) => arrObj[id]);
    this.setCompanyTipoviObrazaca(newArray);
    this.handleChange('initial_tipovi_ids', selectedTipovi.tipovi_obrazaca);
  };

  checkCompany = async () => {
    const [err, res] = await to(companyService.checkCompany());
    if (err || !res) return;

    if (res.korisnik_id === '') return runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.WARNING));
    authStore.setUserId(res.korisnik_id);

    if (isEmpty(res.preduzece)) return runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.PIB));
    runInAction(() => (this.company = res.preduzece));

    if (res.preduzece.status === CompanyStatus.U_PRIPREMI)
      return runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.INFO));
    if (res.preduzece.status === CompanyStatus.AKTIVAN) {
      await obrasciStore.fetchTipoviObrazaca();
      if (!res.preduzece.popunjen_upitnik)
        return runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.SURVEY));
      if (res.preduzece.popunjen_upitnik) {
        // todo: fetch tipovi obrazaca should be moved out from here
        return runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.HOME));
      }
    }
    return (this.firstPageToShow = KoraciRegistracijeKorisnika.WARNING);
  };

  checkPibMaticni = async (params: IPib) => {
    const [err, res] = await to(companyService.checkPibMaticni(params));
    if (err || !res) return;
    return companyService.handleCheckPibMaticni(res, params, 'registration');
  };

  checkApprovePibMaticni = async (params: IPib) => {
    const [err, res] = await to(companyService.checkPibMaticni(params));
    if (err || !res) return;
    return companyService.handleCheckPibMaticni(res, params, 'approval');
  };

  checkIfFacilitiesHaveResponsiblePersons = async () => {
    const [err, res] = await to(
      companyService.checkIfFacilitiesHaveResponsiblePersons((this.getCompany as IGetCompany).id)
    );

    if (err || !res) return;

    return facilityResponsiblePersonMessage(res);
  };

  postPib = async (values: IPib) => {
    const [err, res] = await to(
      companyService.postPib(nrizService.setEmptyValuesToNull({ pib: values.pib, maticni_broj: values.maticni_broj }))
    );
    if (err || !res) return;
    handleSuccessNotify();
    runInAction(() => (this.firstPageToShow = KoraciRegistracijeKorisnika.INFO));
  };

  toggleActivateCompany = async (isActive: boolean) => {
    const [err, res] = await to(companyService.toggleActivateCompany((this.company as IGetCompany).id, isActive));
    if (err || !res) return Promise.reject();
    this.handleChange('company', { ...this.company, is_active: isActive });

    isActive
      ? userStore.setUserList(toggleActivateItems(userStore.getUserList, isActive))
      : userStore.setUserList(userService.deactivateUserList(userStore.getUserList));
    unsanitaryLandfillStore.setUnsanitaryLandfillList(
      toggleActivateItems(unsanitaryLandfillStore.getUnsanitaryLandfillList, isActive)
    );
    facilityStore.setFacilityList(toggleActivateItems(facilityStore.getFacilityList, isActive));
    return handleSuccessNotify();
  };

  putCompany = async (payload: IPostCompany) => {
    const newObject = { id: (this.company as IGetCompany).id, ...payload } as IGetCompany;
    const [err, res] = await to<IEmptyResponse>(
      companyService.putCompany(nrizService.setEmptyValuesToNull(newObject) as IGetCompany)
    );
    if (err || !res) return this.setCompany(this.company);
    handleSuccessNotify();
    let sifarnikMesto = {};
    let sifarnikDelatnost = {};

    if (newObject.mesto_id) {
      sifarnikMesto = nrizService.addSifarnikInObject({
        exId: this.company.mesto_id,
        exObj: (this.company as IGetCompany).mesto,
        list: sifarniciStore.list[SIFARNICI_ROUTES.MESTO],
        name: 'mesto',
        id: newObject.mesto_id,
      });
    }
    if (newObject.sifarnik_pretezne_delatnosti_id) {
      sifarnikDelatnost = nrizService.addSifarnikInObject({
        exId: this.company.sifarnik_pretezne_delatnosti_id,
        exObj: (this.company as IGetCompany).sifarnik_pretezne_delatnosti,
        list: sifarniciStore.list[SIFARNICI_ROUTES.SIFARNIK_PRETEZNE_DELATNOSTI],
        name: 'sifarnik_pretezne_delatnosti',
        id: newObject.sifarnik_pretezne_delatnosti_id,
      });
    }

    this.setCompany({
      ...newObject,
      ...sifarnikMesto,
      ...sifarnikDelatnost,
      status: this.company.status,
      is_active: true,
    });
    return res;
  };

  setCompany = (values: IPostCompany | IGetCompany) => {
    this.company = values;
  };

  setCompaniesList = (values: IGetCompany[]) => {
    this.companiesList = values;
  };

  companyApprove = async (id: string | undefined) => {
    if (!id) return;
    const [err, res] = await to(companyService.companyApprove(id));
    if (err || !res) return;
    handleSuccessNotify();
    this.setCompany({ ...this.company, is_active: true });
    this.updateCompanyList(id);
  };
  companyReject = async (id: string | undefined) => {
    if (!id) return;
    const [err, res] = await to(companyService.companyReject(id));
    if (err || !res) return;
    handleSuccessNotify();
    return res;
  };

  submitCompany = (values: IPostCompany) => {
    return this.putCompany(values);
  };
}

export const companyStore = new CompanyStore();
