import { PlusOutlined } from '@ant-design/icons';
import { PopconfirmProps } from 'antd';
import { Button } from 'components/Button/Button';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';
import { PresekCheckState } from 'hooks/usePresekCheck';
import { PreseciTypeEnum } from 'modules/preseci/preseci.constants';
import { preseciStore } from 'modules/preseci/preseci.store';
import { ICreatePresekPayload } from 'modules/preseci/preseci.types';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import styles from './CreatePresekPopconfirm.module.scss';
import { DatePicker } from 'components/DayjsPicker/DayjsPicker';
import { Dayjs } from 'dayjs';

interface CreatePresekPopconfirmProps {
  presekType: PreseciTypeEnum;
  presekState: PresekCheckState;
  updatePresekInfo: (presek: PreseciTypeEnum, checkKey: string, value: any) => void;
}

interface ButtonPopconfirmProps extends PopconfirmProps {
  name: string;
  showPopconfirm: boolean;
}

export const CreatePresekPopconfirm: React.FC<CreatePresekPopconfirmProps> = ({
  presekType,
  presekState,
  updatePresekInfo,
}) => {
  const navigate = useNavigate();

  const { t } = useTranslation();

  const onCreatePresek = async (additionalInfo?: ICreatePresekPayload) => {
    const ptp2AdditionalInfo: ICreatePresekPayload = additionalInfo ?? { ukljucena_aktuelna_izvestajna_godina: true };
    const validInfo = presekType === PreseciTypeEnum.PTP2 ? ptp2AdditionalInfo : additionalInfo;
    const response = await preseciStore.createSinglePresek(presekType, validInfo);
    if (response) {
      return navigate(`${RoutePathEnum.PRESEK}/${presekType}`);
    }
  };
  const onVisibleChange = (visible: boolean) => {
    if (!visible) updatePresekInfo(PreseciTypeEnum.OTV, 'godinaIzvestaja', null);
  };

  const onOtvDatepickerChange = (value: Dayjs | null) => {
    updatePresekInfo(PreseciTypeEnum.OTV, 'godinaIzvestaja', value);
  };

  const getPopconfirmComponentProps = (): ButtonPopconfirmProps => {
    switch (presekType) {
      case PreseciTypeEnum.AAO1:
        return {
          title: undefined,
          showPopconfirm: false,
          name: 'create-aao1-presek-btn',
        };
      case PreseciTypeEnum.AAO2:
        return {
          title: undefined,
          showPopconfirm: false,
          name: 'create-aao2-presek-btn',
        };
      case PreseciTypeEnum.PZV:
        return {
          title: undefined,
          showPopconfirm: false,
          name: 'create-pzv-presek-btn',
        };
      case PreseciTypeEnum.PTP2:
        return {
          showPopconfirm: !presekState[PreseciTypeEnum.PTP2].additionalPresekInfo.ukljucenaGodina,
          name: 'create-ptp2-presek-popconfirm',
          title: undefined,
          placement: 'bottom',
          okText: 'Da',
          icon: <span />,
          cancelText: 'Ne',
          description: 'Da li želite da uključite aktuelnu godinu u izveštaj?',
          onConfirm: () =>
            onCreatePresek({
              ukljucena_aktuelna_izvestajna_godina: true,
            }),
          onCancel: () =>
            onCreatePresek({
              ukljucena_aktuelna_izvestajna_godina: false,
            }),

          okButtonProps: { disabled: false },
          cancelButtonProps: { disabled: false },
        };
      case PreseciTypeEnum.VELIKI_ZAGADJIVACI:
        return {
          title: undefined,
          showPopconfirm: false,
          name: 'create-veliki-zagadjivaci-presek-btn',
        };
      case PreseciTypeEnum.OTV:
        return {
          showPopconfirm: presekType === PreseciTypeEnum.OTV,
          name: 'create-otv-presek-btn',
          title: 'Izaberite godinu preseka',
          okText: t('Napravi presek'),
          cancelText: t('Odbaci'),
          onCancel: () => updatePresekInfo(PreseciTypeEnum.OTV, 'godinaIzvestaja', null),
          onOpenChange: onVisibleChange,
          okButtonProps: { disabled: !presekState[PreseciTypeEnum.OTV].additionalPresekInfo.godinaIzvestaja },
          onConfirm: () =>
            onCreatePresek({
              godina: Number(presekState[PreseciTypeEnum.OTV].additionalPresekInfo.godinaIzvestaja?.year()),
            }),
          placement: 'bottom',
          description: (
            <DatePicker
              style={{ width: '250px' }}
              className={styles.yearPicker}
              name={'godina-izvestaja'}
              format="YYYY"
              placement="bottomRight"
              value={presekState[PreseciTypeEnum.OTV].additionalPresekInfo.godinaIzvestaja}
              picker={'year' as any}
              onChange={onOtvDatepickerChange}
            />
          ),
        };
      default:
        return {
          title: undefined,
          showPopconfirm: false,
          name: 'create-no-presek-btn',
        };
    }
  };

  const { showPopconfirm, ...popconfirmProps } = getPopconfirmComponentProps();

  return showPopconfirm ? (
    <Popconfirm {...popconfirmProps}>
      <Button name="icon-plus" style={{ marginRight: '5px' }}>
        Napravi presek
        <PlusOutlined />
      </Button>
    </Popconfirm>
  ) : (
    <Button name="icon-plus" style={{ marginRight: '5px' }} onClick={() => onCreatePresek()}>
      Napravi presek
      <PlusOutlined />
    </Button>
  );
};
