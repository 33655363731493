import {
  IAAO1Response,
  IPostAAO1,
  IPostKolicinaAmbalaze,
  IPostOperateri,
  IPostUpravljanjeAmbalaznimOtpadom,
} from './aao1.types';

export const AAO1_INITIAL_STATE: IPostAAO1 = {
  godina: '',
  preduzece_id: '',
  proizvodjac_ambalaze: false,
  paker_proizvoda_u_ambalazu_ili_punilac_ambalaze: false,
  isporucilac_koji_stavlja_u_promet_ambalazu_ili_upakovani_proizvod: false,
  uvoznik_ambalaze: false,
  izvoznik_ambalaze: false,
  krajni_korisnik: false,
  broj_dozvole: '',
  datum_izdavanja: '',
  napomena: '',
  kolicina_ambalaze: [],
  upravljanje_ambalaznim_otpadom: [],
};

export const AAO1_KOLICINA_AMBALAZA_INITIAL_STATE: IPostKolicinaAmbalaze = {
  type_of_packing_id: '',
  proizvedena_kolicina_nepovratne_ambalaze: null,
  uvezena_kolicina_nepovratne_ambalaze: null,
  izvezena_kolicina_nepovratne_ambalaze: null,
  proizvedena_kolicina_povratne_ambalaze: null,
  uvezena_kolicina_povratne_ambalaze: null,
  izvezena_kolicina_povratne_ambalaze: null,
  da_li_je_preneta_kolicina_ambalaze_na_operatera: false,
  kolicina_preneta_na_operatera: null,
  operateri: [],
};

export const AAO1_OPERATERI_INITIAL_STATE: IPostOperateri = {
  operators_package_waste_manage_id: '',
  kolicina: null,
};

export const AAO1_UPRAVLJANJE_AMBALAZNIM_OTPADOM_INITIAL_STATE: IPostUpravljanjeAmbalaznimOtpadom = {
  type_of_packing_id: '',
  kolicina_za_samostalno_upravljanje: null,
  preuzeta_kolicina: null,
  kolicina_za_r1_operaciju: null,
  kolicina_za_r3_operaciju: null,
  druga_operacija_r_id: '',
  kolicina_za_drugu_r_operaciju: null,
  kolicina_za_d1_operaciju: null,
  druga_operacija_d_id: '',
  kolicina_za_drugu_d_operaciju: null,
};

export type BooleanKeys<T> = {
  [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

export const AAO1_COMPANY_OPTIONS: BooleanKeys<IAAO1Response>[] = [
  'proizvodjac_ambalaze',
  'paker_proizvoda_u_ambalazu_ili_punilac_ambalaze',
  'isporucilac_koji_stavlja_u_promet_ambalazu_ili_upakovani_proizvod',
  'uvoznik_ambalaze',
  'izvoznik_ambalaze',
  'krajni_korisnik',
];
