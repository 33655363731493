import { useNavigate, useLocation } from 'react-router-dom';

interface ReactLinkProps {
  to: string;
  children: React.ReactNode;
  className?: string;
  activeClassName?: string;
}

export const ReactLink: React.FC<ReactLinkProps> = ({ to, children, className, activeClassName, ...props }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isExternal = /^https?:\/\//.test(to);
  const isActive = location.pathname === to;

  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    if (isExternal) return;

    if (e.button === 1 || e.ctrlKey || e.metaKey) {
      return;
    }

    e.preventDefault();
    navigate(to);
  };

  return (
    <a
      href={to}
      onClick={handleClick}
      className={`${className || ''} ${isActive && activeClassName ? activeClassName : ''}`}
      {...props}
    >
      {children}
    </a>
  );
};
