import to from 'await-to-js';
import { otvRepo } from './otv.repo';
import { IOTVRegistar, OTVRequest } from './otv.types';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { BooleanKeys } from 'modules/obrasci/store/aao1/aao1.constants';

class OTVService {
  async fetchOtvList(filters: any) {
    const [err, res] = await to(otvRepo.fetchOtvList(filters));
    if (err) return { items: [], ...paginationInitialState };
    return res;
  }

  async fetchOtvById(id: string) {
    const [err, res] = await to(otvRepo.fetchOtvById(id));
    if (err) return undefined;
    return res;
  }

  async postOtv(payload: OTVRequest) {
    return otvRepo.postOtv(payload);
  }

  async updateOtv(payload: IOTVRegistar) {
    const otvRegistarBooleanKeys: BooleanKeys<IOTVRegistar>[] = [
      'posebna_cena',
      'pov_postrojenje_u_izgradnji_ili_rekonstrukciji',
      'vodna_tela_namenjena_rekreaciji',
      'zona_sanitarne_zastite_izvorista',
    ];

    otvRegistarBooleanKeys.forEach(key => {
      payload[key] = payload[key] ?? false;
    });

    return otvRepo.updateOtv(payload, payload.id);
  }
}

export const otvService = new OTVService();
