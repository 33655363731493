import { Col, Form, message, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { Button } from 'components/Button/Button';
import { Content } from 'components/Content/Content';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormInput } from 'components/FormInput/FormInput';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { ReactLink } from 'components/ReactLink/ReactLink';
import dayjs, { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { MAX_LENGTH_RULE, MORE_THEN_0_IS_REQUIRED, REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { dozvoleStore } from 'modules/registarDozvola/dozvole/dozvole.store';
import { IDozvolaRequest } from 'modules/registarDozvola/dozvole/dozvole.types';
import { otpadStore } from 'modules/registarDozvola/otpad/otpad.store';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import { IRangePickerProps } from 'typescript';
import { disabledTodayAndFutureDates } from 'utils/formatDate';
import styles from './DozvolaZaOtpad.module.scss';
import TableData from 'components/TableData/TableData';
import { ColumnType } from 'antd/es/table';
import { EditOutlined } from '@ant-design/icons';
import { IOtpadResponse } from 'modules/registarDozvola/otpad/otpad.types';
import { otpadListColumns } from 'modules/registarDozvola/otpad/otpad.constants';
import { modalStore } from 'store';
import { OtpadDozvolaModal } from '../Otpadi/OtpadDozvolaModal';
import { dozvoleService } from 'modules/registarDozvola/dozvole/dozvole.service';
import { OtpadFilters } from './OtpadFilters/OtpadFilters';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';
import { DatePicker } from 'components/DayjsPicker/DayjsPicker';
import { FormSelect } from 'components/FormSelect/FormSelect';

const DozvolaHeader = observer(() => {
  const { idOperatera, idDozvole } = useParams();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingInline: '10px' }}>
      {idDozvole ? <h2>Dozvola: {dozvoleStore.selectedDozvola?.registarski_broj_dozvole}</h2> : <h2>Nova dozvola</h2>}
      <ReactLink to={`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/operater/${idOperatera}`}>
        <Button name="back-to-operater-btn">Nazad</Button>
      </ReactLink>
    </div>
  );
});

interface DozvoleFormHeaderProps {
  isFormEditable: boolean;
  setIsFormEditable: Dispatch<SetStateAction<boolean>>;
  datumIzmene: Dayjs | null;
  setDatumIzmene: Dispatch<SetStateAction<Dayjs | null>>;
}

const DozvoleFormHeader: React.FC<DozvoleFormHeaderProps> = observer(
  ({ isFormEditable, setIsFormEditable, datumIzmene, setDatumIzmene }) => {
    const { idOperatera, idDozvole } = useParams();
    const form = useFormInstance();

    const navigate = useNavigate();

    const onDiscardClick = () => {
      form.resetFields();
      setIsFormEditable(false);
    };

    const onChangeClick = () => {
      setIsFormEditable(true);
    };

    const getDisabledDatesForDatumIzmene: IRangePickerProps['disabledDate'] = current => {
      const datumVaziOd = form.getFieldValue('vazi_od') as Dayjs | null;
      const datumVaziDo = form.getFieldValue('vazi_do') as Dayjs | null;
      if (!datumVaziOd || !datumVaziDo) return false;
      return current && (current < dayjs(datumVaziOd).endOf('day') || current > datumVaziDo);
    };

    return (
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
        <h3>Podaci o dozvoli</h3>
        {idDozvole ? (
          isFormEditable ? (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button name="discard-dozvola-changes-btn" htmlType="button" onClick={onDiscardClick} danger>
                Odbaci
              </Button>
              <Popconfirm
                name="update-dozvola-popconfirm"
                title="Unesite datum prekida važenja dozvole"
                okText="Izmeni dozvolu"
                cancelText="Odbaci"
                onCancel={() => {
                  form.resetFields();
                  setIsFormEditable(false);
                }}
                okButtonProps={{ disabled: !datumIzmene }}
                onConfirm={form.submit}
                placement={'bottom'}
                description={
                  <DatePicker
                    style={{ width: '250px' }}
                    className={styles.yearPicker}
                    name={'godina-izvestaja'}
                    format="DD-MM-YYYY"
                    placement="bottomLeft"
                    value={datumIzmene}
                    picker="date"
                    disabledDate={getDisabledDatesForDatumIzmene}
                    onChange={date => setDatumIzmene(date)}
                  />
                }
              >
                <Button name="update-dozvola-btn" htmlType="button">
                  Sačuvaj
                </Button>
              </Popconfirm>
            </div>
          ) : (
            <div style={{ display: 'flex', gap: '10px' }}>
              <Popconfirm
                name="delete-dozvola-popconfirm"
                title={'Da li ste sigurni da želite da izbrišete dozvolu?'}
                okText={'Obriši'}
                okButtonProps={{ disabled: false }}
                cancelButtonProps={{ disabled: false }}
                cancelText={'Odbaci'}
                onConfirm={async () => {
                  const response = await dozvoleStore.deleteDozvola(idDozvole);
                  if (response) navigate(`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/${idOperatera}/`);
                }}
                placement="bottom"
              >
                <Button name={'delete-dozvola'} danger>
                  Obriši
                </Button>
              </Popconfirm>
              <Button name="toggle-dozvola-form-editable-btn" onClick={onChangeClick} htmlType="button">
                Izmeni
              </Button>
            </div>
          )
        ) : (
          <Button name="save-dozvola-btn" htmlType="submit">
            Sačuvaj
          </Button>
        )}
      </div>
    );
  }
);

const DozvoleForm = observer(() => {
  const { idOperatera, idDozvole } = useParams();

  const [isFormEditable, setIsFormEditable] = useState(!idDozvole);

  const navigate = useNavigate();

  const [form] = useForm<IDozvolaRequest>();

  const [datumIzmene, setDatumIzmene] = useState<Dayjs | null>(null);

  const handleDozvoleUseEffect = async (id: string) => {
    const fetchedDozvola = await dozvoleStore.fetchDozvolaById(id);
    if (fetchedDozvola) {
      await otpadStore.fetchOtpadList(otpadStore.otpadPagination, id);
      form.setFieldsValue(fetchedDozvola);
    }
  };

  const handleFormSubmit = async (formValues: IDozvolaRequest) => {
    if (!idOperatera) throw new Error('Operator ID missing!');
    const validValues = dozvoleService.formatPayloadDates({ ...formValues, operater_id: idOperatera });
    try {
      if (idDozvole && datumIzmene) {
        const payload = { ...validValues, id: idDozvole };
        const { id: newDozvolaId } = await dozvoleStore.updateDozvolaInfo({
          ...payload,
          datum: dayjs(datumIzmene).format('YYYY-MM-DD'),
        });
        navigate(`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/${idOperatera}/dozvola/${newDozvolaId}`);
        message.success('Uspešno izmenjena dozvola!');
      } else {
        const { id } = await dozvoleStore.createDozvola(validValues);
        navigate(`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/${idOperatera}/dozvola/${id}`);
        message.success('Uspešno kreirana dozvola!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (idDozvole) handleDozvoleUseEffect(idDozvole);
  }, [idDozvole]);

  const defaultOpstinaOption: ISifarnikInitial | undefined = dozvoleStore.selectedDozvola?.sifra_opstine_id
    ? {
        label: dozvoleStore.selectedDozvola?.sifra_opstine?.display_name,
        value: dozvoleStore.selectedDozvola?.sifra_opstine_id,
      }
    : undefined;

  const defaultOpstinaPostrojenjaOption: ISifarnikInitial | undefined = dozvoleStore.selectedDozvola
    ?.opstina_postrojenja_id
    ? {
        label: dozvoleStore.selectedDozvola?.opstina_postrojenja?.display_name,
        value: dozvoleStore.selectedDozvola?.opstina_postrojenja_id,
      }
    : undefined;

  const defaultIzdavalacOption: ISifarnikInitial | undefined = dozvoleStore.selectedDozvola?.izdavalac_dozvole_id
    ? {
        label: dozvoleStore.selectedDozvola?.izdavalac_dozvole_id,
        value: dozvoleStore.selectedDozvola?.izdavalac_dozvole_id,
      }
    : undefined;

  const handleDatumIzdavanjaChange = (date: Dayjs | null) => {
    if (!date || date > dayjs(form.getFieldValue('vazi_do'))) form.setFieldValue('vazi_do', null);
  };

  const getDisabledDatesForDatumIsteka: IRangePickerProps['disabledDate'] = current => {
    const datumIzdavanjaDozvole = form.getFieldValue('vazi_od') as Dayjs | null;
    if (!datumIzdavanjaDozvole) return false;
    return current && current < dayjs(datumIzdavanjaDozvole);
  };

  return (
    <Form
      labelCol={{ span: 10 }}
      wrapperCol={{ span: 14 }}
      labelAlign="left"
      onFinish={handleFormSubmit}
      initialValues={dozvoleStore.selectedDozvola}
      form={form}
    >
      <div style={{ padding: '20px' }}>
        <DozvoleFormHeader
          isFormEditable={isFormEditable}
          setIsFormEditable={setIsFormEditable}
          datumIzmene={datumIzmene}
          setDatumIzmene={setDatumIzmene}
        />
        <Row
          style={{ padding: '20px', marginBottom: '15px', backgroundColor: 'white', marginInline: '0px' }}
          gutter={20}
        >
          <Col span={12}>
            <FormSearchInfiniteScroll
              showSearch={true}
              formName={'sifra_opstine_id'}
              label="Opština"
              placeholder=""
              rules={[REQUIRED_FIELD_RULE(true)]}
              fetchOptinsList={dozvoleStore.fetchOpstina}
              labelOptionAccessor="display_name"
              valueOptionAccessor="id"
              readOnly={!isFormEditable}
              defaultOption={defaultOpstinaOption}
            />
            <FormInputNumber
              name="registarski_broj_dozvole"
              inputName="registarski_broj_dozvole"
              rules={MORE_THEN_0_IS_REQUIRED}
              label="Registarski broj dozvole"
              readOnly={!isFormEditable}
            />
            <FormInput
              name="broj_dosijea"
              inputName="broj_dosijea"
              rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(50)]}
              label="Broj dosijea"
              readOnly={!isFormEditable}
            />

            <FormSearchInfiniteScroll
              showSearch={true}
              formName={'izdavalac_dozvole_id'}
              label="Izdavalac dozvole"
              placeholder=""
              rules={[REQUIRED_FIELD_RULE(true)]}
              fetchOptinsList={dozvoleStore.fetchIzdavalacDozvole}
              labelOptionAccessor="name"
              valueOptionAccessor="id"
              readOnly={!isFormEditable}
              defaultOption={defaultIzdavalacOption}
            />
            <h4 style={{ marginBottom: '15px' }}>Podaci o postrojenju</h4>
            <FormInput
              name="naziv_postrojenja"
              inputName="naziv_postrojenja"
              label="Naziv postrojenja"
              readOnly={!isFormEditable}
            />

            <FormInput
              name="adresa_postrojenja"
              inputName="adresa_postrojenja"
              label="Adresa postrojenja"
              readOnly={!isFormEditable}
            />
            <FormSearchInfiniteScroll
              showSearch={true}
              formName={'opstina_postrojenja_id'}
              label="Opština postrojenja"
              placeholder=""
              fetchOptinsList={dozvoleStore.fetchOpstina}
              labelOptionAccessor="display_name"
              valueOptionAccessor="id"
              readOnly={!isFormEditable}
              defaultOption={defaultOpstinaPostrojenjaOption}
            />
            <FormInput
              name="opis_postrojenja"
              inputName="opis_postrojenja"
              label="Opis postrojenja"
              readOnly={!isFormEditable}
            />
          </Col>

          <Col span={12}>
            <FormDatePicker
              name="vazi_od"
              label="Važi od"
              format="DD-MM-YYYY"
              picker={'date'}
              disabledDate={disabledTodayAndFutureDates}
              rules={[REQUIRED_FIELD_RULE(true)]}
              readOnly={!isFormEditable}
              onChange={handleDatumIzdavanjaChange}
            />
            <FormDatePicker
              name="vazi_do"
              label="Važi do"
              format="DD-MM-YYYY"
              picker={'date'}
              disabledDate={getDisabledDatesForDatumIsteka}
              rules={[REQUIRED_FIELD_RULE(true)]}
              readOnly={!isFormEditable}
            />
            <FormSelect
              name="privremena_dozvola"
              label="Privremena dozvola"
              options={[
                { label: 'Da', value: 'da' },
                { label: 'Ne', value: 'ne' },
              ]}
              readOnly={!isFormEditable}
            />
            {idDozvole && (
              <>
                <FormInputNumber
                  name="verzija"
                  inputName="verzija"
                  label="Verzija"
                  readOnly={!isFormEditable}
                  disabled
                />
                <FormSelect
                  name="status"
                  label="Status dozvole"
                  options={[
                    { label: 'Aktivna', value: 'aktivna' },
                    { label: 'Izmenjena', value: 'izmenjena' },
                    { label: 'Oduzeta', value: 'oduzeta' },
                  ]}
                  disabled
                  readOnly={!isFormEditable}
                />
              </>
            )}
          </Col>
          <Row className={styles.dozvolaNapomena}>
            <FormTextArea
              name="napomena"
              rows={2}
              label="Napomena"
              rules={[MAX_LENGTH_RULE(3000)]}
              labelCol={5}
              wrapperCol={19}
              readOnly={!isFormEditable}
            />
          </Row>
        </Row>
      </div>
    </Form>
  );
});

export const DozvolaZaOtpad = observer(() => {
  const { idDozvole } = useParams();

  const handleEditButtonClick = async (otpadId: string) => {
    await otpadStore.fetchOtpadById(otpadId);
    modalStore.changeModalName('dozvole-otpad-modal');
  };

  const columns: ColumnType<IOtpadResponse>[] = [
    ...otpadListColumns,
    {
      fixed: 'right',
      align: 'center',
      width: 70,

      render: (_: any, record: IOtpadResponse) => {
        return <Button icon={<EditOutlined />} name="edit-operater" onClick={() => handleEditButtonClick(record.id)} />;
      },
    },
  ];

  const refetchData = () => {
    otpadStore.fetchOtpadList(otpadStore.otpadPagination, idDozvole);
  };

  return (
    <Content header={<DozvolaHeader />}>
      <DozvoleForm />
      <div style={{ padding: '20px' }}>
        {idDozvole && (
          <>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}
            >
              <h4 style={{ marginBottom: '10px' }}>Lista otpada</h4>
              <Button
                name="open-dozvola-otpad-modal-btn"
                onClick={() => modalStore.changeModalName('dozvole-otpad-modal')}
              >
                Dodaj novi otpad
              </Button>
            </div>
            <OtpadFilters idDozvole={idDozvole} />
            <TableData
              name="otpad-po-dozovli-table"
              dataSource={otpadStore.otpadList}
              storePagination={otpadStore.otpadPagination}
              columns={columns}
              refetchData={refetchData}
            />
          </>
        )}
      </div>
      <OtpadDozvolaModal />
    </Content>
  );
});
