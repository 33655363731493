import { EditOutlined } from '@ant-design/icons';
import { ColumnType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import { Content } from 'components/Content/Content';
import { ReactLink } from 'components/ReactLink/ReactLink';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { operateriListColumns } from 'modules/registarDozvola/operateri/operateri.constants';
import { operateriStore } from 'modules/registarDozvola/operateri/operateri.store';
import { IOperaterOtpadaResponse } from 'modules/registarDozvola/operateri/operateri.types';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import { OperateriFilters } from './OperateriFilters/OperaterfiFilters';

const OperateriHeader = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/operater/`);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h2>Operateri za upravljanje otpadom</h2>
      <Button name="create-new-operater" onClick={handleButtonClick}>
        Novi operater
      </Button>
    </div>
  );
};

export const OperateriList = observer(() => {
  useEffect(() => {
    operateriStore.fetchOperateriList();
  }, []);

  const columns: ColumnType<IOperaterOtpadaResponse>[] = [
    ...operateriListColumns,

    {
      fixed: 'right',
      align: 'center',
      width: 70,

      render: (_: any, record: IOperaterOtpadaResponse) => {
        return (
          <ReactLink to={`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/operater/${record.id}`}>
            <Button icon={<EditOutlined />} name="edit-operater" />
          </ReactLink>
        );
      },
    },
  ];

  return (
    <Content header={<OperateriHeader />}>
      <OperateriFilters />
      <TableData
        name="operateri-otpad-table"
        columns={columns}
        dataSource={operateriStore.operateriList}
        storePagination={operateriStore.operateriPagination}
        refetchData={operateriStore.fetchOperateriList}
      />
    </Content>
  );
});
