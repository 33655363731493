import { Row, Col, Segmented } from 'antd';
import { SegmentedValue } from 'antd/es/segmented';
import { Button } from 'components/Button/Button';
import { observer } from 'mobx-react-lite';
import { AAO2_SEGMENT_OPTIONS } from 'modules/preseci/aao2Presek/aao2Presek.constants';
import { PreseciTypeEnum } from 'modules/preseci/preseci.constants';
import { preseciStore } from 'modules/preseci/preseci.store';
import { FC, useEffect } from 'react';
import styles from './AdditionalButtonsSinglePresek.module.scss';
import { VELIKI_ZAGADJIVACI_SEGMENT_OPTIONS } from 'modules/preseci/velikiZagadjivaciPresek/velikiZagadjivaciPresek.constants';
import { modalStore } from 'store';
import { Ptp2UparivanjeButtons } from './Ptp2UparivanjeButtons/Ptp2UparivanjeButtons';

const UkljuciIskljuciButtons: FC = observer(() => {
  const onButtonClick = () => {
    modalStore.changeModalName('ukljuci-iskljuci-preseci-modal');
  };
  return (
    <Row justify={'end'} gutter={20} className={styles.wrapper}>
      <Col>
        <Button name="ukljuci-filteri" onClick={onButtonClick}>
          Uključi / Isključi
        </Button>
      </Col>
    </Row>
  );
});

interface ISinglePresekSegmentButtons {
  fetchPresekList: () => void;
  options: { label: string; value: string }[];
}

const SinglePresekSegmentButtons: FC<ISinglePresekSegmentButtons> = observer(({ fetchPresekList, options }) => {
  const onSegmentedChange = (value: SegmentedValue) => {
    preseciStore.handleChange('vrstaIzvestaja', value as string);
    fetchPresekList();
  };

  useEffect(() => {
    return () => preseciStore.handleChange('vrstaIzvestaja', '1');
  }, []);

  return (
    <div className={styles.wrapper}>
      <Segmented options={options} size="large" onChange={onSegmentedChange} />
    </div>
  );
});

interface IAdditionalButtonsSinglePresekProps {
  presekType: PreseciTypeEnum;
  datumPreseka: string | null | undefined;
  fetchPresekList: () => void;
}

type IPreseciConfigAdditionalButtons = {
  [key in PreseciTypeEnum]: React.ReactNode | null;
};

export const AdditionalButtonsSinglePresek: FC<IAdditionalButtonsSinglePresekProps> = observer(
  ({ presekType, datumPreseka, fetchPresekList }) => {
    const options: IPreseciConfigAdditionalButtons = {
      [PreseciTypeEnum.PTP2]: !datumPreseka ? <Ptp2UparivanjeButtons /> : null,
      [PreseciTypeEnum.AAO1]: !datumPreseka ? <UkljuciIskljuciButtons /> : null,
      [PreseciTypeEnum.AAO2]: (
        <SinglePresekSegmentButtons options={AAO2_SEGMENT_OPTIONS} fetchPresekList={fetchPresekList} />
      ),
      [PreseciTypeEnum.OTV]: null,
      [PreseciTypeEnum.PZV]: !datumPreseka ? <UkljuciIskljuciButtons /> : null,
      [PreseciTypeEnum.VELIKI_ZAGADJIVACI]: (
        <SinglePresekSegmentButtons options={VELIKI_ZAGADJIVACI_SEGMENT_OPTIONS} fetchPresekList={fetchPresekList} />
      ),
    };

    return options[presekType] ? <div id="additionalButtons">{options[presekType]}</div> : <></>;
  }
);
