import { makeAutoObservable } from 'mobx';
import { UserToken } from 'typescript';
import Keycloak from 'keycloak-js';
import { AdminRoles, CompanyRoles, IGetUser, IPostUser, KeycloakUserInfo, UserRoles } from 'modules/user/user.types';
import { companyStore } from 'modules/company/company.store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { userInitialState } from 'modules/user/user.constants';

export const rolesForCompanyCheck = [UserRoles.CompanyOwner, UserRoles.CompanyUser, UserRoles.FacilityUser];

class AuthStore {
  constructor() {
    makeAutoObservable(this, {});
  }
  initComplete = false;
  userAuthenticated = false;
  authUser: IGetUser | IPostUser = userInitialState;
  userToken: UserToken | null = null;
  userId = '';
  keycloak = new Keycloak({
    url: window.__ENV__.KEYCLOAK_URL,
    realm: 'nriz',
    clientId: 'nriz-client',
  });
  userInfo: KeycloakUserInfo | null = null;
  get getInitComplete() {
    return this.initComplete;
  }
  get getUserAuthenticated() {
    return this.keycloak.authenticated;
  }
  get getUserToken() {
    return this.userToken;
  }
  get roles() {
    return this.userInfo?.groups || [];
  }
  get isAdmin() {
    if (!this.roles.length) return false;
    return this.roles.some(role => AdminRoles.includes(role));
  }
  get isCompanyRole() {
    if (!this.roles.length) return false;
    return this.roles.some(role => CompanyRoles.includes(role));
  }
  get isSuperAdmin() {
    if (!this.roles.length) return false;
    return this.roles.includes(UserRoles.SuperAdmin);
  }
  get isSystemAdmin() {
    if (!this.roles.length) return false;
    return this.roles.includes(UserRoles.SystemAdmin);
  }
  get isCompanyOwner() {
    if (!this.roles.length) return false;
    return this.roles.includes(UserRoles.CompanyOwner);
  }
  get hasBothUserRoles() {
    if (!this.roles.length || this.roles.length === 1) return false;
    if (this.isAdmin) return false;
    if (this.isCompanyOwner) return false;
    return this.roles.includes(UserRoles.CompanyUser) && this.roles.includes(UserRoles.FacilityUser);
  }
  get isCompanyUser() {
    if (!this.roles.length) return false;
    if (this.isAdmin) return false;
    if (this.isCompanyOwner) return false;
    return this.roles.includes(UserRoles.CompanyUser);
  }

  get isOnlyCompanyUser() {
    if (!this.roles.length) return false;
    return this.roles.every(role => role === UserRoles.CompanyUser);
  }

  get isFacilityUser() {
    if (!this.roles.length) return false;
    if (this.isAdmin) return false;
    if (this.isCompanyOwner) return false;
    return this.roles.includes(UserRoles.FacilityUser);
  }

  get isOnlyFacilityUser() {
    if (!this.roles.length) return false;
    return this.roles.every(role => role === UserRoles.FacilityUser);
  }

  get isObradaDozvolaRole() {
    if (!this.roles.length) return false;
    return this.roles.includes(UserRoles.ObradaDozvola);
  }

  get getSelectedJezik() {
    return this.userInfo?.jezik;
  }

  handleChange(key: keyof AuthStore, value: any) {
    (this as any)[key] = value;
  }

  initKeycloak = async () => {
    this.keycloak.onTokenExpired = () => this.keycloak.updateToken();
    const authenticated = await this.keycloak.init({ onLoad: 'check-sso' });
    if (authenticated && this.keycloak.authenticated) {
      const userInfo: KeycloakUserInfo = await this.keycloak.loadUserInfo();
      if (userInfo && userInfo.internal_user_id) {
        this.setUserInfo(userInfo);
        this.setUserId(userInfo.internal_user_id);
        if (userInfo.groups && rolesForCompanyCheck.includes(userInfo.groups[0])) {
          await companyStore.checkCompany();
          if (companyStore.company) return this.setInitComplete(true);
        }
        // todo: fetch tipovi obrazaca should be moved out of authstore
        if (!this.isObradaDozvolaRole) {
          await obrasciStore.fetchTipoviObrazaca();
        }
        return this.setInitComplete(true);
      }
    }
    this.setInitComplete(true);
  };

  setUserInfo = (userInfo: KeycloakUserInfo) => (this.userInfo = userInfo);
  setInitComplete = (isComplete: boolean) => (this.initComplete = isComplete);
  setUserId = (id: string) => (this.userId = id);
}

export const authStore = new AuthStore();
