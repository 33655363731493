import { Button } from 'components/Button/Button';
import { preseciStore } from 'modules/preseci/preseci.store';
import { Ptp2ProductType } from 'modules/preseci/ptp2Presek/ptp2Presek.types';
import styles from './Ptp2UparivanjeButtons.module.scss';

export const Ptp2UparivanjeButtons = () => {
  return (
    <div className={styles.wrapper}>
      <h4>Uparivanje izveštaja sa podacima carine za:</h4>
      <div className={styles.btnWrapper}>
        <Button
          name="uparivanje-gume-btn"
          onClick={() => preseciStore.automatskoIskljucivanjePtp2(Ptp2ProductType.Guma)}
        >
          Gume
        </Button>
        <Button
          name="uparivanje-gume-btn"
          onClick={() => preseciStore.automatskoIskljucivanjePtp2(Ptp2ProductType.Vozila)}
        >
          Vozila
        </Button>
      </div>
    </div>
  );
};
