import { ColumnType } from 'antd/es/table';
import { IOperaterOtpadaResponse } from './operateri.types';
import {
  InputSifarniciFormProps,
  ISifarniciTypeOfForm,
  SearchScrollSifarniciFormProps,
  SIFARNICI_ROUTES,
} from 'modules/sifarnici/sifarnici.types';

export const operateriListColumns: ColumnType<IOperaterOtpadaResponse>[] = [
  {
    title: 'Naziv preduzeća',
    dataIndex: 'naziv_preduzeca',
    key: 'naziv_preduzeca',
  },
  {
    title: 'PIB preduzeća',
    dataIndex: 'pib_preduzeca',
    key: 'pib_preduzeca',
  },
  {
    title: 'Matični broj preduzeća',
    dataIndex: 'mb_preduzeca',
    key: 'mb_preduzeca',
  },
  {
    title: 'Adresa preduzeća',
    dataIndex: 'adresa_preduzeca',
    key: 'adresa_preduzeca',
  },
  {
    title: 'Opština preduzeća',
    dataIndex: ['opstina_preduzeca', 'display_name'],
    key: 'opstina_preduzeca',
  },
];

const NAZIV_PREDUZECA_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: 'Naziv preduzeća',
  name: 'naziv_preduzeca__ilike',
  inputName: 'naziv_preduzeca__ilike',
};

const PIB_PREDUZECA_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: 'PIB preduzeća',
  name: 'pib_preduzeca__ilike',
  inputName: 'pib_preduzeca__ilike',
  type: 'number',
};

const MATICNI_BROJ_PREDUZECA_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: 'Matični broj preduzeća',
  name: 'maticni_broj_preduzeca__ilike',
  inputName: 'maticni_broj_preduzeca__ilike',
  type: 'number',
};

const ADRESA_PREDUZECA_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: 'Adresa preduzeća',
  name: 'adresa_preduzeca__ilike',
  inputName: 'adresa_preduzeca__ilike',
};

const OPSTINA_PREDUZECA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'opstina_preduzeca_id',
  fetchOptinsList: SIFARNICI_ROUTES.MESTO,
  labelOptionAccessor: ['display_name'],
  valueOptionAccessor: 'id',
  inputName: 'opstina_preduzeca_id',
  label: 'Opština preduzeća',
};

export const OPERATERI_FILTER_OPTIONS: ISifarniciTypeOfForm[] = [
  NAZIV_PREDUZECA_FILTER,
  PIB_PREDUZECA_FILTER,
  MATICNI_BROJ_PREDUZECA_FILTER,
  ADRESA_PREDUZECA_FILTER,
  OPSTINA_PREDUZECA_FILTER,
];
