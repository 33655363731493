import { observer } from 'mobx-react-lite';
import { FC, useEffect } from 'react';
import { PreseciListFilters } from './PreseciListFilters/PreseciListFilters';
import TableData, { ITablePagination } from 'components/TableData/TableData';
import { preseciStore } from 'modules/preseci/preseci.store';
import { Link } from 'react-router-dom';
import { PRESECI_LIST_COLUMN_MAP } from 'modules/preseci/preseci.constants';
import { Content } from 'components/Content/Content';
import { RoutePathEnum } from 'router/router.constants';
import { Button } from 'components/Button/Button';
import { EditOutlined } from '@ant-design/icons';
import { GetPreseciListTypes } from 'modules/preseci/preseci.types';
import { usePresekCheck } from 'hooks/usePresekCheck';
import { PreseciListHeader } from './PreseciListHeader/PreseciListHeader';
// todo: prevod

export const PreseciList: FC = observer(() => {
  const { presekState, handlePresekTypeChange, presekType, updatePresekInfo } = usePresekCheck();

  const columnsByPreseciListType = PRESECI_LIST_COLUMN_MAP[presekType]()
    ? [
        ...PRESECI_LIST_COLUMN_MAP[presekType](),
        {
          title: ``,
          key: 'operation',
          fixed: 'right',
          width: 80,
          align: 'center',
          render: (_: any, record: GetPreseciListTypes) => {
            const getDatumParam = () => {
              if ('datum_slanja' in record) {
                return record.datum_slanja;
              }
              return record.datum_i_vreme_slanja;
            };
            return (
              <Link
                to={{
                  pathname: `${RoutePathEnum.PRESEK}/${presekType}`,
                  search: `?datum_preseka=${getDatumParam()}`,
                }}
              >
                <Button icon={<EditOutlined />} name={`edit-${presekType}-preseci-list`} />
              </Link>
            );
          },
        },
      ]
    : [];

  const fetchPreseciList = (pagination?: ITablePagination) => {
    return preseciStore.fetchPreseciList(presekType, pagination);
  };

  useEffect(() => {
    fetchPreseciList();
  }, [presekType]);

  return (
    <Content
      header={
        <PreseciListHeader
          handlePresekTypeChange={handlePresekTypeChange}
          presekType={presekType}
          presekState={presekState}
          updatePresekInfo={updatePresekInfo}
        />
      }
    >
      <PreseciListFilters presekType={presekType} />
      <TableData
        scrollConfig={['#filtersPreseci', '#preseciHeader']}
        name="preseci"
        columns={columnsByPreseciListType}
        dataSource={preseciStore.preseciList}
        refetchData={fetchPreseciList}
        storePagination={preseciStore.preseciPagination}
      />
    </Content>
  );
});
