import { ColumnType } from 'antd/es/table';
import { IDozvolaResponse, IDozvoleFilters } from './dozvole.types';
import { FormConfig } from 'modules/obrazacIzvestaji/obrazacIzvestajiFilters.constants';
import {
  CheckboxGroupSifarniciFormProps,
  DateSifarniciFormProps,
  InputNumberSifarniciFormProps,
  InputSifarniciFormProps,
  SearchScrollSifarniciFormProps,
  SIFARNICI_ROUTES,
} from 'modules/sifarnici/sifarnici.types';
import { FormInstance } from 'antd';

export const dozvoleListColumns: ColumnType<IDozvolaResponse>[] = [
  {
    title: 'Opština',
    dataIndex: ['sifra_opstine', 'display_name'],
    key: 'opstina_preduzeca',
  },
  {
    title: 'Registraski broj dozvole',
    dataIndex: 'registarski_broj_dozvole',
    key: 'registarski_broj_dozvole',
  },
  {
    title: 'Broj dosijea',
    dataIndex: 'broj_dosijea',
    key: 'broj_dosijea',
  },
  {
    title: 'Važi od',
    dataIndex: 'vazi_od',
    key: 'vazi_od',
  },
  {
    title: 'Važi do',
    dataIndex: 'vazi_do',
    key: 'vazi_do',
  },
  {
    title: 'Izdavalac dozvole',
    dataIndex: ['izdavalac_dozvole', 'name'],
    key: 'izdavalac_dozvole',
  },
  {
    title: 'Verzija',
    dataIndex: 'verzija',
    key: 'verzija',
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
  },
];

const OPSTINA_PREDUZECA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'opstina_preduzeca_id',
  fetchOptinsList: SIFARNICI_ROUTES.MESTO,
  labelOptionAccessor: ['display_name'],
  valueOptionAccessor: 'id',
  inputName: 'opstina_preduzeca_id',
  label: 'Opština preduzeća',
};

const REGISTARSKI_BROJ_DOZVOLE_FILTER: InputNumberSifarniciFormProps = {
  type_of_form: 'input_number',
  name: 'registarski_broj_dozvole',
  filterName: 'registarski_broj_dozvole__ilike',
  // todo: prevod
  label: 'Registarski broj dozvole',
  inputName: 'registarski_broj_dozvole',
};

const BROJ_DOSIJEA_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  name: 'broj_dosijea__ilike',
  label: 'Broj dosijea',
  inputName: 'broj_dosijea__ilike',
};

const DOZVOLA_VAZI_DO_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'datum_vazi_do__lte',
  picker: 'date',
  format: 'DD-MM-YYYY',
  label: 'Datum važi do',
};

const DOZVOLA_VAZI_OD_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'datum_vazi_od__lte',
  picker: 'date',
  format: 'DD-MM-YYYY',
  label: 'Datum važi od',
};

const IZDAVALAC_DOZVOLE_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'izdavalac_dozvole_id',
  fetchOptinsList: SIFARNICI_ROUTES.MESTO,
  labelOptionAccessor: ['display_name'],
  valueOptionAccessor: 'id',
  inputName: 'izdavalac_dozvole_id',
  label: 'Izdavalac dozvole',
};

const VERZIJA_FILTER: InputNumberSifarniciFormProps = {
  type_of_form: 'input_number',
  name: 'verzija',
  filterName: 'verzija',
  // todo: prevod
  label: 'Verzija',
  inputName: 'verzija',
};

const STATUS_DOZVOLE_FILTER = (
  form: FormInstance<{
    status_dozvole_aktivna: boolean;
    status_dozvole_oduzeta: boolean;
    status_dozvole_izmenjena: boolean;
  }>
): CheckboxGroupSifarniciFormProps => {
  const validateCheckboxes = (
    // _event: React.MouseEvent<HTMLElement, MouseEvent>,
    fieldName: 'status_dozvole_aktivna' | 'status_dozvole_oduzeta' | 'status_dozvole_izmenjena'
  ) => {
    const fieldsArray = ['status_dozvole_aktivna', 'status_dozvole_oduzeta', 'status_dozvole_izmenjena'].filter(
      field => field !== fieldName
    );
    const clickedFieldValue = form.getFieldValue(fieldName);
    const isAnyOtherBoxSelected = Object.values(form.getFieldsValue(fieldsArray)).every(s => !s);

    if (!clickedFieldValue && isAnyOtherBoxSelected) {
      form.setFieldsValue({ [fieldName]: true });
    }
  };

  return {
    filterColSpan: 8,
    type_of_form: 'checkbox-group',
    groupName: 'Status dozvole',
    checkboxConfig: [
      {
        label: 'Aktivna',
        name: 'status_dozvole_aktivna',
        onCheckBoxChange: () => validateCheckboxes('status_dozvole_aktivna'),
      },
      {
        label: 'Oduzeta',
        name: 'status_dozvole_oduzeta',
        onCheckBoxChange: () => validateCheckboxes('status_dozvole_oduzeta'),
      },
      {
        label: 'Izmenjena',
        name: 'status_dozvole_izmenjena',
        onCheckBoxChange: () => validateCheckboxes('status_dozvole_izmenjena'),
      },
    ],
  };
};

export const DOZVOLE_FILTER_OPTIONS: FormConfig = form => [
  OPSTINA_PREDUZECA_FILTER,
  REGISTARSKI_BROJ_DOZVOLE_FILTER,
  BROJ_DOSIJEA_FILTER,
  DOZVOLA_VAZI_DO_FILTER,
  DOZVOLA_VAZI_OD_FILTER,
  IZDAVALAC_DOZVOLE_FILTER,
  VERZIJA_FILTER,
  STATUS_DOZVOLE_FILTER(form),
];

export const DOZVOLE_FILTERS_INITIAL_STATE: Partial<IDozvoleFilters> = {
  status_dozvole_aktivna: true,
};
