import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';
import { IOperaterOtpadaRequest, IOperaterOtpadaResponse } from './operateri.types';

const OPERATER_BASE_ROUTE = `${ROUTES.REGISTAR_DOZVOLA}-operater/`;

const fetchOperateriList = (params: any): IPaginatedApiResponse<IOperaterOtpadaResponse> => {
  const query = queryString.stringify(params);
  return axios.get(`${OPERATER_BASE_ROUTE}?${query}`);
};

const createOperater = (payload: IOperaterOtpadaRequest): ApiResponse<{ id: string }> => {
  return axios.post(OPERATER_BASE_ROUTE, payload);
};

const updateOperaterInfo = (id: string, payload: Partial<IOperaterOtpadaResponse>) => {
  return axios.put(`${OPERATER_BASE_ROUTE}${id}`, payload);
};

const fetchOperaterById = (id: string): ApiResponse<IOperaterOtpadaResponse> => {
  return axios.get(`${OPERATER_BASE_ROUTE}${id}`);
};

const deleteOperater = (id: string) => {
  return axios.delete(`${OPERATER_BASE_ROUTE}${id}`);
};

export const operateriRepo = {
  fetchOperateriList,
  createOperater,
  updateOperaterInfo,
  fetchOperaterById,
  deleteOperater,
};
