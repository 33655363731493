import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { Col } from 'antd';
import { OpsteGodinaPreduzecePostrojenje } from 'pages/ObrazacWizard/components/components/OpsteGodinaPreduzecePostrojenje/OpsteGodinaPreduzecePostrojenje';
import { useTranslation } from 'react-i18next';

import styles from './GveOpsteWizardTab.module.scss';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { gveStore } from 'modules/obrasci/store/gve/gve.store';
import { ISifarnikInitial, SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { IGetGve } from 'modules/obrasci/store/gve/gve.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { useObrazacExistsCheck } from 'hooks/useObrazacExistsCheck';
import { IGetAir } from 'modules/sourcesOfPollution/air/air.types';

export const GveOpsteWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();

  const checkIfObrazacExists = useObrazacExistsCheck();

  const defaultIzvorZagadjenjaVazduha: ISifarnikInitial | undefined = gveStore.initialState.izvor_zagadjenja_vazduha_id
    ? {
        label: `${(gveStore.initialState as IGetGve).izvor_zagadjenja_vazduha?.naziv_izvora} ${
          (gveStore.initialState as IGetGve).izvor_zagadjenja_vazduha?.broj_izvora
        }`,
        value: (gveStore.getInitialState as IGetGve).izvor_zagadjenja_vazduha_id,
      }
    : undefined;

  const izvorZagadjenjaVazduhaOptionsMaker = (options: IGetAir[]) => {
    return options.map(option => ({
      label: `${option?.naziv_izvora} ${option?.broj_izvora}`,
      value: option.id,
      item: option,
    }));
  };

  useEffect(() => {
    if (
      obrasciStore.opste.godina &&
      obrasciStore.opste.preduzece_id !== '' &&
      obrasciStore.opste.postrojenje_id !== '' &&
      gveStore.initialState.izvor_zagadjenja_vazduha_id !== ''
    ) {
      obrasciStore.setDisabledTabButton(false);
    }
  }, [obrasciStore.opste, gveStore.initialState.izvor_zagadjenja_vazduha_id]);

  const handleIzvorSelect = (id: string) => {
    const izvorObject = sifarniciStore.list[SIFARNICI_ROUTES.IZVOR_ZAGADJENJA_VAZDUHA].find(item => item.id === id);
    gveStore.handleChange('izvorZagadjenjaObject', izvorObject);
    gveStore.handleInitialStateChange('izvor_zagadjenja_vazduha_id', id);

    if (
      Number(obrasciStore.opste.godina) > 2020 &&
      (izvorObject.obaveza_merenja?.toLowerCase() === 'da' || izvorObject.obaveza_merenja?.toLowerCase() === 'yes')
    ) {
      gveStore.handleChange('showEmisijeUVazduh', true);
    } else {
      gveStore.handleChange('showEmisijeUVazduh', false);
    }
  };

  const sortOptionsByBrojIzvora = (options: { label: string; value: string; item: IGetAir }[]) => {
    const groupByIzvor = options.reduce<Record<string, { label: string; value: string; item: IGetAir }[]>>(
      (acc, item) => {
        const { naziv_izvora } = item.item;
        if (!acc[naziv_izvora]) {
          acc[naziv_izvora] = [];
        }
        acc[naziv_izvora].push(item);
        return acc;
      },
      {}
    );

    return Object.values(groupByIzvor)
      .map(group => group.sort((a, b) => Number(a.item.broj_izvora) - Number(b.item.broj_izvora)))
      .flat();
  };

  return (
    <Col span={12} className={styles.wrapper}>
      <OpsteGodinaPreduzecePostrojenje withPostrojenje={true} store={gveStore} />
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'izvor_zagadjenja_vazduha_id'}
        label={t('OBRASCI.GVE.IZVOR_ZAGADJIVANJA')}
        labelCol={{ span: 9 }}
        wrapperCol={{ span: 15 }}
        placeholder=""
        rules={[REQUIRED_FIELD_RULE(true)]}
        fetchOptinsList={gveStore.fetchIzvorZagadjenjaVazduha}
        labelOptionAccessor="naziv_izvora"
        valueOptionAccessor="id"
        disabledAccessor="aktivan"
        readOnly={false}
        onChange={async value => {
          const obrazacExists = await checkIfObrazacExists({ izvor_zagadjenja_vazduha_id: value });
          if (!obrazacExists) handleIzvorSelect(value);
        }}
        optionsMaker={izvorZagadjenjaVazduhaOptionsMaker}
        defaultOption={defaultIzvorZagadjenjaVazduha}
        sortOptions={sortOptionsByBrojIzvora}
        disabled={
          obrasciStore.opste.godina === '' ||
          obrasciStore.opste.postrojenje_id === '' ||
          gveStore.initialState.izvor_zagadjenja_vazduha_id !== '' ||
          !obrasciStore.disabled_tab_button
        }
      />
    </Col>
  );
});
