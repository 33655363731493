import { IDozvoleZaOtpadTranslations } from 'translations/translation.types';

export const dozvoleZaOtpadTranslationsSrpski: IDozvoleZaOtpadTranslations = {
  TITLE: 'Dozvole za upravljanje otpadom',
  OVERVIEW: 'Pregled dozvola',
  DATA_PROCESSING: 'Obrada podataka',
  OPERATERI: {
    TITLE: 'Operateri',
  },
  DOZVOLE: {
    TITLE: 'Dozvole',
    VRSTA_DOZVOLE: {
      SAKUPLJANJE: 'Sakupljanje',
      SKLADISTENJE: 'Skladištenje',
      TRANSPORT: 'Transport',
      TRETMAN_ODLAGANJE: 'Odlaganje',
      TRETMAN_PONOVNO_ISKORISCENJE: 'Ponovno iskorišćenje',
    },
  },
};
