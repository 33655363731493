import i18n from 'translations/i18n.config';
import {
  IFiltersPtp2PreseciList,
  IFiltersPtp2SinglePresek,
  IGetPtp2PreseciList,
  IGetPtp2SinglePresek,
  Ptp2RazlogIskljucenja,
} from './ptp2Presek.types';
import { IPreseciColumnProps } from '../preseci.types';
import { FormConfig } from 'modules/obrazacIzvestaji/obrazacIzvestajiFilters.constants';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import {
  BROJ_PREDUZECA_COLUMN,
  BROJ_ZAPISA_COLUMN,
  DATUM_DOPISA_COLUMN,
  DATUM_I_VREME_PRESEKA_COLUMN,
  DATUM_I_VREME_SLANJA_COLUMN,
  GODINA_COLUMN,
  MATICNI_BROJ_COLUMN,
  NAZIV_PREDUZECA_COLUMN,
  PIB_COLUMN,
  STATUS_COLUMN,
  ZAVODNI_BROJ_DOPISA_COLUMN,
} from '../preseciColumns.constants';
import {
  DATUM_I_VREME_SLANJA_GTE_FILTER,
  DATUM_I_VREME_SLANJA_LTE_FILTER,
  GODINA_FILTER,
  MATICNI_BROJ_FILTER,
  NAZIV_PREDUZECE_FILTER,
  PIB_FILTER,
} from '../preseciFilters.constants';
import { preseciStore } from '../preseci.store';

const VRSTA_PROIZVODA_ILIKE_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: i18n.t('PRESECI.VRSTA_PROIZVODA'),
  name: 'vrsta_proizvoda__ilike',
  inputName: 'vrsta_proizvoda__ilike',
};

//#region LISTA P
export const PRESECI_PTP_2_COLUMNS: IPreseciColumnProps<IGetPtp2PreseciList>[] = [
  DATUM_I_VREME_SLANJA_COLUMN as IPreseciColumnProps<IGetPtp2PreseciList>,
  BROJ_ZAPISA_COLUMN as IPreseciColumnProps<IGetPtp2PreseciList>,
  BROJ_PREDUZECA_COLUMN as IPreseciColumnProps<IGetPtp2PreseciList>,
  {
    width: 200,
    dataIndex: 'ukupna_kolicina_nova',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_NOVE'),
  },
  {
    width: 200,
    dataIndex: 'ukupan_pdv_nova',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_NOVE'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_kolicina_korigovana',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_KORIGOVANE'),
  },
  {
    width: 200,
    dataIndex: 'ukupan_pdv_korigovana',
    title: i18n.t('PRESECI.UKUPAN_PDV_KORIGOVANE'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_kolicina_brisana',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_BRISANE'),
  },
  {
    width: 200,
    dataIndex: 'ukupan_pdv_brisana',
    title: i18n.t('PRESECI.UKUPAN_PDV_BRISANE'),
  },
  ZAVODNI_BROJ_DOPISA_COLUMN,
  DATUM_DOPISA_COLUMN,
  DATUM_I_VREME_PRESEKA_COLUMN,
];

export const PTP2_PRESECI_LIST_FILTERS_INITIAL_STATE: IFiltersPtp2PreseciList = {
  datum_slanja__gte: '',
  datum_slanja__lte: '',
  naziv_preduzeca__ilike: '',
  vrsta_proizvoda__ilike: '',
};

export const PTP2_PRESECI_LIST_FILTERS_OPTIONS: FormConfig = (form): ISifarniciTypeOfForm[] => [
  DATUM_I_VREME_SLANJA_GTE_FILTER,
  DATUM_I_VREME_SLANJA_LTE_FILTER,
  NAZIV_PREDUZECE_FILTER(form),
  VRSTA_PROIZVODA_ILIKE_FILTER,
];

//#endregion LISTA P

//#region PRESEK
export const SINGLE_PRESEK_PTP_2_COLUMNS: IPreseciColumnProps<IGetPtp2SinglePresek>[] = [
  GODINA_COLUMN as IPreseciColumnProps<IGetPtp2SinglePresek>,
  PIB_COLUMN as IPreseciColumnProps<IGetPtp2SinglePresek>,
  MATICNI_BROJ_COLUMN as IPreseciColumnProps<IGetPtp2SinglePresek>,
  NAZIV_PREDUZECA_COLUMN as IPreseciColumnProps<IGetPtp2SinglePresek>,
  {
    title: `${i18n.t('PRESECI.VRSTA_PROIZVODA')}`,
    dataIndex: 'vrsta_proizvoda',
    key: 'vrsta_proizvoda',
    width: '200px',
  },
  {
    title: `${i18n.t('PRESECI.PROIZVOD')}`,
    dataIndex: 'proizvod',
    key: 'proizvod',
    width: '200px',
  },
  {
    title: `${i18n.t('PRESECI.KOLICINA')}`,
    dataIndex: 'kolicina',
    key: 'kolicina',
    width: '200px',
  },
  {
    title: `${i18n.t('PRESECI.JEDINICA_MERE')}`,
    dataIndex: 'jedinica_mere',
    key: 'jedinica_mere',
    width: '200px',
  },
  {
    title: `${i18n.t('PRESECI.PDV')}`,
    dataIndex: 'pdv',
    key: 'pdv',
    width: '200px',
  },
  STATUS_COLUMN as IPreseciColumnProps<IGetPtp2SinglePresek>,
];

export const PTP2_SINGLE_PRESEK_FILTER_INITIAL_STATE: IFiltersPtp2SinglePresek = {
  maticni_broj__ilike: '',
  godina: '',
  naziv_preduzeca__ilike: '',
  iskljucen: null,
  pib__ilike: '',
};

export const PTP2_SINGLE_PRESEK_FILTERS_OPTIONS: FormConfig = (): ISifarniciTypeOfForm[] => [
  GODINA_FILTER,
  PIB_FILTER({ ilike: true }),
  MATICNI_BROJ_FILTER({ ilike: true }),
  NAZIV_PREDUZECE_FILTER(),
  VRSTA_PROIZVODA_ILIKE_FILTER,
];

export const RAZLOG_ISKLJUCENJA_OPTIONS: { label: string; value: Ptp2RazlogIskljucenja }[] = [
  {
    value: Ptp2RazlogIskljucenja.Zakljucak,
    label: 'Korekcija nije u skladu sa zaključkom po žalbi',
  },
  {
    value: Ptp2RazlogIskljucenja.DrugiZahtev,
    label: 'Korekcija nije u skladu sa drugim zahtevom',
  },
  { value: Ptp2RazlogIskljucenja.PogresnaKolicina, label: 'Pogrešno uneta količina' },
  {
    value: Ptp2RazlogIskljucenja.CekaPotvrdu,
    label: 'Čeka se potvrda ispravnosti unetih proizvoda',
  },
  ...(preseciStore.ptp2PrijavaZaIskljucivanje?.razlog_iskljucenja === Ptp2RazlogIskljucenja.AutomatskoIskljucivanje
    ? [
        {
          value: Ptp2RazlogIskljucenja.AutomatskoIskljucivanje,
          label: 'Automatsko isključivanje zbog netačnih podataka',
        },
      ]
    : []),
];
//#endregion PRESEK
