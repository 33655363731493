import { Tabs } from 'antd';
import { observer } from 'mobx-react-lite';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';

import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IGetCompany } from 'modules/company/company.types';
import { IGetFacility } from 'modules/facility/facility.types';
import styles from './ObrasciCompanyUserFilters.module.scss';
import storage, { StorageEnum } from 'store/storage';
import { companyStore } from 'modules/company/company.store';
import { facilityStore } from 'modules/facility/facility.store';
import { authStore } from 'store';

import { CustomOptionType, InfiniteScroll } from 'components/InfiniteScroll/InfiniteScroll';

export const ObrasciCompanyUserFilters: React.FC = observer(() => {
  enum ActiveObrasciTabEnum {
    Preduzece = '1',
    Postrojenje = '2',
  }
  const [activeTab, setActiveTab] = useState<ActiveObrasciTabEnum>(ActiveObrasciTabEnum.Preduzece);

  const handleUseEffect = async () => {
    const { postrojenje_id } = obrasciStore.opste;

    // if (!preduzece_id)
    storage.setData(StorageEnum.PREDUZECE, companyStore.company);

    obrasciStore.handleChange('opste', {
      ...obrasciStore.opste,
      preduzece: companyStore.company,
      preduzece_id: (companyStore.company as IGetCompany).id,
    });

    if (postrojenje_id) {
      setActiveTab(ActiveObrasciTabEnum.Postrojenje);
      obrasciStore.fetchTipoviObrazacaPoPostrojenju();
    } else if (authStore.isOnlyFacilityUser) {
      setActiveTab(ActiveObrasciTabEnum.Postrojenje);
      const initialFacility = await facilityStore.fetchFacilityListByUser({ page: 1, size: 10 });
      if (initialFacility?.items?.[0]) {
        obrasciStore.handleChange('opste', {
          ...obrasciStore.opste,
          postrojenje: initialFacility.items[0],
          postrojenje_id: initialFacility.items[0].id,
        });
        storage.setData(StorageEnum.POSTROJENJE, initialFacility.items[0]);
        obrasciStore.fetchTipoviObrazacaPoPostrojenju();
      } else {
        obrasciStore.setTipoviObrazaca([]);
        obrasciStore.resetTipObrasca();
      }
    } else {
      setActiveTab(ActiveObrasciTabEnum.Preduzece);
      obrasciStore.fetchTipoviObrazacaPoPreduzecu();
    }
  };

  const defaultFacility = obrasciStore.opste.postrojenje as IGetFacility;

  const defaultPostrojenjeOption: CustomOptionType<IGetFacility> | undefined = defaultFacility
    ? {
        label: defaultFacility.naziv_postrojenja,
        value: defaultFacility.id,
        item: defaultFacility,
      }
    : undefined;

  const { t } = useTranslation();

  const handlePostrojenjeChange = async (facility: IGetFacility) => {
    obrasciStore.resetTipObrasca();
    obrasciStore.setObrasciList([]);
    storage.setData(StorageEnum.POSTROJENJE, facility);
    obrasciStore.handleChange('opste', {
      ...obrasciStore.opste,
      postrojenje_id: facility.id,
      postrojenje: facility,
    });
    await obrasciStore.fetchTipoviObrazacaPoPostrojenju();
    filtersStore.setFilters(FiltersNameEnum.OBRASCI, {});
  };

  useEffect(() => {
    handleUseEffect();
  }, []);

  const onTabChange = (tab: string) => {
    obrasciStore.setObrasciList([]);
    filtersStore.setFilters(FiltersNameEnum.OBRASCI, {});
    obrasciStore.setTipoviObrazaca([]);
    obrasciStore.resetTipObrasca();
    if (tab === ActiveObrasciTabEnum.Preduzece) {
      setActiveTab(ActiveObrasciTabEnum.Preduzece);
      obrasciStore.fetchTipoviObrazacaPoPreduzecu();
      storage.removeData(StorageEnum.POSTROJENJE);
      obrasciStore.handleChange('opste', {
        ...obrasciStore.opste,
        postrojenje_id: '',
        postrojenje: '',
      });
    }

    if (tab === ActiveObrasciTabEnum.Postrojenje) {
      setActiveTab(ActiveObrasciTabEnum.Postrojenje);
      if (facilityStore.facilityList.length) {
        obrasciStore.handleChange('opste', {
          ...obrasciStore.opste,
          postrojenje_id: (facilityStore.facilityList[0] as IGetFacility).id,
          postrojenje: facilityStore.facilityList[0],
        });
        storage.setData(StorageEnum.POSTROJENJE, obrasciStore.opste.postrojenje);
        obrasciStore.fetchTipoviObrazacaPoPostrojenju();
      }
    }
  };

  const tabsUserCompanyFacility = [
    {
      key: ActiveObrasciTabEnum.Preduzece,
      label: t('COMPANY.TITLE'),
      disabled: authStore.isOnlyFacilityUser,
    },
    {
      key: ActiveObrasciTabEnum.Postrojenje,
      label: t('FACILITY.TITLE'),
      disabled: authStore.isOnlyCompanyUser,
    },
  ];

  return (
    <div id="obrasciUserFilters" className={styles.wrapper}>
      <Tabs
        className={styles.tabs}
        activeKey={activeTab}
        onChange={onTabChange}
        type="card"
        items={tabsUserCompanyFacility}
        tabBarExtraContent={
          activeTab === ActiveObrasciTabEnum.Postrojenje && (
            <InfiniteScroll<IGetFacility>
              placeholder={t('FACILITY.PLACEHOLDER.CHOOSE_FROM_LIST')}
              labelOptionAccessor="naziv_postrojenja"
              valueOptionAccessor="id"
              value={obrasciStore.opste.postrojenje_id}
              disabledAccessor="is_active"
              fetchOptionsList={facilityStore.fetchFacilityListByUser}
              //@ts-ignore
              handleChange={(_, option) => handlePostrojenjeChange(option.item)}
              showSearch
              className={styles.postrojenjeSelect}
              name="postrojenje"
              defaultOption={defaultPostrojenjeOption}
              fetchBeforeFocusing={!authStore.isOnlyCompanyUser}
            />
          )
        }
      />
    </div>
  );
});
