import { IPaginatedApiResponse } from 'typescript/NrizTypes';
import {
  IEvropskiIzvestajFilters,
  EvropskiIzvestajiAllReports,
  EvropskiIzvestajTypeEnum,
  CheckForNewIntersection,
  BaseIntersectionResponse,
  EvropskiIzvestajiAllFilters,
} from './evropskiIzvestaji.types';
import { nrizService } from '../nriz/nriz.service';
import { evropskiIzvestajiRepo } from './evropskiIzvestaji.repo';
import { ITablePagination } from 'components/TableData/TableData';

/**
 * Evropski Izvestaji service
 */
class EvropskiIzvestajiService {
  /**
   * Method that prepares payload and calls method from repo for new data
   * @param { IEvropskiIzvestajFilters } payload
   * @param { ITablePagination } pagination
   * @return { IPaginatedApiResponse<EvropskiIzvestajiAllReports> }
   */
  public getEvropskiIzvestajByType = (
    payload: IEvropskiIzvestajFilters,
    pagination: ITablePagination
  ): IPaginatedApiResponse<EvropskiIzvestajiAllReports> => {
    const validPayload = { ...payload, filter_params: payload.filter_params };
    return evropskiIzvestajiRepo.getEvropskiIzvestajByType(validPayload, pagination);
  };

  /**
   * Method that calls method from repo to check if admin can create a new intersection
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   * @return { Promise<CheckForNewIntersection> }
   */
  public checkVisibilityOfIntersection = (
    izvestajType: EvropskiIzvestajTypeEnum,
    godina: number
  ): Promise<CheckForNewIntersection> => evropskiIzvestajiRepo.checkVisibilityOfIntersection(izvestajType, godina);

  /**
   * Method that calls method from repo to create a new intersection
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   * @return { Promise<BaseIntersectionResponse> }
   */
  public createIntersection = (
    izvestajType: EvropskiIzvestajTypeEnum,
    godina: number
  ): Promise<BaseIntersectionResponse> => evropskiIzvestajiRepo.createIntersection(izvestajType, godina);

  /**
   * Method that calls method from repo to create a new intersection
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   * @param { string } id
   * @param { string } status
   * @return { Promise<void> }
   */
  public changeStatus = (
    izvestajType: EvropskiIzvestajTypeEnum,
    godina: number,
    id: string,
    status: string
  ): Promise<void> => evropskiIzvestajiRepo.changeStatus(izvestajType, godina, id, status);

  /**
   * TODO
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   */
  public cancelSubtract = (izvestajType: EvropskiIzvestajTypeEnum, godina: number) =>
    evropskiIzvestajiRepo.cancelIntersection(izvestajType, godina);

  /**
   * Method that parses filters
   * @param { EvropskiIzvestajiAllFilters } payload
   * @return { EvropskiIzvestajiAllFilters }
   */
  public parseFilters = (payload: EvropskiIzvestajiAllFilters): EvropskiIzvestajiAllFilters => {
    return this.modifyFilterProperties(nrizService.pickFields(nrizService.removeObjFromPayload(payload)));
  };

  /**
   *Method that modifies filter properties
   * @param { EvropskiIzvestajiAllFilters } filterParams
   * @return { EvropskiIzvestajiAllFilters }
   */
  public modifyFilterProperties = (filterParams: EvropskiIzvestajiAllFilters): EvropskiIzvestajiAllFilters => {
    const newFilterParams: EvropskiIzvestajiAllFilters = {} as EvropskiIzvestajiAllFilters;
    Object.keys(filterParams).forEach((key: string) => {
      const newKey = key.replace('_id', '') as keyof EvropskiIzvestajiAllFilters;
      // @ts-ignore
      newFilterParams[newKey] = filterParams[key];
    });
    return newFilterParams;
  };

  /**
   * Method that generate excel file
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   * @param { number } godina
   * @return { Promise<Blob> }
   */
  public generateExcel = (izvestajType: EvropskiIzvestajTypeEnum, godina: number): Promise<Blob> =>
    evropskiIzvestajiRepo.generateExcel(izvestajType, godina);
}

/**
 * Evropski izvestaji service constant as instance of EvropskiIzvestajiService class
 * */
export const evropskiIzvestajiService: EvropskiIzvestajiService = new EvropskiIzvestajiService();
