import to from 'await-to-js';
import { makeAutoObservable } from 'mobx';
import { preseciService } from './preseci.service';
import { IPagination } from 'typescript';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { PreseciTypeEnum, SINGLE_PRESEK_LIST_ORDER_BY, VRSTA_IZVESTAJA_SINGLE_PRESEK_TYPE } from './preseci.constants';
import { ITablePagination } from 'components/TableData/TableData';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { nrizService } from 'modules/nriz/nriz.service';
import { IEmptyResponse, IPaginatedResponse, IPostResponse } from 'typescript/NrizTypes';
import dayjs from 'dayjs';
import { saveBlob } from 'utils/fileDownload';
import {
  GetPreseciListTypes,
  GetSinglePresekTypes,
  ICreatePresekPayload,
  IPosaljiPresekForm,
  IPosaljiPtp2PresekForm,
  IUkljuciIskljuciPreseciPoFilterima,
} from './preseci.types';
import { modalStore } from 'store';
import { handleErrorNotify } from 'utils/errorHandling';
import { IGetPtp2SinglePresek, Ptp2ProductType, Ptp2RazlogIskljucenja } from './ptp2Presek/ptp2Presek.types';

class PreseciStore {
  constructor() {
    makeAutoObservable(this);
  }

  ptp2PrijavaZaIskljucivanje: IGetPtp2SinglePresek | undefined = undefined;

  preseciList: GetPreseciListTypes[] = [];
  preseciPagination: IPagination = paginationInitialState;

  singlePresekList: GetSinglePresekTypes[] = [];
  singlePresekPagination: IPagination = paginationInitialState;

  vrstaIzvestaja = '1';

  modalInitialState: IUkljuciIskljuciPreseciPoFilterima = {};

  handleChange<K extends keyof PreseciStore>(key: K, value: this[K]): void {
    this[key] = value;
  }

  fetchPreseciList = async (preseciType: PreseciTypeEnum, pagination?: ITablePagination) => {
    const [err, res] = await to<IPaginatedResponse<GetPreseciListTypes>>(
      preseciService.fetchPreseciList(preseciType, {
        page: pagination?.page || this.preseciPagination.page,
        size: pagination?.size || this.preseciPagination.size,
        ...nrizService.removeObjFromPayload(
          preseciService.modifyFiltersPreseciListPayload({
            ...filtersStore.getFilters[FiltersNameEnum.LISTA_PRESEKA],
          })
        ),
      })
    );
    if (err) return Promise.reject();
    const { items, page, size, pages, total } = res;
    this.handleChange('preseciList', items);
    this.handleChange('preseciPagination', { page, size, total, pages });
    return res;
  };

  fetchSinglePresekList = async (
    preseciType: PreseciTypeEnum,
    pagination?: ITablePagination
  ): Promise<IPaginatedResponse<GetSinglePresekTypes>> => {
    const [err, res] = await to<IPaginatedResponse<GetSinglePresekTypes>>(
      preseciService.fetchSinglePresekList<GetSinglePresekTypes>(
        preseciType,
        nrizService.pickFields({
          page: pagination?.page || this.preseciPagination.page,
          size: pagination?.size || this.preseciPagination.size,
          order_by: SINGLE_PRESEK_LIST_ORDER_BY[preseciType],
          vrsta_izvestaja: VRSTA_IZVESTAJA_SINGLE_PRESEK_TYPE.includes(preseciType) ? this.vrstaIzvestaja : null,
          ...nrizService.removeObjFromPayload(
            preseciService.modifyFiltersSinglePresekPayload({
              ...filtersStore.getFilters[FiltersNameEnum.SINGLE_PRESEK],
            })
          ),
        })
      )
    );
    if (err || !res) return Promise.reject();
    const { items, page, size, pages, total } = res;
    this.handleChange('singlePresekList', items);
    this.handleChange('singlePresekPagination', { page, size, total, pages });
    return res;
  };

  checkIfSinglePresekListIsValidForSending = async (preseciType: PreseciTypeEnum) => {
    const [err, res] = await to<IPaginatedResponse<GetSinglePresekTypes>>(
      preseciService.fetchSinglePresekList<GetSinglePresekTypes>(
        preseciType,
        nrizService.pickFields({
          page: 1,
          size: 1,
          iskljucen: false,
          automatski_iskljucen: preseciType === PreseciTypeEnum.OTV ? false : null,
        })
      )
    );
    if (err || !res) return Promise.reject();
    if (res.items.length)
      return preseciType === PreseciTypeEnum.PTP2
        ? modalStore.changeModalName('posalji-ptp2-izvestaj-modal')
        : modalStore.changeModalName('posalji-izvestaj-modal');
    return handleErrorNotify(['Ne postoji ni jedan uključen red u preseku']);
  };

  fetchExistingSinglePresekList = async (
    preseciType: PreseciTypeEnum,
    datumPreseka: string,
    pagination?: ITablePagination
  ) => {
    const [err, res] = await to<IPaginatedResponse<GetSinglePresekTypes>>(
      preseciService.fetchExistingSinglePresekList<GetSinglePresekTypes>(
        preseciType,
        datumPreseka,
        nrizService.pickFields({
          page: pagination?.page || this.preseciPagination.page,
          size: pagination?.size || this.preseciPagination.size,
          order_by: SINGLE_PRESEK_LIST_ORDER_BY[preseciType],
          ...nrizService.removeObjFromPayload(
            preseciService.modifyFiltersSinglePresekPayload(filtersStore.getFilters[FiltersNameEnum.SINGLE_PRESEK])
          ),
        })
      )
    );
    if (err) return Promise.reject();
    const { items, page, size, pages, total } = res;

    this.handleChange('singlePresekList', items);
    this.handleChange('singlePresekPagination', { page, size, total, pages });
  };

  createSinglePresek = async (preseciType: PreseciTypeEnum, payload?: ICreatePresekPayload): Promise<IPostResponse> => {
    const [err, res] = await to(preseciService.createSinglePresek(preseciType, payload));
    if (err) return Promise.reject();
    return res;
  };

  posaljiSinglePresek = async (
    preseciType: PreseciTypeEnum,
    payload: IPosaljiPresekForm | IPosaljiPtp2PresekForm
  ): Promise<IEmptyResponse> => {
    const [err, res] = await to<IEmptyResponse>(preseciService.posaljiSinglePresek(preseciType, payload));
    if (err) return Promise.reject();
    return res;
  };

  iskljuciStavkuPresekaPoId = async (
    preseciType: PreseciTypeEnum,
    payload: GetSinglePresekTypes
  ): Promise<IEmptyResponse> => {
    const [err, res] = await to<IEmptyResponse>(preseciService.iskljuciStavkuPresekaPoId(preseciType, payload.id));
    if (err || !res) return Promise.reject();
    this.handleChange(
      'singlePresekList',
      preseciService.automatskoIskljucivanjeUkljucivanjeStavki(preseciType, this.singlePresekList, payload, true)
    );
  };

  iskljuciStavkePoFilterima = async (
    preseciType: PreseciTypeEnum,
    filters: IUkljuciIskljuciPreseciPoFilterima
  ): Promise<IEmptyResponse> => {
    const filtersForIskljucivanje = nrizService.pickFields(
      nrizService.removeObjFromPayload(preseciService.modifyFiltersSinglePresekPayload(filters))
    );

    const [err, res] = await to<IEmptyResponse>(
      preseciService.iskljuciStavkePoFilterima(preseciType, filtersForIskljucivanje)
    );

    if (err || !res) return Promise.reject();
    this.handleChange(
      'singlePresekList',
      preseciService.automatskoIskljucivanjeUkljucivanjePoFilterima(
        preseciType,
        this.singlePresekList,
        filtersForIskljucivanje,
        true
      )
    );
  };

  ukljuciStavkuPresekaPoId = async (
    preseciType: PreseciTypeEnum,
    payload: GetSinglePresekTypes
  ): Promise<IEmptyResponse> => {
    const [err, res] = await to<IEmptyResponse>(preseciService.ukljuciStavkuPresekaPoId(preseciType, payload.id));
    if (err || !res) return Promise.reject();
    this.handleChange(
      'singlePresekList',
      preseciService.automatskoIskljucivanjeUkljucivanjeStavki(preseciType, this.singlePresekList, payload)
    );
  };

  ukljuciStavkePoFilterima = async (
    preseciType: PreseciTypeEnum,
    payload: IUkljuciIskljuciPreseciPoFilterima
  ): Promise<IEmptyResponse> => {
    const filters = nrizService.pickFields(
      nrizService.removeObjFromPayload(preseciService.modifyFiltersSinglePresekPayload(payload))
    );
    const [err, res] = await to<IEmptyResponse>(preseciService.ukljuciStavkePoFilterima(preseciType, filters));
    if (err || !res) return Promise.reject();
    this.handleChange(
      'singlePresekList',
      preseciService.automatskoIskljucivanjeUkljucivanjePoFilterima(preseciType, this.singlePresekList, filters)
    );
  };

  iskljucivanjePtp2Prijave = async (razlog_iskljucenja: Ptp2RazlogIskljucenja) => {
    if (!this.ptp2PrijavaZaIskljucivanje) return Promise.reject('Nema prijave za isključivanje!');
    const prijava = { ...this.ptp2PrijavaZaIskljucivanje, razlog_iskljucenja };
    const [err, res] = await to(preseciService.iskljucivanjePtp2Prijave(prijava?.id, razlog_iskljucenja));
    if (err || !res) return Promise.reject(err);

    this.handleChange(
      'singlePresekList',
      preseciService.automatskoIskljucivanjeUkljucivanjeStavki(PreseciTypeEnum.PTP2, this.singlePresekList, prijava)
    );
  };

  deleteSinglePresek = async (preseciType: PreseciTypeEnum): Promise<IEmptyResponse> => {
    const [err, res] = await to<IEmptyResponse>(preseciService.deleteSinglePresek(preseciType));
    if (err) return Promise.reject();
    return res;
  };

  exportExcelPreseciList = async (preseciType: PreseciTypeEnum) => {
    const [err, res] = await to<Blob>(
      preseciService.exportPreseciListExcel(
        preseciType,
        nrizService.removeObjFromPayload(nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.LISTA_PRESEKA]))
      )
    );
    if (err || !res) return;
    const downloadName = `${'Lista-preseka'}_${preseciType}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, downloadName);
  };

  exportSinglePresekExcel = async (preseciType: PreseciTypeEnum) => {
    const [err, res] = await to<Blob>(
      preseciService.exportSinglePresekExcel(
        preseciType,
        nrizService.removeObjFromPayload(nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.SINGLE_PRESEK]))
      )
    );

    if (err || !res) return;
    const downloadName = `${'Presek'}_${preseciType}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, downloadName);
  };

  exportExistingSinglePresekListExcel = async (preseciType: PreseciTypeEnum, datumPreseka: string) => {
    const [err, res] = await to<Blob>(
      preseciService.exportExistingSinglePresekListExcel(
        preseciType,
        datumPreseka,
        nrizService.removeObjFromPayload(nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.SINGLE_PRESEK]))
      )
    );

    if (err || !res) return;
    const downloadName = `${'Presek'}_${preseciType}_od_dana_${datumPreseka}_${dayjs().format('DD-MM-YYYY')}`;
    saveBlob(res, downloadName);
  };

  checkIfYearIncluded = async (presekType: PreseciTypeEnum) => {
    const [err, res] = await to(preseciService.checkIfYearIncluded(presekType));

    if (err || !res) return false;

    return res.ukljucena_aktuelna_izvestajna_godina;
  };

  clearPreseci = () => {
    this.handleChange('preseciList', []);
    this.handleChange('preseciPagination', paginationInitialState);
    this.handleChange('singlePresekList', []);
    this.handleChange('singlePresekPagination', paginationInitialState);
    this.handleChange('ptp2PrijavaZaIskljucivanje', undefined);
  };

  automatskoIskljucivanjePtp2 = async (product: Ptp2ProductType) => {
    const [err, res] = await to(preseciService.automatskoIskljucivanjePtp2(product));
    if (err || !res) return Promise.reject(err);
    this.fetchSinglePresekList(PreseciTypeEnum.PTP2);
  };
}

export const preseciStore = new PreseciStore();
