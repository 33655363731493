import { useForm } from 'antd/es/form/Form';
import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';
import { observer } from 'mobx-react-lite';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { REGISTRI_CONFIG } from 'modules/registri/registri.constants';
import { RegistarKeyProps } from 'modules/registri/registri.types';
import { useMemo } from 'react';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';
import { IFilterTableProps } from 'typescript/NrizTypes';

export const RegistriFilters: React.FC<RegistarKeyProps> = observer(({ registar }) => {
  const [registriFiltersForm] = useForm();

  const { components, initialState } = REGISTRI_CONFIG[registar].tableData.filters;

  const { getItemList } = REGISTRI_CONFIG[registar].store;

  const filterComponents = useMemo(() => {
    return components;
  }, [registar]);

  const filterData: IFilterTableProps = {
    filterStoreName: FiltersNameEnum.REGISTRI,
    form: registriFiltersForm,
    initialState: { ...initialState, ...filtersStore.getFilters[FiltersNameEnum.REGISTRI] },
    filterOptions: filterComponents(registriFiltersForm),
    fetchTableList: () => getItemList(paginationInitialState),
    resetForm: components,
  };

  return <FilterTableForm data={filterData} />;
});
