import { ApiResponse, axios } from 'modules/axios';
import { ROUTES } from 'modules/nriz/nriz.constants';
import queryString from 'query-string';
import { IPaginatedApiResponse } from 'typescript/NrizTypes';
import { IDozvolaRequest, IDozvolaResponse, IDozvolaUpdate } from './dozvole.types';

const DOZVOLA_BASE_ROUTE = `${ROUTES.REGISTAR_DOZVOLA}-dozvola/`;

const fetchDozvoleList = (params: any): IPaginatedApiResponse<IDozvolaResponse> => {
  const query = queryString.stringify(params);
  return axios.get(`${DOZVOLA_BASE_ROUTE}?${query}`);
};

const createDozvola = (payload: IDozvolaRequest): ApiResponse<{ id: string }> => {
  return axios.post(DOZVOLA_BASE_ROUTE, payload);
};

const updateDozvolaInfo = (id: string, payload: IDozvolaUpdate): ApiResponse<{ id: string }> => {
  return axios.put(`${DOZVOLA_BASE_ROUTE}${id}`, payload);
};

const fetchDozvolaById = (id: string): ApiResponse<IDozvolaResponse> => {
  return axios.get(`${DOZVOLA_BASE_ROUTE}${id}`);
};

const deleteDozvola = (id: string) => {
  return axios.delete(`${DOZVOLA_BASE_ROUTE}${id}`);
};

export const dozvoleRepo = {
  fetchDozvoleList,
  createDozvola,
  updateDozvolaInfo,
  fetchDozvolaById,
  deleteDozvola,
};
