import { Col } from 'antd';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { observer } from 'mobx-react-lite';
import {
  MAX_AND_MIN_NUMBER,
  MAX_LENGTH_RULE,
  MORE_THAN_OR_EQUAL_0_RULE,
  MORE_THEN_0,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { obrazac3Store } from 'modules/obrasci/store/obrazac3/obrazac3.store';
import { useTranslation } from 'react-i18next';
import styles from './NewObrazac3DodatniPodaciForm.module.scss';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { ICrudTypeModalProps } from 'components/Modals/CRUDModal/CRUDModal';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { IGetObrazac3, Obrazac3Sistem } from 'modules/obrasci/store/obrazac3/obrazac3.types';
import { FormInputNumber } from 'components/FormInputNumber/FormInputNumber';
import { FormCheckbox } from 'components/FormCheckbox/FormCheckbox';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { KANALIZACIONI_RESET, VODOVODNI_RESET } from 'modules/obrasci/store/obrazac3/obrazac3.constants';
import { FormInput } from 'components/FormInput/FormInput';

export const NewObrazac3DodatniPodaciForm: React.FC<ICrudTypeModalProps> = observer(({ form }) => {
  const { t } = useTranslation();

  const defaultWorkingRegimeOption: ISifarnikInitial | undefined = obrazac3Store.initialState.radni_rezim_ispusta_id
    ? {
        label: (obrazac3Store.initialState as IGetObrazac3).radni_rezim_ispusta.name_sr,
        value: obrazac3Store.initialState.radni_rezim_ispusta_id,
      }
    : undefined;

  const handleVodovodniCheckbox = (event: CheckboxChangeEvent) => {
    obrazac3Store.handleChange('vodovod', event.target.checked);
    if (!event.target.checked) {
      VODOVODNI_RESET.filter(item => item !== 'vodovodni_sistem').forEach(item => form.setFieldValue(item, ''));
      if (Number(obrasciStore.opste.godina) >= 2023) {
        const filteredIndustrijskiPorosaci = obrazac3Store.initialState.glavni_industrijski_potrosaci_vode.filter(
          item => !item[Obrazac3Sistem.VODOVODNI]
        );
        obrazac3Store.handleInitialStateChange('glavni_industrijski_potrosaci_vode', filteredIndustrijskiPorosaci);
      } else obrazac3Store.handleInitialStateChange('glavni_industrijski_potrosaci_vode', []);
    }
  };

  const handleKanalizacioniCheckbox = (event: CheckboxChangeEvent) => {
    obrazac3Store.handleChange('kanalizacioni', event.target.checked);
    if (!event.target.checked) {
      KANALIZACIONI_RESET.filter(item => item !== 'kanalizacioni_sistem').forEach(item => form.setFieldValue(item, ''));
      if (Number(obrasciStore.opste.godina) >= 2023) {
        const filteredIndustrijskiPorosaci = obrazac3Store.initialState.glavni_industrijski_potrosaci_vode.filter(
          item => !item[Obrazac3Sistem.KANALIZACIONI]
        );
        obrazac3Store.handleInitialStateChange('glavni_industrijski_potrosaci_vode', filteredIndustrijskiPorosaci);
      }
    }
  };

  return (
    <Col span={24} className={styles.wrapper}>
      <h4>{t('OBRASCI.OBRAZAC3.ISPUST_INFO')}</h4>
      <FormSearchInfiniteScroll
        showSearch={true}
        formName={'radni_rezim_ispusta_id'}
        fetchOptinsList={obrazac3Store.fetchWorkingRegime}
        labelOptionAccessor="name_sr"
        valueOptionAccessor="id"
        inputName={'radni_rezim_ispusta_id'}
        label={t('OBRASCI.OBRAZAC3.RADNI_REZIM_ISPUSTA')}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        disabled={
          (Number(obrasciStore.opste.godina) >= 2023 && !obrazac3Store.initialState.kreiranje_godisnjeg_bilansa) ||
          obrasciStore.zakljucanObrazac
        }
        rules={[
          REQUIRED_FIELD_RULE(
            Number(obrasciStore.opste.godina) < 2023 || !!obrazac3Store.initialState.kreiranje_godisnjeg_bilansa
          ),
        ]}
        defaultOption={defaultWorkingRegimeOption}
        placeholder={''}
      />
      <FormInputNumber
        name="vremenski_period_ispustanja"
        label={t('OBRASCI.OBRAZAC3.VREMENSKI_PERIOD_ISPUSTANJA')}
        placeholder={''}
        inputName={'vremenski_period_ispustanja'}
        colon={true}
        disabled={
          (Number(obrasciStore.opste.godina) >= 2023 && !obrazac3Store.initialState.kreiranje_godisnjeg_bilansa) ||
          obrasciStore.zakljucanObrazac
        }
        rules={[
          REQUIRED_FIELD_RULE(
            Number(obrasciStore.opste.godina) < 2023 || !!obrazac3Store.initialState.kreiranje_godisnjeg_bilansa
          ),
          ...MAX_AND_MIN_NUMBER(1, 366),
        ]}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
        precision={0}
      />
      <FormInputNumber
        name="ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu"
        label={t('OBRASCI.OBRAZAC3.UKUPNA_KOLICINA_ISPUSTENE')}
        placeholder={''}
        inputName={'ukupna_kolicina_ispustene_otpadne_vode_u_izvestajnoj_godini_na_ispustu'}
        colon={true}
        disabled={Number(obrasciStore.opste.godina) >= 2023 || obrasciStore.zakljucanObrazac}
        rules={[
          REQUIRED_FIELD_RULE(
            Number(obrasciStore.opste.godina) < 2023 || !!obrazac3Store.initialState.kreiranje_godisnjeg_bilansa
          ),
          MORE_THAN_OR_EQUAL_0_RULE,
        ]}
        labelCol={{ span: 14 }}
        wrapperCol={{ span: 10 }}
      />
      {Number(obrasciStore.opste.godina) >= 2023 && (
        <FormInput
          name="nacin_odredjivanja_kolicine_ispustene_vode"
          label={t('OBRASCI.OBRAZAC3.NACIN_ODREDJIVANJA_ISPUSTENE')}
          placeholder={''}
          inputName={'nacin_odredjivanja_kolicine_ispustene_vode'}
          colon={true}
          rules={[REQUIRED_FIELD_RULE(!!obrazac3Store.initialState.kreiranje_godisnjeg_bilansa), MAX_LENGTH_RULE(300)]}
          labelCol={{ span: 14 }}
          wrapperCol={{ span: 10 }}
        />
      )}
      {obrazac3Store.otvObaveza && (
        <>
          <FormInputNumber
            name={'ukupan_kapacitet_proizvodnje_t_god'}
            label={t('OBRASCI.OBRAZAC3.UKUPAN_KAPACITET_PROIZVODNJE')}
            placeholder={''}
            inputName={'ukupan_kapacitet_proizvodnje_t_god'}
            colon
            // disabled
            rules={[REQUIRED_FIELD_RULE(!!obrazac3Store.initialState.kreiranje_godisnjeg_bilansa), ...MORE_THEN_0]}
            labelCol={{ span: 14 }}
            wrapperCol={{ span: 10 }}
          />
          <h5 className={styles.ukupanKapacitetInfo}>
            {t('OBRASCI.OBRAZAC3.UKUPAN_KAPACITET_PROIZVODNJE_DESCRIPTION')}
          </h5>
        </>
      )}
      <h4>{t('OBRASCI.OBRAZAC3.VODOVOD_ILI_KANALIZACIJA')}:</h4>
      <FormCheckbox
        labelCol={{ span: 14 }}
        name={'vodovodni_sistem'}
        label={t('OBRASCI.OBRAZAC3.VODOVODNI_SISTEMI')}
        onCheckBoxChange={handleVodovodniCheckbox}
      />
      <FormCheckbox
        labelCol={{ span: 14 }}
        name={'kanalizacioni_sistem'}
        label={t('OBRASCI.OBRAZAC3.KANALIZACIONI_SISTEMI')}
        onCheckBoxChange={handleKanalizacioniCheckbox}
      />
    </Col>
  );
});
