import { EditOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import useFormInstance from 'antd/es/form/hooks/useFormInstance';
import { ColumnType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import { Content } from 'components/Content/Content';
import { FormInput } from 'components/FormInput/FormInput';
import { FormSearchInfiniteScroll } from 'components/FormSearchInfiniteScroll/FormSearchInfiniteScroll';
import { ReactLink } from 'components/ReactLink/ReactLink';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import {
  IDENTIFICATION_NUMBER_FIELD_RULE,
  MAX_LENGTH_RULE,
  PIB_FIELD_RULE,
  REQUIRED_FIELD_RULE,
} from 'modules/nriz/nriz.constants';
import { dozvoleListColumns } from 'modules/registarDozvola/dozvole/dozvole.constants';
import { dozvoleStore } from 'modules/registarDozvola/dozvole/dozvole.store';
import { IDozvolaResponse } from 'modules/registarDozvola/dozvole/dozvole.types';
import { operateriStore } from 'modules/registarDozvola/operateri/operateri.store';
import { IOperaterOtpadaRequest } from 'modules/registarDozvola/operateri/operateri.types';
import { ISifarnikInitial } from 'modules/sifarnici/sifarnici.types';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import { DozvoleFilters } from './DozvoleFilters/DozvoleFilters';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';

const OperaterHeader = observer(() => {
  const { idOperatera } = useParams();

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingInline: '10px' }}>
      {idOperatera ? <h2>Operater: {operateriStore.selectedOperater?.naziv_preduzeca}</h2> : <h2>Novi operater</h2>}
      <ReactLink to={`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/`}>
        <Button name="back-to-operater-list-btn">Nazad</Button>
      </ReactLink>
    </div>
  );
});

interface OperaterFormHeaderProps {
  isFormEditable: boolean;
  setIsFormEditable: Dispatch<SetStateAction<boolean>>;
}

const OperaterFormHeader: React.FC<OperaterFormHeaderProps> = observer(({ isFormEditable, setIsFormEditable }) => {
  const { idOperatera } = useParams();
  const form = useFormInstance();
  const navigate = useNavigate();

  const onDiscardClick = () => {
    form.resetFields();
    setIsFormEditable(false);
  };

  const onChangeClick = () => {
    setIsFormEditable(true);
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
      <h3>Podaci o operateru</h3>
      {idOperatera ? (
        isFormEditable ? (
          <div style={{ display: 'flex', gap: '20px' }}>
            <Button name="discard-operater-changes-btn" htmlType="button" onClick={onDiscardClick} danger>
              Odbaci
            </Button>
            <Button name="change-operater-btn" htmlType="submit">
              Sačuvaj
            </Button>
          </div>
        ) : (
          <div style={{ display: 'flex', gap: '10px' }}>
            <Popconfirm
              name="delete-operater-popconfirm"
              title={'Da li ste sigurni da želite da izbrišete operatera?'}
              okText={'Obriši'}
              okButtonProps={{ disabled: false }}
              cancelButtonProps={{ disabled: false }}
              cancelText={'Odbaci'}
              onConfirm={async () => {
                const response = await operateriStore.deleteOperater(idOperatera);
                if (response) navigate(`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/`);
              }}
              placement="bottom"
            >
              <Button name={'delete-dozvola'} danger>
                Obriši
              </Button>
            </Popconfirm>
            <Button name="toggle-operater-form-editable-btn" onClick={onChangeClick} htmlType="button">
              Izmeni
            </Button>
          </div>
        )
      ) : (
        <Button name="save-operater-btn" htmlType="submit">
          Sačuvaj
        </Button>
      )}
    </div>
  );
});

const OperaterForm = observer(() => {
  const { idOperatera } = useParams();
  const [isFormEditable, setIsFormEditable] = useState(!idOperatera);

  const navigate = useNavigate();

  const [form] = useForm<IOperaterOtpadaRequest>();

  const handleOperaterUseEffect = async (id: string) => {
    const fetchedOperater = await operateriStore.fetchOperaterById(id);

    if (fetchedOperater) {
      await dozvoleStore.fetchDozvoleList(dozvoleStore.dozvolePagination, id);
      form.setFieldsValue(fetchedOperater);
    }
  };

  const handleFormSubmit = async (formValues: IOperaterOtpadaRequest) => {
    try {
      if (idOperatera) {
        const payload = { ...formValues, id: idOperatera };
        await operateriStore.updateOperaterInfo(payload);
        setIsFormEditable(false);
        message.success('Uspešno izmenjen operater!');
      } else {
        const { id } = await operateriStore.createOperater(formValues);
        navigate(`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/${id}`);
        message.success('Uspešno kreiran operater!');
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (idOperatera) handleOperaterUseEffect(idOperatera);
    return () => operateriStore.setSelectedOperater({});
  }, [idOperatera]);

  const defaultOpstinaOption: ISifarnikInitial | undefined = operateriStore.selectedOperater?.opstina_preduzeca_id
    ? {
        label: operateriStore.selectedOperater.opstina_preduzeca?.display_name,
        value: operateriStore.selectedOperater.opstina_preduzeca_id,
      }
    : undefined;

  return (
    <Form
      labelCol={{ span: 12 }}
      wrapperCol={{ span: 12 }}
      labelAlign="left"
      onFinish={handleFormSubmit}
      initialValues={operateriStore.selectedOperater}
      form={form}
    >
      <div>
        <OperaterFormHeader isFormEditable={isFormEditable} setIsFormEditable={setIsFormEditable} />

        <div style={{ padding: '20px', marginBottom: '15px', backgroundColor: 'white' }}>
          <FormInput
            name="naziv_preduzeca"
            inputName="naziv_preduzeca"
            rules={[REQUIRED_FIELD_RULE(true)]}
            label="Naziv preduzeća"
            readOnly={!isFormEditable}
          />
          <FormInput
            name="pib_preduzeca"
            inputName="pib_preduzeca"
            rules={[REQUIRED_FIELD_RULE(true), PIB_FIELD_RULE]}
            label="PIB preduzeća"
            disabled={!!idOperatera}
            readOnly={!isFormEditable}
          />
          <FormInput
            name="mb_preduzeca"
            inputName="mb_preduzeca"
            rules={[REQUIRED_FIELD_RULE(true), IDENTIFICATION_NUMBER_FIELD_RULE]}
            label="Matični broj preduzeća"
            disabled={!!idOperatera}
            readOnly={!isFormEditable}
          />
          <FormInput
            name="adresa_preduzeca"
            inputName="adresa_preduzeca"
            rules={[REQUIRED_FIELD_RULE(true), MAX_LENGTH_RULE(100)]}
            label="Adresa preduzeća"
            readOnly={!isFormEditable}
          />
          <FormSearchInfiniteScroll
            showSearch={true}
            formName={'opstina_preduzeca_id'}
            label="Opština preduzeća"
            placeholder=""
            rules={[REQUIRED_FIELD_RULE(true)]}
            fetchOptinsList={operateriStore.fetchOpstina}
            labelOptionAccessor="display_name"
            valueOptionAccessor="id"
            readOnly={!isFormEditable}
            defaultOption={defaultOpstinaOption}
          />
        </div>
      </div>
    </Form>
  );
});

export const OperaterDetails = observer(() => {
  const { idOperatera } = useParams();

  const refetchData = () => {
    dozvoleStore.fetchDozvoleList(dozvoleStore.dozvolePagination, idOperatera);
  };

  const columns: ColumnType<IDozvolaResponse>[] = [
    ...dozvoleListColumns,

    {
      fixed: 'right',
      align: 'center',
      width: 70,

      render: (_: any, record: IDozvolaResponse) => {
        return (
          <ReactLink to={`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/operater/${idOperatera}/dozvola/${record.id}`}>
            <Button icon={<EditOutlined />} name="edit-operater" />
          </ReactLink>
        );
      },
    },
  ];

  return (
    <Content header={<OperaterHeader />}>
      <div style={{ padding: '20px' }}>
        <OperaterForm />
        {idOperatera && (
          <>
            <div
              style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}
            >
              <h4 style={{ marginBottom: '10px' }}>Dozvole za upravljanje otpadom</h4>
              <ReactLink to={`${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/operater/${idOperatera}/dozvola/`}>
                <Button name="add-new-permit-btn">Dodaj novu dozvolu</Button>
              </ReactLink>
            </div>
            <DozvoleFilters />
            <TableData
              name="dozvole-za-otpad-table"
              columns={columns}
              dataSource={dozvoleStore.dozvoleList}
              refetchData={refetchData}
              storePagination={dozvoleStore.dozvolePagination}
            />
          </>
        )}
      </div>
    </Content>
  );
});
