import { Col, Form, Row } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Button } from 'components/Button/Button';
import { FormDatePicker } from 'components/FormDatePicker/FormDatePicker';
import { FormInput } from 'components/FormInput/FormInput';
import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import { REQUIRED_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { POSALJI_PRESEK_FORM_INITIAL_STATE, PreseciTypeEnum } from 'modules/preseci/preseci.constants';
import { preseciStore } from 'modules/preseci/preseci.store';
import { IPosaljiPresekForm } from 'modules/preseci/preseci.types';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import { modalStore } from 'store';

interface IModalPosaljiIzvestajProps {
  presekType: PreseciTypeEnum;
}

export const ModalPosaljiIzvestaj: FC<IModalPosaljiIzvestajProps> = observer(({ presekType }) => {
  const { t } = useTranslation();
  const [form] = useForm();
  const navigate = useNavigate();
  const onOdustani = () => {
    form.setFieldsValue(POSALJI_PRESEK_FORM_INITIAL_STATE);
    modalStore.removeAllModals();
  };

  const posaljiIzvestaj = async (value: IPosaljiPresekForm) => {
    const poslat = await preseciStore.posaljiSinglePresek(presekType, value);
    if (poslat) navigate(`${RoutePathEnum.LISTA_PRESEKA}/${presekType}`);
    onOdustani();
  };

  return (
    <>
      {modalStore.getCurrentOpenedModal.includes(`posalji-izvestaj-modal`) && (
        <Modal
          name={`posalji-izvestaj-modal`}
          open={true}
          okButtonProps={{ style: { display: 'none' } }}
          cancelButtonProps={{ style: { display: 'none' } }}
          closable={true}
          onCancel={onOdustani}
          title={t('Podaci o izveštaju')}
          footer={null}
          destroyOnClose={true}
          maskClosable={true}
          width={'70%'}
        >
          <Form
            form={form}
            onFinish={posaljiIzvestaj}
            initialValues={POSALJI_PRESEK_FORM_INITIAL_STATE}
            labelCol={{ span: 6 }}
          >
            <FormInput
              inputName={'broj_izvestaja'}
              name={'broj_izvestaja'}
              rules={[REQUIRED_FIELD_RULE(true)]}
              label={t('PRESECI.BROJ_IZVESTAJA')}
            />
            <FormDatePicker
              name={'datum_izvestaja'}
              format="DD-MM-YYYY"
              label={t('PRESECI.DATUM_IZVESTAJA')}
              picker="date"
              rules={[REQUIRED_FIELD_RULE(true)]}
            />
            <Row justify={'end'} gutter={20}>
              <Col>
                <Button name="ukljuci-filteri" danger onClick={onOdustani}>
                  Odustani
                </Button>
              </Col>
              <Col>
                <Button htmlType="submit" name="iskljuci-filteri">
                  Pošalji izveštaj
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal>
      )}
    </>
  );
});
