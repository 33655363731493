import { FormConfig } from 'modules/obrazacIzvestaji/obrazacIzvestajiFilters.constants';
import i18n from 'translations/i18n.config';
import {
  AUTOMATSKI_ISKLJUCEN_PRESEK_COLUMN,
  BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS,
  BROJ_PREDUZECA_COLUMN,
  BROJ_ZAPISA_COLUMN,
  DATUM_SLANJA_COLUMN,
  GODINA_COLUMN,
  MATICNI_BROJ_COLUMN,
  MESTO_PREDUZECA_COLUMN,
  NACIONALNI_ID_POSTROJENJA_COLUMN,
  NAZIV_POSTROJENJA_COLUMN,
  NAZIV_PREDUZECA_COLUMN,
  OPSTINA_PREDUZECA_COLUMN,
  PIB_COLUMN,
  STATUS_COLUMN,
  ULICA_PREDUZECA_COLUMN,
} from '../preseciColumns.constants';
import { IPreseciColumnProps } from '../preseci.types';
import {
  DATUM_SLANJA_GTE_FILTER,
  DATUM_SLANJA_LTE_FILTER,
  NACIONALNI_ID_POSTROJENJA_FILTER,
  NAZIV_POSTROJENJA_FILTER,
  NAZIV_PREDUZECE_FILTER,
  PIB_FILTER,
} from '../preseciFilters.constants';
import {
  IFiltersOtvPreseciList,
  IFiltersOtvSinglePresek,
  IGetOtvPreseciList,
  IGetOtvSinglePresek,
  IModalUkljuciIskljuciOtvSinglePresek,
} from './otvPresek.types';
import {
  ISifarniciTypeOfForm,
  SearchScrollSifarniciFormProps,
  SIFARNICI_ROUTES,
} from 'modules/sifarnici/sifarnici.types';
import { PIB_FIELD_RULE } from 'modules/nriz/nriz.constants';
import { FormInstance } from 'antd';

const ZAGADJUJUCA_MATERIJA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  label: i18n.t('PRESECI.ZAGADJUJUCA_MATERIJA'),
  formName: 'zagadjujuce_materije_id',
  labelOptionAccessor: ['name_sr'],
  valueOptionAccessor: 'id',
  fetchOptinsList: SIFARNICI_ROUTES.POLLUTANT_CODE,
};

//#region LISTA P
export const PRESECI_OTV_COLUMNS: IPreseciColumnProps<IGetOtvPreseciList>[] = [
  DATUM_SLANJA_COLUMN as IPreseciColumnProps<IGetOtvPreseciList>,
  BROJ_ZAPISA_COLUMN as IPreseciColumnProps<IGetOtvPreseciList>,
  BROJ_PREDUZECA_COLUMN as IPreseciColumnProps<IGetOtvPreseciList>,
  { width: 200, dataIndex: 'broj_zagadjujucih_materija', title: i18n.t('PRESECI.BROJ_ZAGADJUJUCIH_MATERIJA') },
  ...(BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS as IPreseciColumnProps<IGetOtvPreseciList>[]),
];

export const OTV_PRESECI_LIST_FILTERS_INITIAL_STATE: IFiltersOtvPreseciList = {
  datum_slanja__gte: '',
  datum_slanja__lte: '',
  obrazac_preduzece_id: '',
  zagadjujuce_materije_id: '',
};

export const OTV_PRESECI_LIST_FILTERS_OPTIONS: FormConfig = (form): ISifarniciTypeOfForm[] => [
  DATUM_SLANJA_GTE_FILTER,
  DATUM_SLANJA_LTE_FILTER,
  NAZIV_PREDUZECE_FILTER(form),
  ZAGADJUJUCA_MATERIJA_FILTER,
];

//#endregion LISTA P

//#region PRESEK
export const SINGLE_PRESEK_OTV_COLUMNS = (): IPreseciColumnProps<IGetOtvSinglePresek>[] => [
  GODINA_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
  PIB_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
  MATICNI_BROJ_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
  NAZIV_PREDUZECA_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
  MESTO_PREDUZECA_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
  OPSTINA_PREDUZECA_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
  ULICA_PREDUZECA_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
  NACIONALNI_ID_POSTROJENJA_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
  NAZIV_POSTROJENJA_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
  {
    title: `${i18n.t('PRESECI.NAZIV_ISPUSTA')}`,
    dataIndex: 'naziv_ispusta',
    key: 'naziv_ispusta',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.OZNAKA_ISPUSTA')}`,
    dataIndex: 'oznaka_ispusta',
    key: 'oznaka_ispusta',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.ZONA_SANITARNE_ZASTITE_IZVORISTA')}`,
    dataIndex: 'zona_sanitarne_zastite_izvorista',
    key: 'zona_sanitarne_zastite_izvorista',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.VODNA_TELA_NAMENJENA_REKREACIJI')}`,
    dataIndex: 'vodna_tela_namenjena_rekreaciji',
    key: 'vodna_tela_namenjena_rekreaciji',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.POV_POSTROJENJE_U_IZGRADNJI_ILI_REKONSTRUKCIJI')}`,
    dataIndex: 'pov_postrojenje_u_izgradnji_ili_rekonstrukciji',
    key: 'pov_postrojenje_u_izgradnji_ili_rekonstrukciji',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.POSEBNA_CENA_U_SKLADU_SA_NAPOMENOM_IZ_TABELE_8_1_ZAKONA')}`,
    dataIndex: 'posebna_cena',
    key: 'posebna_cena',
    width: 300,
  },
  {
    title: `${i18n.t('PRESECI.VREMENSKI_PERIOD_ISPUSTANJA_VODE_DAN_GOD')}`,
    dataIndex: 'vremenski_period_ispustanja_vode',
    key: 'vremenski_period_ispustanja_vode',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.UKUPNA_KOLICINA_ISPUSTENE_VODE_M3_GOD')}`,
    dataIndex: 'ukupna_kolicina_ispustene_otpadne_vode',
    key: 'ukupna_kolicina_ispustene_otpadne_vode',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.SREDNJI_DNEVNI_PROTOK_OTPADNE_VODE_M3_DAN')}`,
    dataIndex: 'srednji_dnevni_protok_otpdane_vode',
    key: 'srednji_dnevni_protok_otpdane_vode',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.KAPACITET_PROIZVODNJE_T_GOD')}`,
    dataIndex: 'kapacitet_proizvodnje_t_god',
    key: 'kapacitet_proizvodnje_t_god',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.ZAGADJUJUCA_MATERIJA')}`,
    dataIndex: 'zagadjujuca_materija',
    key: 'zagadjujuca_materija',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.SREDNJA_GODISNJA_IZMERENA_VREDNOST_MG_L')}`,
    dataIndex: 'srednja_godisnja_izmerena_vrednost',
    key: 'srednja_godisnja_izmerena_vrednost',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.KOLICINA_PRI_REDOVNOM_RADU_KG_GOD')}`,
    dataIndex: 'kolicina_pri_redovnom_radu',
    key: 'kolicina_pri_redovnom_radu',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.GVE')}`,
    dataIndex: 'gve',
    key: 'gve',
    width: 250,
  },
  {
    title: `${i18n.t('PRESECI.GVE_JEDINICA_MERE')}`,
    dataIndex: 'gve_jedinica_mere',
    key: 'gve_jedinica_mere',
    width: 250,
  },
  STATUS_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
  AUTOMATSKI_ISKLJUCEN_PRESEK_COLUMN as IPreseciColumnProps<IGetOtvSinglePresek>,
];

export const OTV_SINGLE_PRESEK_FILTER_INITIAL_STATE: IFiltersOtvSinglePresek = {
  pib__ilike: '',
  obrazac_preduzece_id: '',
  nacionalni_id__ilike: '',
  postrojenje_id: '',
  naziv_ispusta__ilike: '',
  oznaka_ispusta__ilike: '',
  zagadjujuce_materije_id: '',
  iskljucen: null,
};

export const OTV_SINGLE_PRESEK_FILTERS_OPTIONS: FormConfig = (form: FormInstance): ISifarniciTypeOfForm[] => [
  PIB_FILTER({ ilike: true }),
  NAZIV_PREDUZECE_FILTER(form),
  NAZIV_POSTROJENJA_FILTER(form),
  NACIONALNI_ID_POSTROJENJA_FILTER({ ilike: true }),

  {
    type_of_form: 'input',
    label: i18n.t('PRESECI.NAZIV_ISPUSTA'),
    name: 'naziv_ispusta__ilike',
    inputName: 'naziv_ispusta__ilike',
  },
  {
    type_of_form: 'input',
    label: i18n.t('PRESECI.OZNAKA_ISPUSTA'),
    name: 'oznaka_ispusta__ilike',
    inputName: 'oznaka_ispusta__ilike',
  },
  ZAGADJUJUCA_MATERIJA_FILTER,
];
//#endregion PRESEK

//#region MODAL
export const OTV_MODAL_SINGLE_PRESEK_FORM_INITIAL_STATE: IModalUkljuciIskljuciOtvSinglePresek = {
  pib: '',
  obrazac_preduzece_id: '',
  nacionalni_id: '',
  postrojenje_id: '',
  naziv_ispusta: '',
  oznaka_ispusta: '',
  zagadjujuca_materija_id: '',
  iskljucen: null,
  godina: '',
};

export const OTV_MODAL_SINGLE_PRESEK_FORM_OPTIONS: FormConfig = (form: FormInstance): ISifarniciTypeOfForm[] => [
  PIB_FILTER({ rules: [PIB_FIELD_RULE] }),
  NAZIV_PREDUZECE_FILTER(form),
  NAZIV_POSTROJENJA_FILTER(form),
  NACIONALNI_ID_POSTROJENJA_FILTER(),
  {
    type_of_form: 'input',
    label: i18n.t('PRESECI.NAZIV_ISPUSTA'),
    name: 'naziv_ispusta',
    inputName: 'naziv_ispusta',
  },
  {
    type_of_form: 'input',
    label: i18n.t('PRESECI.OZNAKA_ISPUSTA'),
    name: 'oznaka_ispusta__ilike',
    inputName: 'oznaka_ispusta__ilike',
  },
  ZAGADJUJUCA_MATERIJA_FILTER,
];
//#endregion MODAL
