import { IEvropskiIzvestajiTranslations } from './translation.types';

// Todo: seperate files to srpski/engleski folder then import them into parent object

export const evropskiIzvestajiTranslations: IEvropskiIzvestajiTranslations = {
  EVROPSKI_IZVESTAJI: 'Izveštaji ka EU',
  EU_IZVESTAJI_TITLE: 'EU Izveštaji',
  GODINA: 'Godina',
  VRSTA_IZVESTAJA: 'Vrsta izveštaja',
  KREIRAJ_PRESEK: 'Kreiraj presek',
  TOOLTIP_CREATE_INTERSECTION_CONFIRMATION:
    'Da li ste sigurni da je LCP registar ažuran i da želite kreiranje preseka?',
  CHANGE_STATUS_FORM_TITLE: 'Izmena statusa postrojenja',
  STATUS_POSTROJENJA: 'Status postrojenja',
  STATUSES: {
    FUNCTIONAL: 'Aktivno postrojenje',
    DISUSED: 'Postrojenje van upotrebe',
    DECOMMISSIONED: 'Zatvoreno postrojenje',
    NOT_REGULATED: 'Not regulated',
  },
  CHANGE_BUTTON: 'Izmeni',
  CANCEL_BUTTON: 'Odustani',
  CREATE_BUTTON: 'Kreiraj',
  INTERSECTION_DATE: 'Datum preseka:',
  CANCEL_INTERSECTION: 'Odustani od preseka',
  TOOLTIP_CANCEL_INTERSECTION_CONFIRMATION:
    'Da li želite da odustanete od preseka? Ako odustanete, sve izvršene izmene će biti poništene pa je potrebno ponovo kreirati presek.',
  GENERATE_EXCEL_TABLE: 'Generiši excel tabele',
  LOCATION_PATH_LABEL: 'Lokacija za eksport podataka',
  NO_CHROME_MESSAGE:
    'Poštovani, s obzirom da ne koristite browser Chrome ili Edge, generisani Excel fajlovi će biti skladišteni u default direktorijumu.',
  CHROME_MESSAGE: 'Poštovani, generisani fajlovi biće sačuvani na odabranoj lokaciji.',
};
