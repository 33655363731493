import { makeAutoObservable } from 'mobx';
import { COMPANY_LIST_FILTERS } from 'modules/company/company.constants';
import { ICompaniesListFilters } from 'modules/company/company.types';
import { FACILITY_FILTERS_INITIAL_STATE } from 'modules/facility/facility.constants';
import { IFacilityFilters } from 'modules/facility/facility.types';
import { IAuditLogs } from 'modules/istorijaPromena/istorijaPromena.types';
import { ISTORIJA_PROMENA_INITIAL_STATE } from 'modules/istorijaPromena/istroijaPromena.constants';
import { IFilterDataObrasci } from 'modules/obrasci/filterDataObrasci.types';
import { ObrazacIzvestajiAllFilters } from 'modules/obrazacIzvestaji/obrazacIzvestaji.types';
import { FiltersPreseciList, FiltersSinglePresek } from 'modules/preseci/preseci.types';
import { ISifarniciFilters } from 'modules/sifarnici/sifarnici.types';
import { AIR_FILTERS_INITIAL_STATE } from 'modules/sourcesOfPollution/air/air.constants';
import { IAirFilters } from 'modules/sourcesOfPollution/air/air.types';
import { UNSANITARY_LANDFILL_FILTERS_INITIAL_STATE } from 'modules/unsanitarylandfill/unsanitarylandfill.constants';
import { IUnsanitaryLandfillFilters } from 'modules/unsanitarylandfill/unsanitarylandfill.types';
import { USER_FILTERS_INITIAL_STATE } from 'modules/user/user.constants';
import { IUserFilters } from 'modules/user/user.types';
import { EvropskiIzvestajiAllFilters } from 'modules/evropskiIzvestaji/evropskiIzvestaji.types';
import { IPregledDozvolaFilters } from 'modules/registarDozvola/pregled/pregledDozvola.types';
import { PREGLED_DOZVOLA_FILTERS_INITIAL_STATE } from 'modules/registarDozvola/pregled/pregledDozvola.constants';
import { IOperateriFilters } from 'modules/registarDozvola/operateri/operateri.types';
import { IDozvoleFilters } from 'modules/registarDozvola/dozvole/dozvole.types';
import { DOZVOLE_FILTERS_INITIAL_STATE } from 'modules/registarDozvola/dozvole/dozvole.constants';
import { IOtpadDozvoleFilters } from 'modules/registarDozvola/otpad/otpad.types';

export interface IFiltersBySection {
  korisnik: IUserFilters;
  korisnik_postrojenje: IUserFilters;
  preduzeca: ICompaniesListFilters;
  postrojenja: IFacilityFilters;
  sifarnici: ISifarniciFilters;
  obrasci: IFilterDataObrasci;
  izvor_vazduh: IAirFilters;
  nesanitarne_deponije: IUnsanitaryLandfillFilters;
  registri: any;
  obrazacIzvestaji: ObrazacIzvestajiAllFilters;
  evropskiIzvestaji: EvropskiIzvestajiAllFilters;
  istorijaPromena: IAuditLogs;
  lista_preseka: FiltersPreseciList;
  single_presek: FiltersSinglePresek;
  dozvole_pregled: IPregledDozvolaFilters;
  dozvole_operateri: IOperateriFilters;
  dozvole_dozvole: IDozvoleFilters;
  dozvole_otpad: IOtpadDozvoleFilters;
}

export const FILTERS_INITIAL_STATE: IFiltersBySection = {
  korisnik: USER_FILTERS_INITIAL_STATE,
  korisnik_postrojenje: USER_FILTERS_INITIAL_STATE,
  preduzeca: COMPANY_LIST_FILTERS,
  postrojenja: FACILITY_FILTERS_INITIAL_STATE,
  sifarnici: {},
  obrasci: {},
  izvor_vazduh: AIR_FILTERS_INITIAL_STATE,
  nesanitarne_deponije: UNSANITARY_LANDFILL_FILTERS_INITIAL_STATE,
  registri: undefined,
  obrazacIzvestaji: {},
  evropskiIzvestaji: {} as EvropskiIzvestajiAllFilters,
  istorijaPromena: ISTORIJA_PROMENA_INITIAL_STATE,
  lista_preseka: {},
  single_presek: {},
  dozvole_pregled: PREGLED_DOZVOLA_FILTERS_INITIAL_STATE,
  dozvole_operateri: {},
  dozvole_dozvole: DOZVOLE_FILTERS_INITIAL_STATE,
  dozvole_otpad: {},
};

export enum FiltersNameEnum {
  KORISNIK = 'korisnik',
  KORISNIK_POSTROJENJE = 'korisnik_postrojenje',
  PREDUZECA = 'preduzeca',
  POSTROJENJA = 'postrojenja',
  SIFARNICI = 'sifarnici',
  OBRASCI = 'obrasci',
  IZVOR_VAZDUH = 'izvor_vazduh',
  NESANITARNE_DEPONIJE = 'nesanitarne_deponije',
  REGISTRI = 'registri',
  OBRAZAC_IZVESTAJI = 'obrazacIzvestaji',
  EVROPSKI_IZVESTAJI = 'evropskiIzvestaji',
  ISTORIJA_PROMENA = 'istorijaPromena',
  LISTA_PRESEKA = 'lista_preseka',
  SINGLE_PRESEK = 'single_presek',
  DOZVOLE_PREGLED = 'dozvole_pregled',
  DOZVOLE_OPERATERI = 'dozvole_operateri',
  DOZVOLE_DOZVOLE = 'dozvole_dozvole',
  DOZVOLE_OTPAD = 'dozvole_otpad',
}

export class FilteriStore {
  filters: IFiltersBySection = FILTERS_INITIAL_STATE;

  constructor() {
    makeAutoObservable(this);
  }

  get getFilters() {
    return this.filters;
  }

  handleChange(key: string, value: any) {
    (this as any)[key] = value;
  }

  handleFiltersChange = (type: FiltersNameEnum, key: string, value: any) => {
    this.filters[type] = { ...this.filters[type], [key]: value };
  };

  setFilters = (type: FiltersNameEnum, filters: any) => {
    this.filters[type] = filters;
  };
}

export const filtersStore = new FilteriStore();
