import i18n from 'translations/i18n.config';
import { GetPreseciListTypes, GetSinglePresekTypes, IPreseciColumnProps } from './preseci.types';
import dayjs from 'dayjs';
import { Checkbox, Switch } from 'antd';
import { PreseciTypeEnum } from './preseci.constants';
import { preseciStore } from './preseci.store';
import { IGetPtp2PreseciList, IGetPtp2SinglePresek } from './ptp2Presek/ptp2Presek.types';
import { preseciService } from './preseci.service';
import { ColumnType } from 'antd/es/table';
import { Button } from 'components/Button/Button';
import { CloseOutlined, EyeOutlined } from '@ant-design/icons';
import { modalStore } from 'store';

export const DATUM_SLANJA_COLUMN = {
  width: 250,
  dataIndex: 'datum_slanja',
  title: i18n.t('PRESECI.DATUM_I_VREME_SLANJA_IZVESTAJA'),
  render: (_: any, record: GetPreseciListTypes) => {
    if ('datum_slanja' in record) {
      return <div>{dayjs(record.datum_slanja).format('DD-MM-YYYY HH:mm:ss')}</div>;
    }
    return <></>;
  },
};

export const DATUM_I_VREME_SLANJA_COLUMN = {
  width: 250,
  dataIndex: 'datum_i_vreme_slanja',
  title: i18n.t('PRESECI.DATUM_I_VREME_SLANJA_IZVESTAJA'),
  render: (_: any, record: IGetPtp2PreseciList) => (
    <div>{dayjs(record.datum_i_vreme_slanja).format('DD-MM-YYYY HH:mm:ss')}</div>
  ),
};

export const DATUM_DOPISA_COLUMN = {
  width: 250,
  dataIndex: 'datum_dopisa' as keyof IGetPtp2PreseciList,
  title: i18n.t('PRESECI.DATUM_DOPISA'),
  render: (_: any, record: IGetPtp2PreseciList) =>
    record.datum_dopisa ? <div>{dayjs(record.datum_dopisa).format('DD-MM-YYYY HH:mm:ss')}</div> : <></>,
};

export const DATUM_I_VREME_PRESEKA_COLUMN = {
  width: 250,
  dataIndex: 'datum_dopisa' as keyof IGetPtp2PreseciList,
  title: i18n.t('PRESECI.DATUM_I_VREME_PRESEKA'),
  render: (_: any, record: IGetPtp2PreseciList) => (
    <div>{dayjs(record.datum_i_vreme_preseka).format('DD-MM-YYYY HH:mm:ss')}</div>
  ),
};

export const BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS = [
  { width: 150, dataIndex: 'broj_izvestaja', title: 'Broj izveštaja' },
  {
    width: 200,
    dataIndex: 'datum_izvestaja',
    title: i18n.t('PRESECI.DATUM_IZVESTAJA'),
    render: (_: any, record: GetPreseciListTypes) => (
      <div>{dayjs(record.datum_izvestaja).format('DD-MM-YYYY HH:mm:ss')}</div>
    ),
  },
  {
    width: 250,
    dataIndex: 'datum_preseka',
    title: i18n.t('PRESECI.DATUM_PRESEKA'),
    render: (_: any, record: GetPreseciListTypes) => {
      if ('datum_preseka' in record) return <div>{dayjs(record.datum_preseka).format('DD-MM-YYYY HH:mm:ss')}</div>;
      return <></>;
    },
  },
];

export const BROJ_ZAPISA_COLUMN = { width: 120, dataIndex: 'broj_zapisa', title: i18n.t('PRESECI.BROJ_ZAPISA') };

export const BROJ_PREDUZECA_COLUMN = {
  width: 150,
  dataIndex: 'broj_preduzeca',
  title: i18n.t('PRESECI.BROJ_PREDUZECA'),
};

export const BROJ_OPERATERA_COLUMN = {
  width: 200,
  dataIndex: 'broj_operatera',
  title: i18n.t('PRESECI.BROJ_OPERATERA'),
};

export const AUTOMATSKI_ISKLJUCEN_PRESEK_COLUMN = {
  width: 110,
  title: i18n.t('PRESECI.AUTOMATSKI_ISKLJUCEN'),
  dataIndex: 'automatski_iskljucen',
  align: 'center',
  fixed: 'right',
  render: (_: any, record: GetSinglePresekTypes) => {
    if ('automatski_iskljucen' in record) {
      return <Checkbox checked={!!record.automatski_iskljucen} disabled={true} />;
    }
    return <></>;
  },
};

export const ISKLJUCI_UKLJUCI_PRESEK_COLUMN = (
  preseciType: PreseciTypeEnum,
  datumPreseka: string | null | undefined
): IPreseciColumnProps<any> => {
  return {
    width: 100,
    dataIndex: 'iskljucen',
    align: 'center',
    fixed: 'right',
    title: i18n.t('PRESECI.ISKLJUCEN'),
    render: (_: any, record: GetSinglePresekTypes) => {
      const disabled = preseciService.isSwitchIskljucenoDisabled(preseciType, record, datumPreseka);
      return (
        <Switch
          disabled={disabled}
          defaultChecked={record.iskljucen}
          onChange={(value: boolean) => {
            if (value) {
              preseciStore.iskljuciStavkuPresekaPoId(preseciType, record);
            } else {
              preseciStore.ukljuciStavkuPresekaPoId(preseciType, record);
            }
          }}
        ></Switch>
      );
    },
  };
};

export const ISKLJUCI_PTP_PRIJAVU_COLUMN: ColumnType<IGetPtp2SinglePresek> = {
  width: 100,
  align: 'center',
  fixed: 'right',
  title: i18n.t('PRESECI.ISKLJUCEN'),
  render(_, record) {
    const hideIskljucivanjeBtn = record.iskljucen || !!record.datum_i_vreme_preseka;
    const renderIcon = () => (hideIskljucivanjeBtn ? <EyeOutlined /> : <CloseOutlined />);
    return (
      <Button
        name="iskljuci-ptp2-btn"
        onClick={() => {
          preseciStore.handleChange('ptp2PrijavaZaIskljucivanje', record);
          modalStore.changeModalName('ptp2-presek-iskljucivanje-modal');
        }}
        danger={!hideIskljucivanjeBtn}
        icon={renderIcon()}
      />
    );
  },
};

export const STATUS_COLUMN = { width: 150, dataIndex: 'status', title: i18n.t('PRESECI.STATUS') };

export const GODINA_COLUMN = { width: 100, dataIndex: 'godina', title: i18n.t('YEAR'), fixed: 'left' };

export const PIB_PREDUZECA_COLUMN = {
  width: 150,
  dataIndex: 'pib_preduzeca',
  title: i18n.t('PRESECI.PIB_PREDUZECA'),
  fixed: 'left',
};

export const PIB_COLUMN = { width: 150, dataIndex: 'pib', title: i18n.t('PRESECI.PIB_PREDUZECA'), fixed: 'left' };

export const MATICNI_BROJ_COLUMN = {
  title: `${i18n.t('PRESECI.MATICNI_BROJ_PREDUZECA')}`,
  dataIndex: 'maticni_broj',
  width: 200,
  key: 'maticni_broj',
};
export const MATICNI_BROJ_PREDUZECA_COLUMN = {
  title: `${i18n.t('PRESECI.MATICNI_BROJ_PREDUZECA')}`,
  dataIndex: 'maticni_broj_preduzeca',
  width: 200,
  key: 'maticni_broj_preduzeca',
};

export const NAZIV_PREDUZECA_COLUMN = {
  title: i18n.t('PRESECI.NAZIV_PREDUZECA'),
  dataIndex: 'naziv_preduzeca',
  width: 250,
  key: 'naziv_preduzeca',
};

export const ULICA_PREDUZECA_COLUMN = {
  title: `${i18n.t('PRESECI.ULICA_BROJ_PREDUZECA')}`,
  dataIndex: 'ulica_preduzeca',
  key: 'ulica_preduzeca',
  width: 200,
};

export const OPSTINA_PREDUZECA_COLUMN = {
  title: `${i18n.t('PRESECI.OPSTINA')}`,
  dataIndex: 'opstina_preduzeca',
  key: 'opstina_preduzeca',
  width: 200,
};
export const TELEFON_PREDUZECA_COLUMN = {
  title: `${i18n.t('PRESECI.TELEFON_PREDUZECA')}`,
  dataIndex: 'broj_preduzeca',
  key: 'broj_preduzeca',
  width: 200,
};
export const EMAIL_PREDUZECA_COLUMN = {
  title: `${i18n.t('PRESECI.EMAIL_PREDUZECA')}`,
  dataIndex: 'email_preduzeca',
  key: 'email_preduzeca',
  width: 200,
};

export const MESTO_PREDUZECA_COLUMN = {
  title: `${i18n.t('PRESECI.MESTO')}`,
  dataIndex: 'mesto_preduzeca',
  key: 'mesto_preduzeca',
  width: 200,
};

export const NAZIV_POSTROJENJA_COLUMN = {
  title: `${i18n.t('PRESECI.NAZIV_POSTROJENJA')}`,
  dataIndex: 'naziv_postrojenja',
  key: 'naziv_postrojenja',
  width: 200,
};
export const NACIONALNI_ID_POSTROJENJA_COLUMN = {
  title: `${i18n.t('PRESECI.NACIONALNI_ID')}`,
  dataIndex: 'nacionalni_id',
  key: 'nacionalni_id',
  width: 200,
};

export const OPSTINA_POSTROJENJA_COLUMN = {
  title: `${i18n.t('PRESECI.NAZIV_ISPUSTA')}`,
  dataIndex: 'opstina_postrojenja',
  key: 'opstina_postrojenja',
  width: '200px',
};

export const ZAVODNI_BROJ_DOPISA_COLUMN = {
  title: `${i18n.t('PRESECI.ZAVODNI_BROJ_DOPISA')}`,
  dataIndex: 'zavodni_broj_dopisa' as keyof IGetPtp2PreseciList,
  key: 'zavodni_broj_dopisa',
  width: 200,
};
