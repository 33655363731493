import { IErrors } from 'typescript/NrizTypes';
import { toast } from 'react-toastify';
import { authStore } from 'store';

export const handleErrorNotify = (error: string[] = []) => {
  toast.error(`${error}`, {
    position: 'top-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
};

export const errorHandling = (error: IErrors) => {
  let errorMessages: string[] = [];
  if (Array.isArray(error?.response?.data?.detail)) {
    errorMessages = error.response.data.detail.map(
      (element: { msg: string }) => `${element?.msg.charAt(0).toUpperCase() + element.msg.slice(1)}
    `
    );
  }
  if (typeof error?.response?.data?.detail === 'string') {
    errorMessages = error.response.data.detail;
  }

  const userExistsError = errorMessages?.includes('User exists with same username');

  const userExistsMessage = 'Već postoji korisnik sa ovim korisničkim imenom!';

  const unhandledErrorMessage = 'Došlo je do greške. Molim vas kontaktirajte administratora';

  // const invalidOwnershipMessage = 'Ovo nije vase preduzece!';

  // logoutMessages --> poruke koje stizu sa keycloaka kada je pozeljan logout(u slucaju neaktivnog tokena ili kada ne prodje refresh)
  // todo: proveriti poruke i da li se uopste citaju kako treba(token is not active, internal_grant), zbog drugacijeg interfejsa responsa
  const logoutMessages = [
    'Signature has expired',
    'Token is not active',
    // 'internal_grant',
    // 'Authorization header is expected',
  ];

  if (logoutMessages.some(message => errorMessages.includes(message))) return authStore.keycloak.logout();

  handleErrorNotify(
    errorMessages.length ? (userExistsError ? [userExistsMessage] : errorMessages) : [unhandledErrorMessage]
  );
};
