import i18n from 'translations/i18n.config';
import { IFacilityFilters, IPostFacility, ISurvey } from './facility.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { IS_ACTIVE_SWITCH } from 'modules/sifarnici/sifarniciFormObj.constants';

export const facilityFormInitialState: IPostFacility = {
  naziv_postrojenja: '',
  mesto_id: '',
  postanski_broj: '',
  ulica: '',
  kucni_broj: '',
  telefon: '',
  koordinate_lat: '',
  koordinate_long: '',
  prethodni_nacionalni_id: '',
  istorijski_id: '',
  nacionalni_id: '',
  ippc: false,
  napomena: '',
  is_active: false,
  preduzece_id: '',
};

export const surveyInitialState: ISurvey = {
  korak_1_pitanje_1: '',
  korak_1_pitanje_2: '',
  korak_1_pitanje_3: '',
  korak_1_pitanje_4: '',
  korak_1_pitanje_5: '',
  korak_1_pitanje_6: '',
  korak_2_pitanje_1: '',
  korak_2_pitanje_2: '',
  korak_3_pitanje_1: '',
  korak_4_pitanje_1: '',
  postrojenje: facilityFormInitialState,
  verifikacioni_kod: '',
  preduzece_id: '',
};

export const FACILITY_FILTERS_INITIAL_STATE: IFacilityFilters = {
  naziv_postrojenja__ilike: '',
  nacionalni_id: '',
  order_by: '',
};

export const FACILITY_FILTERS_OPTIONS: ISifarniciTypeOfForm[] = [
  {
    label: i18n.t('FACILITY.TITLE'),
    name: 'naziv_postrojenja__ilike',
    type_of_form: 'input',
    inputName: 'naziv_postrojenja__ilike',
    sortName: 'naziv_postrojenja',
  },
  {
    label: i18n.t('FACILITY.NACIONAL_ID'),
    name: 'nacionalni_id',
    type_of_form: 'input',
    inputName: 'nacionalni_id',
    sortName: 'nacionalni_id',
  },
  IS_ACTIVE_SWITCH,
];
