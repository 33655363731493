import i18n from 'translations/i18n.config';
import {
  IFiltersVelikiZagadjivaciPreseciList,
  IFiltersVelikiZagadjivaciSinglePresek,
  IGetVelikiZagadjivaciPreseciList,
  IGetVelikiZagadjivaciSinglePresek,
} from './velikiZagadjivaciPresek.types';
import { IPreseciColumnProps } from '../preseci.types';
import {
  BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS,
  BROJ_PREDUZECA_COLUMN,
  BROJ_ZAPISA_COLUMN,
  DATUM_SLANJA_COLUMN,
  GODINA_COLUMN,
  NACIONALNI_ID_POSTROJENJA_COLUMN,
  NAZIV_POSTROJENJA_COLUMN,
  NAZIV_PREDUZECA_COLUMN,
  OPSTINA_POSTROJENJA_COLUMN,
  PIB_COLUMN,
  STATUS_COLUMN,
} from '../preseciColumns.constants';
import { FormConfig } from 'modules/obrazacIzvestaji/obrazacIzvestajiFilters.constants';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import {
  DATUM_SLANJA_GTE_FILTER,
  DATUM_SLANJA_LTE_FILTER,
  GODINA_FILTER,
  NACIONALNI_ID_POSTROJENJA_FILTER,
  NAZIV_POSTROJENJA_FILTER,
  NAZIV_PREDUZECE_FILTER,
  PIB_FILTER,
} from '../preseciFilters.constants';
import { preseciStore } from '../preseci.store';

//#region LISTA P
export const PRESECI_VELIKI_ZAGADJIVACI_COLUMNS: IPreseciColumnProps<IGetVelikiZagadjivaciPreseciList>[] = [
  DATUM_SLANJA_COLUMN as IPreseciColumnProps<IGetVelikiZagadjivaciPreseciList>,
  BROJ_ZAPISA_COLUMN as IPreseciColumnProps<IGetVelikiZagadjivaciPreseciList>,
  BROJ_PREDUZECA_COLUMN as IPreseciColumnProps<IGetVelikiZagadjivaciPreseciList>,
  {
    width: 200,
    dataIndex: 'ukupna_kolicina_sumporni_oksidi',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_SUMPORNI_OKSIDI'),
  },
  { width: 200, dataIndex: 'ukupna_kolicina_azotni_oksidi', title: i18n.t('PRESECI.UKUPNA_KOLICINA_AZOTNI_OKSIDI') },
  {
    width: 200,
    dataIndex: 'ukupna_kolicina_suspendovane_cestice',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_SUSPENDOVANE_CESTICE'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_kolicina_praskaste_materije',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_UKUPNE_PRASKASTE_MATERIJE'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_godisnja_kolicina_proizvedenog_otpad',
    title: i18n.t('PRESECI.UKUPNA_GODISNJA_KOLICINA_OTPAD'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_kolicina_otpada_predata_skladistenje',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_OTPAD_SKLADISTENJE'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_kolicina_otpada_predata_odlaganje',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_OTPAD_ODLAGANJE'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_kolicina_otpada_predata_tretman',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_OTPAD_TRETMAN'),
  },
  {
    width: 200,
    dataIndex: 'ukupna_kolicina_izvezenog_otpada',
    title: i18n.t('PRESECI.UKUPNA_KOLICINA_IZVEZENI_OTPAD'),
  },
  ...(BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS as IPreseciColumnProps<IGetVelikiZagadjivaciPreseciList>[]),
];

export const VELIKI_ZAGADJIVACI_PRESECI_LIST_FILTERS_INITIAL_STATE: IFiltersVelikiZagadjivaciPreseciList = {
  datum_slanja__gte: '',
  datum_slanja__lte: '',
  obrazac_preduzece_id: '',
  nacionalni_id__ilike: '',
  postrojenje_id: '',
};

export const VELIKI_ZAGADJIVACI_PRESECI_LIST_FILTERS_OPTIONS: FormConfig = (form): ISifarniciTypeOfForm[] => [
  DATUM_SLANJA_GTE_FILTER,
  DATUM_SLANJA_LTE_FILTER,
  NAZIV_PREDUZECE_FILTER(form),
  NAZIV_POSTROJENJA_FILTER(form),
  NACIONALNI_ID_POSTROJENJA_FILTER({ ilike: true }),
];

//#endregion LISTA P

//#region PRESEK
// todo: prevod
export const VELIKI_ZAGADJIVACI_SEGMENT_OPTIONS: { label: string; value: '1' | '2' }[] = [
  { label: 'Emisije u vazduh po izvorima i zagađujućim materijama', value: '1' },
  { label: 'Količine otpada po postrojenjima', value: '2' },
];

export const SINGLE_PRESEK_VELIKI_ZAGADJIVACI_COLUMNS =
  (): IPreseciColumnProps<IGetVelikiZagadjivaciSinglePresek>[] => {
    const columns: IPreseciColumnProps<IGetVelikiZagadjivaciSinglePresek>[] =
      preseciStore.vrstaIzvestaja === '1'
        ? [
            {
              title: `${i18n.t('PRESECI.SOURCE_NAME')}`,
              dataIndex: 'naziv_izvora',
              key: 'naziv_izvora',
              width: '200px',
            },
            {
              title: `${i18n.t('PRESECI.SOURCE_NUMBER')}`,
              dataIndex: 'broj_izvora',
              key: 'broj_izvora',
              width: '200px',
            },
            {
              title: `${i18n.t('PRESECI.SUMPORNI_OKSIDI')}`,
              dataIndex: 'sumportni_oksidi',
              key: 'sumportni_oksidi',
              width: '200px',
            },
            {
              title: `${i18n.t('PRESECI.AZOTNI_OKSIDI')}`,
              dataIndex: 'azotni_oksidi',
              key: 'azotni_oksidi',
              width: '200px',
            },
            {
              title: `${i18n.t('PRESECI.SUSPENDOVANE_CESTICE')}`,
              dataIndex: 'suspendovane_cestice',
              key: 'suspendovane_cestice',
              width: '200px',
            },
            {
              title: `${i18n.t('PRESECI.UKUPNE_PRASKASTE_MATERIJE')}`,
              dataIndex: 'ukupne_praškaste_materije',
              key: 'ukupne_praškaste_materije',
              width: '200px',
            },
          ]
        : [
            {
              title: `${i18n.t('PRESECI.GODISNJA_KOLICINA_PROIZVEDENOG_OTPAD')}`,
              dataIndex: 'godisnja_kolicina_proizvedenog_otpad',
              key: 'godisnja_kolicina_proizvedenog_otpad',
              width: '200px',
            },
            {
              title: `${i18n.t('PRESECI.KOLICINA_OTPAD_SKLADISTENJE')}`,
              dataIndex: 'kolicina_otpada_predata_skladistenje',
              key: 'kolicina_otpada_predata_skladistenje',
              width: '200px',
            },
            {
              title: `${i18n.t('PRESECI.KOLICINA_OTPAD_ODLAGANJE')}`,
              dataIndex: 'kolicina_otpada_predata_odlaganje',
              key: 'kolicina_otpada_predata_odlaganje',
              width: '200px',
            },
            {
              title: `${i18n.t('PRESECI.KOLICINA_OTPAD_TRETMAN')}`,
              dataIndex: 'kolicina_otpada_predata_tretman',
              key: 'kolicina_otpada_predata_tretman',
              width: '200px',
            },
            {
              title: `${i18n.t('PRESECI.KOLICINA_IZVEZENOG_OTPAD')}`,
              dataIndex: 'kolicina_izvezenog_otpada',
              key: 'kolicina_izvezenog_otpada',
              width: '200px',
            },
          ];

    return [
      GODINA_COLUMN as IPreseciColumnProps<IGetVelikiZagadjivaciSinglePresek>,
      PIB_COLUMN as IPreseciColumnProps<IGetVelikiZagadjivaciSinglePresek>,
      NAZIV_PREDUZECA_COLUMN as IPreseciColumnProps<IGetVelikiZagadjivaciSinglePresek>,
      NACIONALNI_ID_POSTROJENJA_COLUMN as IPreseciColumnProps<IGetVelikiZagadjivaciSinglePresek>,
      NAZIV_POSTROJENJA_COLUMN as IPreseciColumnProps<IGetVelikiZagadjivaciSinglePresek>,
      OPSTINA_POSTROJENJA_COLUMN as IPreseciColumnProps<IGetVelikiZagadjivaciSinglePresek>,
      ...columns,
      STATUS_COLUMN as IPreseciColumnProps<IGetVelikiZagadjivaciSinglePresek>,
    ];
  };

export const VELIKI_ZAGADJIVACI_SINGLE_PRESEK_FILTER_INITIAL_STATE: IFiltersVelikiZagadjivaciSinglePresek = {
  pib__ilike: '',
  obrazac_preduzece_id: '',
  nacionalni_id__ilike: '',
  postrojenje_id: '',
  iskljucen: null,
  vrsta_izvestaja: '1',
  godina: '',
  naziv_izvora__ilike: '',
  broj_izvora__ilike: '',
};

export const VELIKI_ZAGADJIVACI_SINGLE_PRESEK_FILTERS_OPTIONS: FormConfig = (form): ISifarniciTypeOfForm[] => {
  return [
    GODINA_FILTER,
    PIB_FILTER({ ilike: true }),
    NAZIV_PREDUZECE_FILTER(form),
    NAZIV_POSTROJENJA_FILTER(form),
    NACIONALNI_ID_POSTROJENJA_FILTER({ ilike: true }),
    ...(preseciStore.vrstaIzvestaja === '1'
      ? ([
          {
            type_of_form: 'input',
            label: i18n.t('PRESECI.SOURCE_NAME'),
            name: 'naziv_izvora__ilike',
            inputName: 'naziv_izvora__ilike',
          },
          {
            type_of_form: 'input',
            label: i18n.t('PRESECI.SOURCE_NUMBER'),
            name: 'broj_izvora__ilike',
            inputName: 'broj_izvora__ilike',
          },
        ] as ISifarniciTypeOfForm[])
      : []),
  ];
};

//#endregion PRESEK
