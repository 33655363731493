import { UpOutlined, DownOutlined } from '@ant-design/icons';
import React, { useState } from 'react';
import styles from './SortByButton.module.scss';
import { FormInstance } from 'antd';

interface ISortByProps {
  form: FormInstance<any>;
  name: string;
  onChange?: (e: any) => any;
}

export const SortByButton: React.FC<ISortByProps> = props => {
  const [state, setState] = useState(1);

  const onSortChange = () => {
    let newState = 0;
    if (state === 1) newState = 2;
    if (state === 2) newState = 3;
    if (state === 3) newState = 1;

    setState(newState);
    const strOrderBy = props.form.getFieldValue('order_by') ?? '';
    const orderByArray = strOrderBy.split(',').filter((el: string) => el !== '');
    const existingName = state === 1 ? props.name : state === 2 ? `+${props.name}` : `-${props.name}`;
    const indexOf = orderByArray.indexOf(existingName);

    if (newState === 1) {
      orderByArray.splice(indexOf, 1);
    } else if (newState === 2) {
      orderByArray.push(`+${props.name}`);
    } else {
      orderByArray[indexOf] = `-${props.name}`;
    }

    props.form.setFieldValue('order_by', orderByArray.join());
  };

  return (
    <div className={`${styles.wrapper} ${state !== 1 ? styles.active : styles.inactive}`} onClick={onSortChange}>
      {state !== 2 ? <UpOutlined width={'1px'} /> : <div style={{ height: '13px' }}></div>}
      {state !== 3 ? <DownOutlined width={'1px'} /> : <div style={{ height: '13px' }}></div>}
    </div>
  );
};
