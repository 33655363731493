import { Row, Col } from 'antd';
import { Button } from 'components/Button/Button';
import { ExcelButton } from 'components/Buttons/ExcelButton/ExcelButton';
import { PRESECI_TYPE_OPTIONS, PreseciTypeEnum } from 'modules/preseci/preseci.constants';
import { preseciStore } from 'modules/preseci/preseci.store';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import { modalStore } from 'store';

interface ISinglePresekHeader {
  presekType: PreseciTypeEnum;
  datumPreseka: string;
}

export const HeaderSinglePresek: FC<ISinglePresekHeader> = ({ presekType, datumPreseka }) => {
  const navigate = useNavigate();

  const odustaniOdIzvestaja = async () => {
    const odustani = await preseciStore.deleteSinglePresek(presekType);
    if (odustani) navigate(`${RoutePathEnum.LISTA_PRESEKA}/${presekType}`);
  };

  const checkIfPresekIsValid = () => {
    preseciStore.checkIfSinglePresekListIsValidForSending(presekType);
  };

  const handleExportExcel = () => {
    if (datumPreseka) {
      return preseciStore.exportExistingSinglePresekListExcel(presekType, datumPreseka);
    }
    preseciStore.exportSinglePresekExcel(presekType);
  };

  const presekHeader = PRESECI_TYPE_OPTIONS.find(option => option.value === presekType)?.label;

  // todo: prevod
  return (
    <Row justify={'space-between'} id="singlePresekHeaderId">
      <h1>{presekHeader}</h1>
      <Row gutter={16}>
        {!datumPreseka && (
          <>
            <Col>
              <Button name={'posalji-izvestaj'} onClick={checkIfPresekIsValid} disabled={modalStore.isLoading}>
                Pošalji izveštaj
              </Button>
            </Col>
            <Col>
              <Button name={'odustani-izvestaj'} onClick={odustaniOdIzvestaja} danger>
                Odustani od kreiranja izveštaja
              </Button>
            </Col>
          </>
        )}
        <Col>
          <ExcelButton name="preseci" onClick={handleExportExcel} />
        </Col>
      </Row>
    </Row>
  );
};
