/**
 * Hook to manage and check the state of different preseci based on their type.
 * This hook uses a reducer to handle the state of preseci, provides a function to handle type changes,
 * and fetches the current presek state and additional checks on load or type change.
 *
 * @module usePresekCheck
 */

import { PreseciTypeEnum } from 'modules/preseci/preseci.constants';
import { preseciStore } from 'modules/preseci/preseci.store';
import { useEffect, useReducer } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RoutePathEnum } from 'router/router.constants';
import { filtersStore, FiltersNameEnum } from 'store/filteri.store';
import { isEnumValue } from 'utils/isEnumValue';

/**
 * Type defining the state for each presek.
 * - `isPresekActive`: Whether the presek is currently active.
 * - `additionalPresekInfo`: Object containing additional state information for the presek.
 */
export type PresekCheckState = Record<
  PreseciTypeEnum,
  { isPresekActive: boolean; additionalPresekInfo: Record<string, any> }
>;

/**
 * Type defining the actions for the presek state reducer.
 */
export type PresekStateAction =
  | { type: 'SET_ACTIVE'; presek: PreseciTypeEnum; isPresekActive: boolean }
  | { type: 'UPDATE_PRESEK_INFO'; presek: PreseciTypeEnum; checkKey: string; value: any }
  | { type: 'RESET'; presek: PreseciTypeEnum };

/**
 * Initial state for all preseci.
 */
const PRESEK_CHECK_INITIAL_STATE: PresekCheckState = {
  [PreseciTypeEnum.AAO1]: {
    isPresekActive: false,
    additionalPresekInfo: {},
  },
  [PreseciTypeEnum.AAO2]: { isPresekActive: false, additionalPresekInfo: {} },
  [PreseciTypeEnum.PTP2]: {
    isPresekActive: false,
    additionalPresekInfo: { ukljucenaGodina: false },
  },
  [PreseciTypeEnum.PZV]: { isPresekActive: false, additionalPresekInfo: {} },
  [PreseciTypeEnum.VELIKI_ZAGADJIVACI]: { isPresekActive: false, additionalPresekInfo: {} },
  [PreseciTypeEnum.OTV]: {
    isPresekActive: false,
    additionalPresekInfo: {
      godinaIzvestaja: null,
    },
  },
};

/**
 * Reducer function to manage the state of preseci.
 *
 * @param state - Current state of preseci.
 * @param action - Action to update the state.
 * @returns Updated state based on the action.
 */
const presekCheckReducer = (state: PresekCheckState, action: PresekStateAction) => {
  switch (action.type) {
    case 'SET_ACTIVE':
      return {
        ...state,
        [action.presek]: {
          ...state[action.presek],
          isPresekActive: action.isPresekActive,
        },
      };
    case 'UPDATE_PRESEK_INFO':
      return {
        ...state,
        [action.presek]: {
          ...state[action.presek],
          additionalPresekInfo: {
            ...state[action.presek]?.additionalPresekInfo,
            [action.checkKey]: action.value,
          },
        },
      };
    case 'RESET':
      return {
        ...state,
        [action.presek]: {
          isPresekActive: false,
          additionalPresekInfo: {},
        },
      };
    default:
      return state;
  }
};

/**
 * Hook for managing and checking the state of preseci (entities) based on their type.
 *
 * @returns {Object} - Returns the state and utility functions for managing preseci.
 * @property {PresekCheckState} presekState - The current state of all preseci types.
 * @property {function(string): void} handlePresekTypeChange - Changes the current presek type and updates the URL parameter.
 * @property {PreseciTypeEnum} presekType - The current selected presek type from URL parameters.
 * @property {function(PreseciTypeEnum, string, any): void} updatePresekInfo - Updates specific additional info for a presek type.
 */
export function usePresekCheck() {
  const { presekType: initialPresekType } = useParams();
  const navigate = useNavigate();

  const presekIsInEnum = !!initialPresekType && isEnumValue(initialPresekType, Object.values(PreseciTypeEnum));

  useEffect(() => {
    if (!presekIsInEnum) navigate(`${RoutePathEnum.LISTA_PRESEKA}/${PreseciTypeEnum.AAO1}`);
  }, []);

  const presekType = presekIsInEnum ? initialPresekType : PreseciTypeEnum.AAO1;

  const [state, dispatch] = useReducer(presekCheckReducer, PRESEK_CHECK_INITIAL_STATE);

  /**
   * Fetches the state of the current presek and updates the state accordingly.
   * Includes additional checks for specific preseci when required.
   *
   * @param presekType - The type of presek to check.
   */

  const checkPresekStates = async (presekType: PreseciTypeEnum) => {
    // possibly make these additional checks dynamic
    try {
      const preseciList = await preseciStore.fetchSinglePresekList(presekType);
      dispatch({ type: 'SET_ACTIVE', presek: presekType, isPresekActive: !!preseciList.items.length });
    } catch (error) {
      console.error(error);
      return dispatch({ type: 'RESET', presek: presekType });
    }

    if (presekType === PreseciTypeEnum.PTP2 && !state[PreseciTypeEnum.PTP2].isPresekActive) {
      const includeYear = await preseciStore.checkIfYearIncluded(presekType);
      dispatch({
        type: 'UPDATE_PRESEK_INFO',
        presek: PreseciTypeEnum.PTP2,
        checkKey: 'ukljucenaGodina',
        value: includeYear,
      });
    }
  };

  useEffect(() => {
    if (presekType) checkPresekStates(presekType);
  }, [presekType]);

  /**
   * Updates the current presek type and navigates to the corresponding route.
   *
   * @param newType - The new presek type.
   */
  const handlePresekTypeChange = (newType: PreseciTypeEnum) => {
    preseciStore.clearPreseci();
    filtersStore.setFilters(FiltersNameEnum.LISTA_PRESEKA, {});
    dispatch({ type: 'RESET', presek: presekType });
    navigate(`${RoutePathEnum.LISTA_PRESEKA}/${newType}`);
  };

  /**
   * Dispatch function to manually update additional information for a presek.
   *
   * @param presek - The type of presek to update.
   * @param checkKey - The key of the additional information to update.
   * @param value - The value to set for the specified key.
   */
  const updatePresekInfo = (presek: PreseciTypeEnum, checkKey: string, value: any) =>
    dispatch({ type: 'UPDATE_PRESEK_INFO', presek, checkKey, value });

  return { presekState: state, handlePresekTypeChange, presekType, updatePresekInfo };
}
