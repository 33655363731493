import { FormInstance } from 'antd';

import { EvropskiIzvestajTypeEnum } from './evropskiIzvestaji.types';
import { LCPStatus } from '../registri/lcp/lcp.types';
import {
  ISifarniciTypeOfForm,
  SIFARNICI_ROUTES,
  SearchScrollSifarniciFormProps,
  SelectSifarniciFormProps,
} from 'modules/sifarnici/sifarnici.types';

export type FormConfig = (form: FormInstance) => ISifarniciTypeOfForm[];

const STATUS_POSTROJENJA_FILTER: SelectSifarniciFormProps = {
  type_of_form: 'select',
  name: 'status',
  label: 'Status postrojenja',
  placeholder: 'Izaberite status',
  options: [
    {
      label: 'Aktivno postrojenje',
      value: LCPStatus.functional,
    },
    {
      label: 'Nedostaje Obrazac 1',
      value: LCPStatus.disused,
    },
    {
      label: 'Zatvoreno postrojenje',
      value: LCPStatus.decomissioned,
    },
    {
      label: 'Promena aktivnosti',
      value: LCPStatus.notRegulater,
    },
  ],
};

const PREDUZECE_FUNC_FILTER = (label: string, formName: string, field: string): SearchScrollSifarniciFormProps => ({
  type_of_form: 'searchScroll',
  name: formName,
  formName: formName,
  label,
  labelOptionAccessor: [field],
  valueOptionAccessor: field,
  fetchOptinsList: SIFARNICI_ROUTES.PREDUZECE,
  placeholder: label,
});

const POSTROJENJE_FUNC_FILTER = (label: string, formName: string, field: string): SearchScrollSifarniciFormProps => ({
  type_of_form: 'searchScroll',
  name: formName,
  formName: formName,
  label,
  labelOptionAccessor: [field],
  valueOptionAccessor: field,
  fetchOptinsList: SIFARNICI_ROUTES.POSTROJENJE,
  placeholder: label,
});

export const EU_REGISTAR_FILTERS: FormConfig = () => [
  PREDUZECE_FUNC_FILTER('Pib preduzeća', 'pib_id', 'pib'),
  PREDUZECE_FUNC_FILTER('Matični broj preduzeća', 'maticni_broj_id', 'maticni_broj'),
  PREDUZECE_FUNC_FILTER('Naziv preduzeća', 'naziv_preduzeca_id', 'naziv'),
  POSTROJENJE_FUNC_FILTER('Naziv postrojenja', 'naziv_postrojenja_id', 'naziv_postrojenja'),
  POSTROJENJE_FUNC_FILTER('Istorijski ID postrojenja', 'istorijski_id', 'istorijski_id'),
  POSTROJENJE_FUNC_FILTER('Nacionalni ID postrojenja', 'nacionalni_id', 'nacionalni_id'),
  STATUS_POSTROJENJA_FILTER,
];

export const EU_INTEGRATED_REPORT_FILTERS: FormConfig = () => [];

export const EVROPSKI_IZVESTAJI_FILTERS_MAP: {
  [key in EvropskiIzvestajTypeEnum]: (form: FormInstance) => ISifarniciTypeOfForm[];
} = {
  [EvropskiIzvestajTypeEnum.EU_REGISTAR]: EU_REGISTAR_FILTERS,
  [EvropskiIzvestajTypeEnum.EU_INTEGRATED_REPORT]: EU_INTEGRATED_REPORT_FILTERS,
};
