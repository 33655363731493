import { makeAutoObservable } from 'mobx';
import { operateriService } from './operateri.service';
import to from 'await-to-js';
import { IOperaterOtpadaRequest, IOperaterOtpadaResponse } from './operateri.types';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { ITablePagination } from 'components/TableData/TableData';
import { IPagination } from 'typescript';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { IPaginatedResponse } from 'typescript/NrizTypes';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { nrizService } from 'modules/nriz/nriz.service';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';

class OperateriStore {
  operateriList: IOperaterOtpadaResponse[] = [];
  operateriPagination: IPagination = paginationInitialState;
  selectedOperater: Partial<IOperaterOtpadaResponse> = {};

  constructor() {
    makeAutoObservable(this, {});
  }

  setOperateriList = (newList: IOperaterOtpadaResponse[]) => {
    this.operateriList = newList;
  };

  setSelectedOperater = (operater: Partial<IOperaterOtpadaResponse>) => {
    this.selectedOperater = operater;
  };

  fetchOperateriList = async (pagination?: ITablePagination) => {
    const { page, size } = pagination ?? this.operateriPagination;
    const [err, res] = await to(
      operateriService.fetchOperateriList({
        page,
        size,
        ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.DOZVOLE_OPERATERI]),
      })
    );

    if (err || !res) return Promise.reject([]);

    this.setOperateriList(res.items);
    return res.items;
  };

  createOperater = async (payload: IOperaterOtpadaRequest) => {
    const [err, res] = await to(operateriService.createOperater(payload));

    if (err || !res) return Promise.reject(err);
    return res;
  };

  updateOperaterInfo = async (payload: Partial<IOperaterOtpadaResponse>) => {
    if (!payload.id) return Promise.reject(new Error('No id provided!'));
    const [err, res] = await to(operateriService.updateOperaterInfo(payload.id, payload));

    if (err || !res) return Promise.reject(err);
  };

  fetchOperaterById = async (id: string) => {
    const [err, res] = await to(operateriService.fetchOperaterById(id));

    if (err || !res) return Promise.reject(err);
    this.setSelectedOperater(res);
    return res;
  };

  deleteOperater = async (id: string) => {
    const [err, res] = await to(operateriService.deleteOperater(id));

    if (err || !res) return Promise.reject(err);
    this.fetchOperateriList();
  };

  fetchOpstina = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };
}

export const operateriStore = new OperateriStore();
