import { IPagination, ListTableProps } from 'typescript';
import { IGetCompany } from '../company/company.types';
import { IGetFacility } from '../facility/facility.types';

export interface IUserListProps extends ListTableProps {
  isFacility: boolean;
  storePagination: IPagination;
}

export interface ResponseUserList extends IPagination {
  items: IGetUser[];
}

export interface RequestUserFacilityList extends Partial<IPagination> {
  postrojenje_id: string;
}

export interface KeycloakUserInfo {
  groups?: [UserRoles] | [UserRoles, UserRoles];
  jezik?: UserLanguages;
  internal_user_id?: string;
}

export enum UserRoles {
  SuperAdmin = '/super-admin',
  SystemAdmin = '/sistem-admin',
  CompanyOwner = '/company-owner',
  CompanyUser = '/company-user',
  FacilityUser = '/facility-user',
  ObradaDozvola = '/obrada-dozvola',
}

export const AdminRoles = [UserRoles.SuperAdmin, UserRoles.SystemAdmin];

export const CompanyRoles = [UserRoles.CompanyOwner, UserRoles.CompanyUser, UserRoles.FacilityUser];

export const ObradaDozvolaRoles = [UserRoles.ObradaDozvola];

export enum UserLanguages {
  SRPSKI = 'srpski',
  ENGLESKI = 'engleski',
}

// /////////////////////////////////////

export interface IPostUser {
  email: string;
  ime: string;
  username: string;
  prezime: string;
  jezik: string;
  broj_telefon: string;
  preduzece_id?: string;
  function_of_person?: string;
  alternativni_telefon?: string;
  company_user: boolean;
  facility_user: boolean;
  postrojenje_id_list?: string[];
}

export interface IGetUser extends IPostUser, IPostUserResponsibility {
  id: string;
  external_id: string;
  preduzece?: IGetCompany;
  postrojenje_id: string;
  postrojenja?: IGetFacility[];
  sistem_administrator: boolean;
  preduzece_kp: PreduzeceKp[];
  is_active: boolean;
  odgovorno_lice: boolean;
  main_company_user?: boolean;
}

export interface PreduzeceKp {
  preduzece_id: string;
  korisnik_id: string;
  preduzece: IGetCompany;
}

export interface IPostUserResponsibility {
  odgovorna_osoba_preduzeca: boolean;
  odgovorna_osoba_za_izvestavanje: boolean;
  odgovorna_osoba_za_saradnju_sa_agencijom: boolean;
}

export enum UserFormType {
  UserRegistration = 'user-registration',
  UserCompany = 'user-company',
  UserAdministrator = 'user-administrator',
  UserFacility = 'user-facility',
}

export enum AddFacilityUsers {
  Existing = 1,
  New,
}

export interface IUserFilters {
  sistem_administrator: boolean | null;
  ime?: string;
  prezime?: string;
  username?: string;
  ime__ilike?: string;
  prezime__ilike?: string;
  username__ilike?: string;
  preduzece__pib__ilike: string;
  preduzece__maticni_broj__ilike: string;
  preduzece__naziv__ilike: string;
  order_by: string;
  is_active: boolean | null;
}
