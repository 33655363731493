import { FC, useEffect, useMemo } from 'react';

import { observer } from 'mobx-react-lite';
import { useForm } from 'antd/es/form/Form';
import { Form } from 'antd';

import { FilterTableForm } from 'components/TableData/FilterTableForm/FilterTableForm';

import { EvropskiIzvestajTypeEnum } from 'modules/evropskiIzvestaji/evropskiIzvestaji.types';
import { IFilterTableProps } from 'typescript/NrizTypes';
import { EVROPSKI_IZVESTAJI_FILTERS_MAP } from 'modules/evropskiIzvestaji/evropskiIzvestajiFilters.constants';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { evropskiIzvestajiStore } from 'modules/evropskiIzvestaji/evropskiIzvestaji.store';

export interface IObrazacIzvestajiFiltersParams {
  izvestajType: EvropskiIzvestajTypeEnum | undefined;
}

export const EvropskiIzvestajiFilters: FC<IObrazacIzvestajiFiltersParams> = observer(({ izvestajType }) => {
  const [izvestajiForm] = useForm();

  const filtersByIzvestajType = useMemo(() => {
    return izvestajType ? EVROPSKI_IZVESTAJI_FILTERS_MAP[izvestajType](izvestajiForm) : [];
  }, [Form.useWatch([], izvestajiForm), izvestajType]);

  useEffect(() => {
    izvestajiForm.resetFields();
  }, [izvestajType]);

  const fetchIzvestaji = () => {
    return evropskiIzvestajiStore.getEvropskiIzvestaj(
      izvestajType as EvropskiIzvestajTypeEnum,
      new Date().getFullYear() - 1,
      paginationInitialState
    );
  };

  const filterConfig: IFilterTableProps = {
    form: izvestajiForm,
    initialState: {},
    filterStoreName: 'evropskiIzvestaji',
    resetForm: izvestajType,
    filterOptions: filtersByIzvestajType,
    fetchTableList: fetchIzvestaji,
  } as IFilterTableProps;

  return (
    <div id="evropskiIzvestajFilters">
      <FilterTableForm data={filterConfig} />
    </div>
  );
});
