import { observer } from 'mobx-react-lite';
import { PreseciTypeEnum, SINGLE_PRESEK_LIST_COLUMN_MAP } from 'modules/preseci/preseci.constants';
import { FC, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FiltersSinglePresek } from './FiltersSinglePresek/FiltersSinglePresek';
import TableData, { ITablePagination } from 'components/TableData/TableData';
import { preseciStore } from 'modules/preseci/preseci.store';
import { Content } from 'components/Content/Content';
import { RoutePathEnum } from 'router/router.constants';
import { ISKLJUCI_PTP_PRIJAVU_COLUMN, ISKLJUCI_UKLJUCI_PRESEK_COLUMN } from 'modules/preseci/preseciColumns.constants';
import dayjs from 'dayjs';
import { HeaderSinglePresek } from './HeaderSinglePresek/HeaderSinglePresek';
import { AdditionalButtonsSinglePresek } from './AdditionalButtonsSinglePresek/AdditionalButtonsSinglePresek';
import { ModalUkljuciIskljuciPreseciKeys } from 'modules/preseci/preseci.types';
import { ModalUkljuciIskljuciPoFilterimaSinglePresek } from './ModalUkljuciIskljuciPoFilterimaSinglePresek/ModalUkljuciIskljuciPoFilterimaSinglePresek';
import { isEnumValue } from 'utils/isEnumValue';
import { ModalPosaljiIzvestaj } from './ModalPosaljiIzvestaj/ModalPosaljiIzvestaj';
import { Ptp2IskljucivanjeModal } from './Ptp2IskljucivanjeModal/Ptp2IskljucivanjeModal';
import { ModalPtp2PosaljiIzvestaj } from './ModalPosaljiIzvestaj/ModalPtp2PosaljiIzvestaj';

export const SinglePresek: FC = observer(() => {
  const [searchParams] = useSearchParams();
  const { presekType: initialPresekType } = useParams();
  const navigate = useNavigate();

  const presekIsInEnum = !!initialPresekType && isEnumValue(initialPresekType, Object.values(PreseciTypeEnum));

  useEffect(() => {
    if (!presekIsInEnum) navigate(`${RoutePathEnum.LISTA_PRESEKA}/${PreseciTypeEnum.AAO1}`);
  }, []);

  const presekType = presekIsInEnum ? initialPresekType : PreseciTypeEnum.AAO1;

  const datumPreseka = searchParams.get('datum_preseka') as string;

  const columnsBySinglePresekType = [
    ...SINGLE_PRESEK_LIST_COLUMN_MAP[presekType](),
    ...(presekType === PreseciTypeEnum.PTP2
      ? [ISKLJUCI_PTP_PRIJAVU_COLUMN]
      : [ISKLJUCI_UKLJUCI_PRESEK_COLUMN(presekType, datumPreseka)]),
  ];

  const fetchPresekList = (pagination?: ITablePagination) => {
    if (datumPreseka) return preseciStore.fetchExistingSinglePresekList(presekType, datumPreseka, pagination);
    return preseciStore.fetchSinglePresekList(presekType, pagination);
  };

  useEffect(() => {
    fetchPresekList();
  }, []);

  return (
    <Content
      backBtn={{
        name: 'lista-preseka',
        route: `${RoutePathEnum.LISTA_PRESEKA}/${presekType}`,
        label: 'Lista preseka',
        rightComponent: (
          <div>
            {datumPreseka && (
              <label style={{ fontWeight: 'bold' }}>{`Datum preseka / ${dayjs(datumPreseka).format(
                'DD-MM-YYYY'
              )}`}</label>
            )}
          </div>
        ),
      }}
      header={<HeaderSinglePresek presekType={presekType} datumPreseka={datumPreseka} />}
    >
      <FiltersSinglePresek presekType={presekType} fetchPresekList={fetchPresekList} />

      <AdditionalButtonsSinglePresek
        presekType={presekType}
        datumPreseka={datumPreseka}
        fetchPresekList={fetchPresekList}
      />

      <TableData
        subtractTableHeight={30}
        scrollConfig={['#singlePresekHeaderId', '#contentHeader', '#additionalButtons']}
        name="preseci"
        columns={columnsBySinglePresekType}
        dataSource={preseciStore.singlePresekList}
        refetchData={fetchPresekList}
        storePagination={preseciStore.singlePresekPagination}
      />
      <Ptp2IskljucivanjeModal />
      <ModalUkljuciIskljuciPoFilterimaSinglePresek presekType={presekType as ModalUkljuciIskljuciPreseciKeys} />
      <ModalPosaljiIzvestaj presekType={presekType} />
      <ModalPtp2PosaljiIzvestaj />
    </Content>
  );
});
