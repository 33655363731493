import { FormTextArea } from 'components/FormTextArea/FormTextArea';
import { observer } from 'mobx-react-lite';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import { useTranslation } from 'react-i18next';
import styles from './DkoNapomenaWizardTab.module.scss';
import { Col } from 'antd';
import { authStore } from 'store';
import { obrasciStore } from 'modules/obrasci/obrasci.store';
import { dkoObrazacStore } from 'modules/obrasci/store/dko/dko.store';
import { IGetDkoObrazac } from 'modules/obrasci/store/dko/dko.types';

export const DkoNapomenaWizardTab: React.FC = observer(() => {
  const { t } = useTranslation();
  return (
    <Col span={24} className={styles.napomena}>
      <FormTextArea
        label={t('WIZARD_TABS.NAPOMENA')}
        disabled={
          obrasciStore.zakljucanObrazac &&
          ((dkoObrazacStore.initialState as IGetDkoObrazac).odjava_kretanja ||
            (dkoObrazacStore.initialState as IGetDkoObrazac).potvrda_dokumenta ||
            (dkoObrazacStore.initialState as IGetDkoObrazac).admin_odjava_dokumenta)
        }
        name="napomena"
        rows={6}
        labelCol={5}
        wrapperCol={19}
        rules={[MAX_LENGTH_RULE(3000)]}
      />
      {authStore.isAdmin && (
        <FormTextArea
          label={t('WIZARD_TABS.NAPOMENA_ADMINISTRATORA')}
          name="admin_napomena"
          rows={6}
          labelCol={5}
          wrapperCol={19}
          rules={[MAX_LENGTH_RULE(3000)]}
        />
      )}
    </Col>
  );
});
