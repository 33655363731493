import i18n from 'translations/i18n.config';
import { toast, ToastPosition } from 'react-toastify';
export const handleSuccessNotify = (message: string | null = null, position: ToastPosition = 'bottom-right') => {
  toast.success(message || `${i18n.t('SUCCESS')}`, {
    position,
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: 'light',
  });
};
