import { Form, message } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { observer } from 'mobx-react-lite';
import { otpadStore } from 'modules/registarDozvola/otpad/otpad.store';
import { IOtpadRequest } from 'modules/registarDozvola/otpad/otpad.types';
import { useParams } from 'react-router-dom';
import { modalStore } from 'store';
import { OtpadDozvolaForm } from './OtpadDozvolaForm';
import { Button } from 'components/Button/Button';
import { OTPAD_INITIAL_STATE } from 'modules/registarDozvola/otpad/otpad.constants';
import { Modal } from 'components/Modal/Modal';
import { Popconfirm } from 'components/Popconfirm/Popconfirm';

export const OtpadDozvolaModal = observer(() => {
  const [form] = useForm<IOtpadRequest>();

  const { idDozvole } = useParams();

  const handleFormSubmit = async () => {
    if (!idDozvole) throw new Error('Dozvola ID missing!');

    const validValues: IOtpadRequest = { ...form.getFieldsValue(true), dozvola_id: idDozvole };
    try {
      if (otpadStore.selectedOtpad?.id) {
        const payload = { ...validValues, id: otpadStore.selectedOtpad.id };
        await otpadStore.updateOtpadInfo(payload);
        message.success('Uspešno izmenjen operater!');
      } else {
        await otpadStore.createOtpad(validValues);
        message.success('Uspešno kreiran operater!');
        modalStore.clearModal('dozvole-otpad-modal');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Modal
      name="otpad"
      open={modalStore.getCurrentOpenedModal.includes('dozvole-otpad-modal')}
      destroyOnClose
      onCancel={() => modalStore.clearModal('dozvole-otpad-modal')}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
      width={'70%'}
      title={'Podaci o otpadu'}
    >
      <Form<IOtpadRequest>
        form={form}
        preserve={false}
        onFinish={handleFormSubmit}
        initialValues={otpadStore.selectedOtpad ?? OTPAD_INITIAL_STATE}
        labelAlign="left"
        labelCol={{ span: 10 }}
        wrapperCol={{ span: 14 }}
      >
        <OtpadDozvolaForm />
        <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '10px', marginTop: '10px' }}>
          <Button name="otpad-dozvola-submit-btn" htmlType="submit">
            Sačuvaj
          </Button>
          {otpadStore.selectedOtpad && (
            <Popconfirm
              name="delete-dozvola-otpad-popconfirm"
              title={'Da li ste sigurni da želite da izbrišete otpad?'}
              okText={'Obriši'}
              okButtonProps={{ disabled: false }}
              cancelButtonProps={{ disabled: false }}
              cancelText={'Odbaci'}
              onConfirm={() => {
                if (!idDozvole) throw new Error('No dozvola ID available!');
                otpadStore.deleteOtpad(idDozvole);
              }}
              placement="bottom"
            >
              <Button name="otpad-dozvola-delete-btn" htmlType="button" danger>
                Obriši
              </Button>
            </Popconfirm>
          )}
        </div>
      </Form>
    </Modal>
  );
});
