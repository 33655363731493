import { Content } from 'components/Content/Content';
import TableData from 'components/TableData/TableData';
import { observer } from 'mobx-react-lite';
import { PREGLED_DOZVOLA_COLUMNS } from 'modules/registarDozvola/pregled/pregledDozvola.constants';
import { pregledDozvolaStore } from 'modules/registarDozvola/pregled/pregledDozvola.store';
import { useEffect } from 'react';
import { PregledDozvolaFilters } from './PregledDozvolaFilters/PregledDozvolaFilters';
import { Segmented } from 'antd';
import { PregledDozvolaType } from 'modules/registarDozvola/dozvole/dozvole.types';
import { SegmentedLabeledOption, SegmentedValue } from 'antd/es/segmented';
import styles from './PregledDozvola.module.scss';

export const PregledDozvola = observer(() => {
  useEffect(() => {
    pregledDozvolaStore.fetchDozvoleList();
  }, []);

  const segmentedOptions: SegmentedLabeledOption[] = [
    {
      // icon: <LockOutlined />,
      label: 'Pregled po vrsti dozvole',
      value: PregledDozvolaType.VrstaDozvole,
    },
    {
      // icon: <UnlockOutlined />,
      label: 'Pregled po vrsti dozvole i indeksnom broju otpada',
      value: PregledDozvolaType.IndeksniBroj,
    },
  ];

  const onSegmentedChange = (value: SegmentedValue) => {
    pregledDozvolaStore.setPregledDozvolaType(value as PregledDozvolaType);
  };

  const PregledDozvolaHeader = () => {
    return (
      <div className={styles.header}>
        <h2>Pregled Dozvola</h2>
        <Segmented
          options={segmentedOptions}
          onChange={onSegmentedChange}
          defaultValue={pregledDozvolaStore.pregledDozvolaType}
        />
      </div>
    );
  };

  return (
    <Content header={<PregledDozvolaHeader />}>
      <PregledDozvolaFilters />
      <TableData
        columns={PREGLED_DOZVOLA_COLUMNS(pregledDozvolaStore.pregledDozvolaType)}
        dataSource={pregledDozvolaStore.dozvoleList}
        name="pregled-dozvola-table"
        refetchData={pregledDozvolaStore.fetchDozvoleList}
        storePagination={pregledDozvolaStore.pregledDozvolaPagination}
        scrollConfig={['#obrasciFilters']}
      />
    </Content>
  );
});
