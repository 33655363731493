import { observer } from 'mobx-react-lite';
import { companyStore } from 'modules/company/company.store';
import { UserRoles } from 'modules/user/user.types';
import { PropsWithChildren, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { authStore } from 'store';
import { KoraciRegistracijeKorisnika } from 'modules/company/company.constants';
import { IGetCompany } from 'modules/company/company.types';
import { rolesForCompanyCheck } from 'store/AuthStore';
import { RoutePathEnum } from './router.constants';

type RouteGuardProps = {
  allowedRoles: UserRoles[];
} & PropsWithChildren;

const RouteGuard = observer(({ allowedRoles, children }: RouteGuardProps) => {
  const navigate = useNavigate();

  const { pathname } = useLocation();

  const REDIRECT_ROUTES: { [key: string | UserRoles]: string } = {
    [UserRoles.SuperAdmin]: RoutePathEnum.LISTA_PREDUZECA,
    [UserRoles.SystemAdmin]: RoutePathEnum.LISTA_PREDUZECA,
    [UserRoles.ObradaDozvola]: `${RoutePathEnum.REGISTAR_DOZVOLA}/obrada/`,
    [UserRoles.CompanyOwner]: (companyStore.company as IGetCompany)?.id
      ? `${RoutePathEnum.PREDUZECE}/${(companyStore.company as IGetCompany)?.id}`
      : RoutePathEnum.POCETNA_STRANA_KORISNIK,
    [UserRoles.CompanyUser]: (companyStore.company as IGetCompany)?.id
      ? `${RoutePathEnum.PREDUZECE}/${(companyStore.company as IGetCompany)?.id}`
      : RoutePathEnum.POCETNA_STRANA_KORISNIK,
    [UserRoles.FacilityUser]: (companyStore.company as IGetCompany)?.id
      ? `${RoutePathEnum.PREDUZECE}/${(companyStore.company as IGetCompany)?.id}`
      : RoutePathEnum.POCETNA_STRANA_KORISNIK,
  };

  const roleArray = authStore.roles;

  const startingRegistration = !roleArray.length && pathname === RoutePathEnum.REGISTRACIJA;

  const hasPermissions = roleArray.length && allowedRoles.some(role => roleArray.includes(role));

  useEffect(() => {
    if (roleArray.length && rolesForCompanyCheck.some(role => roleArray.includes(role)) && companyStore.getPageToShow) {
      if (companyStore.getPageToShow !== KoraciRegistracijeKorisnika.HOME)
        navigate(RoutePathEnum.POCETNA_STRANA_KORISNIK);
      else if (pathname.includes(RoutePathEnum.POCETNA_STRANA_KORISNIK))
        navigate(`${RoutePathEnum.PREDUZECE}/${(companyStore.company as IGetCompany)?.id}`);
    }
    if (roleArray.length && !hasPermissions) navigate(REDIRECT_ROUTES[roleArray[0]]);
  }, [pathname, companyStore.getPageToShow, roleArray]);

  return <>{hasPermissions || startingRegistration ? children : null}</>;
});

export default RouteGuard;
