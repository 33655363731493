import React, { useEffect, useState } from 'react';
import { Select, Tooltip } from 'antd';
import { IPagination } from 'typescript';
import { paginationInitialState } from 'modules/nriz/nriz.constants';

import { map4select } from 'utils/map4select';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { DefaultOptionType, SelectProps } from 'antd/es/select';
import { IPaginatedResponse } from 'typescript/NrizTypes';
import { ITablePagination } from 'components/TableData/TableData';

export interface CustomOptionType<T> extends DefaultOptionType {
  item: T;
}
export interface InfiniteScrollProps<T> extends SelectProps {
  className?: string;
  labelOptionAccessor: string;
  valueOptionAccessor: string;
  disabledAccessor?: string;
  defaultOption?: CustomOptionType<T>;
  fetchOptionsList: <P extends ITablePagination>(params: P) => Promise<IPaginatedResponse<T> | undefined>;
  optionsMaker?: (items: T[]) => CustomOptionType<T>[];
  name?: string;
  customParamsForSearch?: string;
  handleChange?: (value: string | string[], option: CustomOptionType<T> | CustomOptionType<T>[]) => void;
  fetchBeforeFocusing?: boolean;
}

export const InfiniteScroll = <T,>({
  className,
  fetchOptionsList,
  valueOptionAccessor,
  labelOptionAccessor,
  disabledAccessor,
  optionsMaker = (items: T[]) =>
    map4select<T>({
      valueAccessor: valueOptionAccessor,
      labelAccessor: labelOptionAccessor,
      sourceArray: items,
      disabledAccessor: disabledAccessor,
    }),
  customParamsForSearch,
  defaultOption,
  handleChange,
  fetchBeforeFocusing,
  ...scrollConfig
}: InfiniteScrollProps<T>) => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<CustomOptionType<T>[]>([]);

  const [pagination, setPagination] = useState<IPagination>(paginationInitialState);

  const [search, setSearch] = useState<string>('');

  const currentOptions = !options.length ? (defaultOption ? [defaultOption] : []) : options;

  const updatePagination = (response: IPaginatedResponse<T>) => {
    setPagination({
      page: response.page === response.total ? response.total : response.page + 1,
      size: response.size,
      total: response.total,
      pages: response.pages,
    });
  };

  const loadMoreOptions = useDebouncedCallback(async () => {
    const { page, size } = pagination;

    const params = {
      page,
      size,
      ...(!customParamsForSearch ? { search: search } : { [customParamsForSearch]: search }),
    };

    const response = await fetchOptionsList(params);

    if (!response?.items?.length) {
      return;
    }

    const selectOptions = optionsMaker(response.items);

    setOptions(previousOptions => [...previousOptions, ...selectOptions]);

    updatePagination(response);
  }, 500);

  const handleSearch = (value: string) => {
    setSearch(value);
    setPagination(paginationInitialState);
    setOptions([]);
    loadMoreOptions();
  };

  const handleScroll = useDebouncedCallback((e: React.UIEvent<HTMLDivElement>) => {
    const { scrollTop, clientHeight, scrollHeight } = e.currentTarget ?? e.target;

    if (scrollHeight - scrollTop <= clientHeight + 10) {
      if (pagination.page <= pagination.pages) {
        loadMoreOptions();
      }
    }
  }, 200);

  useEffect(() => {
    if (fetchBeforeFocusing) loadMoreOptions();
  }, []);

  const handleLoadDataOnFocus = () => {
    if (options.length === 0) {
      loadMoreOptions();
    }
  };

  return (
    <Select
      className={className}
      onPopupScroll={handleScroll}
      onSearch={handleSearch}
      notFoundContent={scrollConfig.notFoundContent ? scrollConfig.notFoundContent : t('NO_OPTIONS_FOUND')}
      filterOption={false}
      onClear={() => {
        setOptions([]);
        setPagination(paginationInitialState);
      }}
      onDropdownVisibleChange={open => {
        if (!options.length && open) {
          setPagination(paginationInitialState);
          handleLoadDataOnFocus();
        }
      }}
      onFocus={handleLoadDataOnFocus}
      onChange={(value, option) => {
        if (!Array.isArray(option)) {
          //@ts-ignore
          handleChange?.(value, option);
        } else {
          //@ts-ignore
          option.forEach(option => handleChange?.(value, option));
        }
      }}
      {...scrollConfig}
    >
      {currentOptions.map((option: CustomOptionType<T>) =>
        option && option.value ? (
          <Select.Option key={option.value} value={option.value} disabled={option.disabled} item={option.item}>
            <Tooltip title={option.label}>{option.label}</Tooltip>
          </Select.Option>
        ) : undefined
      )}
    </Select>
  );
};
