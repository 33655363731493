import { ColumnsType } from 'antd/es/table';
import { IGetTypeOfPacking, IPostTypeOfPacking, ITypeOfPackingFilters } from './typeOfPacking.types';
import { FormInstance } from 'antd';
import { InputSifarniciFormProps, ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { MAX_LENGTH_RULE } from 'modules/nriz/nriz.constants';
import { VERSION_TABLE } from 'modules/sifarnici/sifarniciTableObj.constants';

export const TYPE_OF_PACKING_INITIAL_STATE: IPostTypeOfPacking = {
  type: '',
  type_sr: '',
  subtype: '',
  subtype_sr: '',
  display_type: '',
  display_type_sr: '',
  group_number: null,
  sub_group_number: null,
  index_number: '',
  is_active: true,
  version: '',
};
export const TYPE_OF_PACKING_FILTERS_INITIAL_STATE: ITypeOfPackingFilters = {
  type__ilike: '',
  type_sr__ilike: '',
  subtype__ilike: '',
  subtype_sr__ilike: '',
  display_type__ilike: '',
  display_type_sr__ilike: '',
  group_number__ilike: null,
  sub_group_number__ilike: null,
  index_number__ilike: '',
  is_active: null,
  version: '',
};
// todo: prevod
export const TYPE_OF_PACKING_TABLE_DATA: ColumnsType<IGetTypeOfPacking> = [
  {
    title: 'Tip pakovanja',
    width: '100xp',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Tip pakovanja (srp.)',
    width: '250px',
    dataIndex: 'type_sr',
    key: 'type_sr',
  },
  {
    title: 'Podtip pakovanja',
    width: '150px',
    dataIndex: 'subtype',
    key: 'subtype',
  },
  {
    title: 'Podtip pakovanja (srp.)',
    width: '250px',
    dataIndex: 'subtype_sr',
    key: 'subtype_sr',
  },
  {
    title: 'Tip ekrana',
    width: '200px',
    dataIndex: 'display_type',
    key: 'display_type',
  },
  {
    title: 'Tip ekrana (srp.)',
    width: '200px',
    dataIndex: 'display_type_sr',
    key: 'display_type_sr',
  },
  {
    title: 'Broj grupe',
    width: '150px',
    dataIndex: 'group_number',
    key: 'group_number',
  },

  {
    title: 'Broj podgrupe',
    width: '150px',
    dataIndex: 'sub_group_number',
    key: 'sub_group_number',
  },

  {
    title: 'Indeksni broj',
    width: '150px',
    dataIndex: 'index_number',
    key: 'index_number',
  },
  VERSION_TABLE,
];
export const TYPE_OF_PACKING_FORM_OPTIONS = (_: FormInstance<IGetTypeOfPacking>): ISifarniciTypeOfForm[] => {
  return [
    {
      type_of_form: 'input',
      name: 'type',
      filterName: 'type__ilike',
      rules: [MAX_LENGTH_RULE(100)],
      // todo: prevod
      label: 'Tip pakovanja',
      inputName: 'type',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      rules: [MAX_LENGTH_RULE(100)],
      name: 'type_sr',
      filterName: 'type_sr__ilike',
      // todo: prevod
      label: 'Tip pakovanja (srp.)',
      inputName: 'type_sr',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      rules: [MAX_LENGTH_RULE(100)],
      name: 'subtype',
      filterName: 'subtype__ilike',
      // todo: prevod
      label: 'Podtip pakovanja',
      inputName: 'subtype',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'subtype_sr',
      filterName: 'subtype_sr__ilike',
      rules: [MAX_LENGTH_RULE(100)],
      // todo: prevod
      label: 'Podtip pakovanja (srp.)',
      inputName: 'subtype_sr',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'display_type',
      filterName: 'display_type__ilike',
      rules: [MAX_LENGTH_RULE(200)],
      // todo: prevod
      label: 'Tip ekrana',
      inputName: 'display_type',
    } as InputSifarniciFormProps,

    {
      type_of_form: 'input',
      name: 'display_type_sr',
      filterName: 'display_type_sr__ilike',
      rules: [MAX_LENGTH_RULE(200)],
      // todo: prevod
      label: 'Tip ekrana (srp.)',
      inputName: 'display_type_sr',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'group_number',
      filterName: 'group_number__ilike',
      // todo: prevod
      label: 'Broj grupe',
      inputName: 'group_number',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input_number',
      name: 'sub_group_number',
      filterName: 'sub_group_number__ilike',
      // todo: prevod
      label: 'Broj podgrupe',
      inputName: 'sub_group_number',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'index_number',
      filterName: 'index_number__ilike',
      rules: [MAX_LENGTH_RULE(100)],
      // todo: prevod
      label: 'Indeksni broj',
      inputName: 'index_number',
    } as InputSifarniciFormProps,
    {
      type_of_form: 'input',
      name: 'version',
      filterName: 'version',
      label: 'Verzija',
      inputName: 'version',
    },
  ];
};
