import { makeAutoObservable } from 'mobx';
import { IDozvolaRequest, IDozvolaResponse, IDozvolaUpdate } from './dozvole.types';
import { dozvoleService } from './dozvole.service';
import to from 'await-to-js';
import { ITablePagination } from 'components/TableData/TableData';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { IPagination } from 'typescript';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';
import { IPaginatedResponse } from 'typescript/NrizTypes';
import { IGetCompetentAuthorityParty } from 'modules/sifarnici/store/competentAuthorityParty/competentAuthorityParty.types';
import { nrizService } from 'modules/nriz/nriz.service';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';

class DozvoleStore {
  dozvoleList: IDozvolaResponse[] = [];
  dozvolePagination: IPagination = paginationInitialState;
  selectedDozvola: Partial<IDozvolaResponse> = { privremena_dozvola: 'ne' };

  constructor() {
    makeAutoObservable(this, {});
  }

  setDozvoleList = (newList: IDozvolaResponse[]) => {
    this.dozvoleList = newList;
  };

  setSelectedDozvola = (operater: IDozvolaResponse) => {
    this.selectedDozvola = operater;
  };

  fetchDozvoleList = async (pagination?: ITablePagination, operaterId?: string) => {
    if (!operaterId) return new Error('Operater ID missing');
    const { page, size } = pagination ?? this.dozvolePagination;

    const [err, res] = await to(
      dozvoleService.fetchDozvoleList({
        operater_id: operaterId,
        page,
        size,
        ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.DOZVOLE_DOZVOLE]),
      })
    );

    if (err || !res) return Promise.reject(err);

    this.setDozvoleList(res.items);
  };

  createDozvola = async (payload: IDozvolaRequest) => {
    const [err, res] = await to(dozvoleService.createDozvola(payload));

    if (err || !res) return Promise.reject(err);
    return res;
  };

  updateDozvolaInfo = async (payload: IDozvolaUpdate) => {
    if (!payload.id) return Promise.reject(new Error('No id provided!'));
    const [err, res] = await to(dozvoleService.updateDozvolaInfo(payload.id, payload));

    if (err || !res) return Promise.reject(err);

    return res;
  };

  fetchDozvolaById = async (id: string) => {
    const [err, res] = await to(dozvoleService.fetchDozvolaById(id));

    if (err || !res) return Promise.reject(err);
    const formattedResponse = dozvoleService.formatResponseDates(res);
    this.setSelectedDozvola(formattedResponse);
    return formattedResponse;
  };

  deleteDozvola = async (id: string) => {
    const [err, res] = await to(dozvoleService.deleteDozvola(id));

    if (err || !res) return Promise.reject(err);
  };

  fetchOpstina = async (pagination: IPagination, search: string): Promise<IPaginatedResponse<IGetMesto>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetMesto>(SIFARNICI_ROUTES.MESTO, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      location_code__neq: 999999,
      order_by: '+display_name',
    });
    return response;
  };

  fetchIzdavalacDozvole = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetCompetentAuthorityParty>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetCompetentAuthorityParty>(
      SIFARNICI_ROUTES.COMPETENT_AUTHORITY_PARTY,
      {
        page: pagination.page,
        size: pagination.size,
        search: search,
      }
    );
    return response;
  };
}

export const dozvoleStore = new DozvoleStore();
