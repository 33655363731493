import { IPreseciTranslation } from './preseciTranslation.types';

export const PRESECI_SRPSKI_TRANSLATION: IPreseciTranslation = {
  OPSTINA: 'Opština preduzeća',
  PIB_PREDUZECA: 'PIB preduzeća',
  MATICNI_BROJ_PREDUZECA: 'Matični broj preduzeća',
  BROJ_TELEFONA_PREDUZECA: 'Broj telefona preduzeća',
  MESTO: 'Mesto preduzeća',
  ULICA_BROJ_PREDUZECA: 'Ulica i broj preduzeća',
  GRUPA_AMBALAZA: 'Grupa ambalaže',
  UKUPNA_NEPOVRATNE: 'Ukupna količina nepovratne ambalaže (t)',
  UKUPNA_POVRATNE: 'Ukupna količina povratne ambalaže (t) ',
  UKUPNA_AMBALAZA: 'Ukupna količina ambalaže (t) ',
  STATUS: 'Status',
  ISKLJUCEN: 'Isključen',
  AUTOMATSKI_ISKLJUCEN: 'Automatski isključen',
  PERIOD_SLANJA_OD: 'Period slanja od',
  PERIOD_SLANJA_DO: 'Period slanja do',
  NAZIV_PREDUZECA: 'Naziv preduzeća',
  GRUPA_AMBALAZE: 'Grupa ambalaže',
  BROJ_ZAPISA: 'Broj zapisa',
  BROJ_PREDUZECA: 'Broj preduzeća',
  BROJ_IZVESTAJA: 'Broj izveštaja',
  DATUM_I_VREME_SLANJA_IZVESTAJA: 'Datum i vreme slanja izveštaja',
  DATUM_I_VREME_PRESEKA: 'Datum i vreme preseka',
  DATUM_IZVESTAJA: 'Datum izveštaja',
  DATUM_PRESEKA: 'Datum preseka',
  PIB_OPERATERA: 'PIB operatera',
  NAZIV_OPERATERA: 'Naziv operatera',
  BROJ_OPERATERA: 'Broj operatera',
  TELEFON_PREDUZECA: 'Telefon preduzeća',
  EMAIL_PREDUZECA: 'Email preduzeća',
  NACIONALNI_ID: 'Nacionalni ID postrojenja',
  NAZIV_POSTROJENJA: 'Naziv postrojenja',
  NAZIV_ISPUSTA: 'Naziv ispusta',
  OZNAKA_ISPUSTA: 'Oznaka ispusta',
  ZONA_SANITARNE_ZASTITE_IZVORISTA: 'Zona sanitarne zaštite izvorišta',
  VODNA_TELA_NAMENJENA_REKREACIJI: 'Vodna tela namenjena rekreaciji',
  POV_POSTROJENJE_U_IZGRADNJI_ILI_REKONSTRUKCIJI: 'POV postrojenje u izgradnji ili rekonstrukciji',
  POSEBNA_CENA_U_SKLADU_SA_NAPOMENOM_IZ_TABELE_8_1_ZAKONA: 'Posebna cena u skladu sa napomenom iz Tabele 8.1 Zakona',
  VREMENSKI_PERIOD_ISPUSTANJA_VODE_DAN_GOD: 'Vremenski period ispuštanja vode (dan/god)',
  UKUPNA_KOLICINA_ISPUSTENE_VODE_M3_GOD: 'Ukupna količina ispuštene vode (m3/god)',
  SREDNJI_DNEVNI_PROTOK_OTPADNE_VODE_M3_DAN: 'Srednji dnevni protok otpadne vode (m3/dan)',
  KAPACITET_PROIZVODNJE_T_GOD: 'Kapacitet proizvodnje (t/god)',
  ZAGADJUJUCA_MATERIJA: 'Zagađujuća materija',
  SREDNJA_GODISNJA_IZMERENA_VREDNOST_MG_L: 'Srednja godišnja izmerena vrednost (mg/l)',
  KOLICINA_PRI_REDOVNOM_RADU_KG_GOD: 'Količina pri redovnom radu (kg/god)',
  GVE: 'GVE',
  GVE_JEDINICA_MERE: 'GVE jedinica mere',
  BROJ_ZAGADJUJUCIH_MATERIJA: 'Broj zagađujućih materija',
  UKUPNA_KOLICINA_PROIZVODA_DETERDZENTI: 'Ukupna količina proizvoda (kg) - deterdženti',
  UKUPNA_KOLICINA_FOSFATA_DETERDZENTI: 'Ukupna količina fosfata (kg) - deterdženti',
  UKUPNA_KOLICINA_PROIZVODA_MINERALNA_DJUBRIVA: 'Ukupna količina proizvoda (kg) - mineralna đubriva',
  UKUPNA_KOLICINA_AZOTA_MINERALNA_DJUBRIVA: 'Ukupna količina azota (kg) - mineralna đubriva',
  UKUPNA_KOLICINA_PROIZVODA_SREDSTVA_ZA_ZASTITU_BILJA: 'Ukupna količina proizvoda (kg) - sredstva za zaštitu bilja',
  UKUPNA_KOLICINA_AKTIVNE_MATERIJE_SREDSTVA_ZA_ZASTITU_BILJA:
    'Ukupna količina aktivne materije (kg) - sredstva za zaštitu bilja',
  GRUPA_PROIZVODA: 'Grupa proizvoda',
  UKUPNA_KOLICINA_ZA_GRUPU_PROIZVODA: 'Ukupna količina za grupu proizvoda (kg)',
  UKUPNA_KOLICINA_MATERIJE: 'Ukupna količina materije (kg)',

  UKUPNA_KOLICINA_SUMPORNI_OKSIDI: 'Ukupna količina: Sumporni oksidi (SOx/SO2) (kg/god)',
  UKUPNA_KOLICINA_AZOTNI_OKSIDI: 'Ukupna količina: Azotni oksidi (NOx/NO2) (kg/god)',
  UKUPNA_KOLICINA_SUSPENDOVANE_CESTICE: 'Ukupna količina: Suspendovane čestice (PM10) (kg/god)',
  UKUPNA_KOLICINA_UKUPNE_PRASKASTE_MATERIJE: 'Ukupna količina: Ukupne praškaste materije (kg/god)',
  UKUPNA_GODISNJA_KOLICINA_OTPAD: 'Ukupna godišnja količina proizvedenog otpad (t/god)',
  UKUPNA_KOLICINA_OTPAD_SKLADISTENJE: 'Ukupna količina otpada predata na skladištenje (t/god)',
  UKUPNA_KOLICINA_OTPAD_ODLAGANJE: 'Ukupna količina otpada predata na odlaganje (t/god)',
  UKUPNA_KOLICINA_OTPAD_TRETMAN: 'Ukupna količina otpada predata na tretman (t/god)',
  UKUPNA_KOLICINA_IZVEZENI_OTPAD: 'Ukupna količina izvezenog otpada (t/god)',
  SUMPORNI_OKSIDI: 'Sumporni oksidi (SOx/SO2) (kg/god)',
  AZOTNI_OKSIDI: 'Azotni oksidi (NOx/NO2) (kg/god)',
  SUSPENDOVANE_CESTICE: 'Suspendovane čestice (PM10) (kg/god)',
  UKUPNE_PRASKASTE_MATERIJE: 'Ukupne praškaste materije (kg/god)',
  SOURCE_NUMBER: 'Broj izvora',
  SOURCE_NAME: 'Naziv izvora',

  GODISNJA_KOLICINA_PROIZVEDENOG_OTPAD: 'Godišnja količina prozvedenog otpada',
  KOLICINA_OTPAD_SKLADISTENJE: 'Količina otpada predata na skladištenje',
  KOLICINA_OTPAD_ODLAGANJE: 'Količina otpada predata na odlaganje',
  KOLICINA_OTPAD_TRETMAN: 'Količina otpada predata na tretman',
  KOLICINA_IZVEZENOG_OTPAD: 'Količina izvezenog otpada',
  VRSTA_PROIZVODA: 'Vrsta proizvoda',
  PROIZVOD: 'Proizvod',
  KOLICINA: 'Količina',
  JEDINICA_MERE: 'Jedinica mere',
  PDV: 'PDV',
  ZAVODNI_BROJ_DOPISA: 'Zavodni broj dopisa',
  DATUM_DOPISA: 'Datum dopisa',
  UKUPNA_KOLICINA_NOVE: 'Ukupna količina - nove (kg)',
  UKUPAN_PDV_NOVE: 'Ukupan PDV - nove (RSD) ',
  UKUPNA_KOLICINA_KORIGOVANE: 'Ukupna količina - korigovane (kg)',
  UKUPAN_PDV_KORIGOVANE: 'Ukupan PDV - korigovane (RSD) ',
  UKUPNA_KOLICINA_BRISANE: 'Ukupna količina - brisane (kg)',
  UKUPAN_PDV_BRISANE: 'Ukupan PDV - brisane (RSD) ',
};
