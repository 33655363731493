import { Checkbox, FormInstance } from 'antd';
import { ColumnsType } from 'antd/es/table';
import dayjs from 'dayjs';
import { pregledDozvolaStore } from './pregledDozvola.store';
import { PregledDozvolaType } from '../dozvole/dozvole.types';
import { IPregledDozvolaFilters, IPregledDozvoleRow } from './pregledDozvola.types';
import {
  CheckboxGroupSifarniciFormProps,
  DateSifarniciFormProps,
  InputNumberSifarniciFormProps,
  InputSifarniciFormProps,
  ISifarniciTypeOfForm,
  SearchScrollSifarniciFormProps,
  SIFARNICI_ROUTES,
} from 'modules/sifarnici/sifarnici.types';
// import i18n from 'translations/i18n.config';

export const PREGLED_DOZVOLA_COLUMNS: (type: PregledDozvolaType) => ColumnsType<IPregledDozvoleRow> = type => [
  {
    title: 'Naziv operatera',
    dataIndex: 'naziv_operatera',
    key: 'naziv_operatera',
    width: 150,
    align: 'center',
  },
  {
    title: 'PIB operatera',
    dataIndex: 'pib_operatera',
    key: 'pib_operatera',
    width: 150,
    align: 'center',
  },
  {
    title: 'Matični broj operatera',
    dataIndex: 'maticni_broj_operatera',
    key: 'maticni_broj_operatera',
    width: 150,
    align: 'center',
  },
  {
    title: 'Opština operatera',
    dataIndex: 'opstina_operatera',
    key: 'opstina_operatera',
    width: 150,
    align: 'center',
  },
  {
    title: 'Registraski broj dozvole',
    dataIndex: 'registraski_broj_dozvole',
    key: 'registraski_broj_dozvole',
    width: 150,
    align: 'center',
  },
  {
    title: 'Broj dozvole',
    dataIndex: 'broj_dozvole',
    key: 'broj_dozvole',
    width: 150,
    align: 'center',
  },
  {
    title: 'Nadležni organ koji je izdao dozvolu',
    dataIndex: 'nadlezni_organ_koji_je_izdao_dozvolu',
    key: 'nadlezni_organ_koji_je_izdao_dozvolu',
    width: 150,
    align: 'center',
  },
  {
    title: 'Dozvola važi od',
    render: (_, record) => <>{dayjs(record.dozvola_vazi_od).format('DD.MM.YYYY')}</>,
    key: 'dozvola_vazi_od',
    width: 150,
    align: 'center',
  },
  {
    title: 'Dozvola važi do',
    render: (_, record) => <>{dayjs(record.dozvola_vazi_do).format('DD.MM.YYYY')}</>,
    key: 'dozvola_vazi_do',
    width: 150,
    align: 'center',
  },
  {
    title: 'Status dozvole',
    dataIndex: 'status_dozvole',
    key: 'status_dozvole',
    width: 150,
    align: 'center',
  },
  {
    title: 'Vrsta dozvole za upravljanje otpadom',
    children: [
      ...(type === PregledDozvolaType.IndeksniBroj
        ? [
            {
              title: 'Indeksni broj otpada',
              dataIndex: 'indeksni_broj_otpada',
              key: 'indeksni_broj_otpada',
              width: 150,
              align: 'center' as any,
            },
          ]
        : []),
      {
        title: 'Sakupljanje',
        render: (_, record) => <Checkbox checked={record.sakupljanje} disabled />,
        key: 'sakupljanje',
        width: 150,
        align: 'center',
      },
      {
        title: 'Transport',
        render: (_, record) => <Checkbox checked={record.transport} disabled />,
        key: 'transport',
        width: 150,
        align: 'center',
      },
      {
        title: 'Skladištenje',
        render: (_, record) => <Checkbox checked={record.skladistenje} disabled />,
        key: 'skladistenje',
        width: 150,
        align: 'center',
      },
      {
        title: 'Tretman - odlaganje',
        render: (_, record) => <Checkbox checked={record.tretman_odlaganje} disabled />,
        key: 'tretman_odlaganje',
        width: 150,
        align: 'center',
      },
      {
        title: 'Tretman - ponovno iskoriscenje',
        render: (_, record) => <Checkbox checked={record.tretman_ponovno_iskoriscenje} disabled />,
        key: 'tretman_ponovno_iskoriscenje',
        width: 150,
        align: 'center',
      },
    ],
  },
  ...(pregledDozvolaStore.pregledDozvolaType === PregledDozvolaType.IndeksniBroj
    ? [
        {
          title: 'Naziv otpada',
          dataIndex: 'naziv_otpada',
          key: 'naziv_otpada',
          width: 150,
          align: 'center' as any,
        },
      ]
    : []),
];

export const PREGLED_DOZVOLA_FILTERS_INITIAL_STATE: Partial<IPregledDozvolaFilters> = {
  status_dozvole_aktivna: true,
  // pregled_po_vrsti_dozvole: true,
};

const NAZIV_OPERATERA_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: 'Naziv operatera',
  name: 'naziv_operatera__ilike',
  inputName: 'naziv_operatera__ilike',
  // sortName: 'pib',
};

const PIB_OPERATERA_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: 'PIB operatera',
  name: 'pib_operatera__ilike',
  inputName: 'pib_operatera__ilike',
  type: 'number',
  // sortName: 'pib',
};

const MATICNI_BROJ_OPERATERA_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  label: 'Matični broj operatera',
  name: 'maticni_broj_operatera__ilike',
  inputName: 'maticni_broj_operatera__ilike',
  type: 'number',
  // sortName: 'maticni_broj',
};

const OPSTINA_OPERATERA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'opstina_operatera_id',
  fetchOptinsList: SIFARNICI_ROUTES.MESTO,
  labelOptionAccessor: ['display_name'],
  valueOptionAccessor: 'id',
  inputName: 'opstina_operatera_id',
  label: 'Opština operatera',
};

const REGISTARSKI_BROJ_DOZVOLE_FILTER: InputNumberSifarniciFormProps = {
  type_of_form: 'input_number',
  name: 'registarski_broj_dozvole',
  filterName: 'registarski_broj_dozvole__ilike',
  // todo: prevod
  label: 'Registarski broj dozvole',
  inputName: 'registarski_broj_dozvole',
};

const BROJ_DOSIJEA_FILTER: InputSifarniciFormProps = {
  type_of_form: 'input',
  name: 'broj_dosijea__ilike',
  label: 'Broj dosijea',
  inputName: 'broj_dosijea__ilike',
};

const OPSTINA_POSTROJENJA_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'opstina_postrojenja_id',
  fetchOptinsList: SIFARNICI_ROUTES.MESTO,
  labelOptionAccessor: ['display_name'],
  valueOptionAccessor: 'id',
  inputName: 'opstina_postrojenja_id',
  label: 'Opština postrojenja',
};

const DOZVOLA_VAZI_DO_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'datum_vazi_do__lte',
  picker: 'date',
  format: 'DD-MM-YYYY',
  label: 'Datum važi do',
};

const DOZVOLA_VAZI_OD_FILTER: DateSifarniciFormProps = {
  type_of_form: 'date',
  name: 'datum_vazi_od__lte',
  picker: 'date',
  format: 'DD-MM-YYYY',
  label: 'Datum važi od',
};
const IZDAVALAC_DOZVOLE_FILTER: SearchScrollSifarniciFormProps = {
  type_of_form: 'searchScroll',
  formName: 'izdavalac_dozvole_id',
  fetchOptinsList: SIFARNICI_ROUTES.MESTO,
  labelOptionAccessor: ['display_name'],
  valueOptionAccessor: 'id',
  inputName: 'izdavalac_dozvole_id',
  label: 'Izdavalac dozvole',
};

const INDEKSNI_BROJ_OTPADA_FILTER = (): SearchScrollSifarniciFormProps => ({
  type_of_form: 'searchScroll',
  formName: 'indeksni_broj_otpada_id',
  fetchOptinsList: SIFARNICI_ROUTES.WASTE_CATALOGUE,
  labelOptionAccessor: ['index_number', 'waste_sr'],
  valueOptionAccessor: 'id',
  inputName: 'indeksni_broj_otpada_id',
  label: 'Indeksni broj otpada',
  filterColSpan: 8,
  // disabled: pregledDozvolaStore.pregledDozvolaType !== PregledDozvolaType.IndeksniBroj,
  // resetOption: pregledDozvolaStore.pregledDozvolaType,
  // resetOption ne radi kako treba u komponenti FormScrollWithObjInside - popraviti
});

const STATUS_DOZVOLE_FILTER = (
  form: FormInstance<{
    status_dozvole_aktivna: boolean;
    status_dozvole_oduzeta: boolean;
    status_dozvole_izmenjena: boolean;
  }>
): CheckboxGroupSifarniciFormProps => {
  const validateCheckboxes = (
    // _event: React.MouseEvent<HTMLElement, MouseEvent>,
    fieldName: 'status_dozvole_aktivna' | 'status_dozvole_oduzeta' | 'status_dozvole_izmenjena'
  ) => {
    const fieldsArray = ['status_dozvole_aktivna', 'status_dozvole_oduzeta', 'status_dozvole_izmenjena'].filter(
      field => field !== fieldName
    );
    const clickedFieldValue = form.getFieldValue(fieldName);
    const isAnyOtherBoxSelected = Object.values(form.getFieldsValue(fieldsArray)).every(s => !s);

    if (!clickedFieldValue && isAnyOtherBoxSelected) {
      form.setFieldsValue({ [fieldName]: true });
    }
  };

  return {
    filterColSpan: 8,
    type_of_form: 'checkbox-group',
    groupName: 'Status dozvole',
    checkboxConfig: [
      {
        label: 'Aktivna',
        name: 'status_dozvole_aktivna',
        onCheckBoxChange: () => validateCheckboxes('status_dozvole_aktivna'),
      },
      {
        label: 'Oduzeta',
        name: 'status_dozvole_oduzeta',
        onCheckBoxChange: () => validateCheckboxes('status_dozvole_oduzeta'),
      },
      {
        label: 'Izmenjena',
        name: 'status_dozvole_izmenjena',
        onCheckBoxChange: () => validateCheckboxes('status_dozvole_izmenjena'),
      },
    ],
  };
};

const VRSTA_DOZVOLE_FILTER: CheckboxGroupSifarniciFormProps = {
  type_of_form: 'checkbox-group',
  groupName: 'Vrsta dozvole',
  filterColSpan: 16,
  checkboxConfig: [
    {
      label: 'Sakupljanje',
      name: 'sakupljanje',
    },
    {
      label: 'Transport',
      name: 'transport',
    },
    {
      label: 'Skladištenje',
      name: 'skladistenje',
    },
    {
      label: 'Tretman - odglaganje',
      name: 'tretman_odlaganje',
    },
    {
      label: 'Tretman - ponovno iskorišćenje',
      name: 'tretman_ponovno_iskoriscenje',
    },
  ],
};

const VRSTA_OTPADA_FILTER: CheckboxGroupSifarniciFormProps = {
  type_of_form: 'checkbox-group',
  groupName: 'Vrsta otpada',
  filterColSpan: 8,
  checkboxConfig: [
    {
      label: 'Opasan',
      name: 'vrsta_otpada_opasan',
    },
    {
      label: 'Neopasan',
      name: 'vrsta_otpada_neopasan',
    },
  ],
};

// izdavalac_dozvole_id?: string;
// indeksni_broj_otpada_id?: string;

// ???
// pregled_po_vrsti_dozvole?: boolean;
// pregled_po_vrsti_dozvole_i_indeksnom_broju?: boolean;

export const PREGLED_DOZVOLA_FILTER_OPTIONS = (
  form: FormInstance<any>,
  type: PregledDozvolaType
): ISifarniciTypeOfForm[] => [
  NAZIV_OPERATERA_FILTER,
  PIB_OPERATERA_FILTER,
  MATICNI_BROJ_OPERATERA_FILTER,
  OPSTINA_OPERATERA_FILTER,
  REGISTARSKI_BROJ_DOZVOLE_FILTER,
  BROJ_DOSIJEA_FILTER,
  OPSTINA_POSTROJENJA_FILTER,
  DOZVOLA_VAZI_OD_FILTER,
  DOZVOLA_VAZI_DO_FILTER,
  IZDAVALAC_DOZVOLE_FILTER,
  STATUS_DOZVOLE_FILTER(form),
  VRSTA_DOZVOLE_FILTER,
  VRSTA_OTPADA_FILTER,
  INDEKSNI_BROJ_OTPADA_FILTER(),
  ...(type === PregledDozvolaType.IndeksniBroj ? [] : []),
];
