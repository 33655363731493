import { Row, Col, Button, Alert, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { FormComponents } from 'components/FormComponents/FormComponents';
import { Modal } from 'components/Modal/Modal';
import { observer } from 'mobx-react-lite';
import { MODAL_SINGLE_PRESEK_CONFIG } from 'modules/preseci/preseci.constants';
import { preseciStore } from 'modules/preseci/preseci.store';
import { ModalUkljuciIskljuciPreseciKeys, IPreseciConfig } from 'modules/preseci/preseci.types';
import { ISifarniciTypeOfForm } from 'modules/sifarnici/sifarnici.types';
import { FC, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { modalStore } from 'store';

const UkljuciIskljuciModalForm: FC<IModalUkljuciIskljuciPoFilterimaSinglePresekProps> = observer(({ presekType }) => {
  const [form] = useForm();

  const presekData: IPreseciConfig = MODAL_SINGLE_PRESEK_CONFIG[presekType];
  const formComponents = useMemo(() => {
    return presekData.components(form);
  }, [Form.useWatch(['obrazac_preduzece_id'], form)]);

  const initialState = presekData.initialState;

  const ukljuciStavkePoFilterima = () => {
    preseciStore.ukljuciStavkePoFilterima(presekType, form.getFieldsValue(true));
  };

  const iskljuciStavkePoFilterima = () => {
    preseciStore.iskljuciStavkePoFilterima(presekType, form.getFieldsValue(true));
  };

  useEffect(() => {
    return () => form.setFieldsValue(initialState);
  }, []);

  return (
    <Form
      form={form}
      initialValues={initialState}
      name={'company'}
      labelCol={{ span: 11 }}
      wrapperCol={{ span: 13 }}
      labelAlign="left"
    >
      <div style={{ padding: '20px 0px' }}>
        <Alert
          style={{ width: '100%' }}
          message="Akcijom uključivanje ili isključivanje gase se svi redovi koji su zadatim filterom vezani za to preduzece i za godinu "
          description="PRIMER: Ako izaberete PIB 222222222, a u list postoji vise redova za zadati pib , svi redovi se iskljucuju ili ukljucuju iz izveštaja  "
          type="info"
          showIcon
        />
      </div>
      {formComponents?.map((item: ISifarniciTypeOfForm, index) => (
        <FormComponents data={item} key={index} />
      ))}

      <Row justify={'end'} gutter={20}>
        <Col>
          <Button name="ukljuci-filteri" onClick={ukljuciStavkePoFilterima}>
            Uključi u izveštaj sve stavke po filterima
          </Button>
        </Col>
        <Col>
          <Button danger name="iskljuci-filteri" onClick={iskljuciStavkePoFilterima}>
            Isključi iz izveštaja sve stavke po filterima
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

interface IModalUkljuciIskljuciPoFilterimaSinglePresekProps {
  presekType: ModalUkljuciIskljuciPreseciKeys;
}

export const ModalUkljuciIskljuciPoFilterimaSinglePresek: FC<IModalUkljuciIskljuciPoFilterimaSinglePresekProps> =
  observer(({ presekType }) => {
    const { t } = useTranslation();

    return (
      <>
        {modalStore.getCurrentOpenedModal.includes(`ukljuci-iskljuci-preseci-modal`) && (
          <Modal
            name={`ukljuci-iskljuci-preseci-modal`}
            open={true}
            okButtonProps={{ style: { display: 'none' } }}
            cancelButtonProps={{ style: { display: 'none' } }}
            closable={true}
            onCancel={() => {
              modalStore.removeAllModals();
            }}
            title={t('Uključivanje / Isključivanje')}
            footer={null}
            destroyOnClose={true}
            maskClosable={true}
            width={'70%'}
          >
            <UkljuciIskljuciModalForm presekType={presekType} />
          </Modal>
        )}
      </>
    );
  });
