import { FormConfig } from 'modules/obrazacIzvestaji/obrazacIzvestajiFilters.constants';
import { ISifarniciTypeOfForm, SelectSifarniciFormProps } from 'modules/sifarnici/sifarnici.types';

import { IPreseciColumnProps } from '../preseci.types';
import {
  DATUM_SLANJA_COLUMN,
  BROJ_ZAPISA_COLUMN,
  BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS,
  GODINA_COLUMN,
  MATICNI_BROJ_COLUMN,
  NAZIV_PREDUZECA_COLUMN,
  OPSTINA_PREDUZECA_COLUMN,
  STATUS_COLUMN,
  BROJ_PREDUZECA_COLUMN,
  MESTO_PREDUZECA_COLUMN,
  ULICA_PREDUZECA_COLUMN,
  PIB_COLUMN,
  TELEFON_PREDUZECA_COLUMN,
} from '../preseciColumns.constants';
import {
  DATUM_SLANJA_GTE_FILTER,
  DATUM_SLANJA_LTE_FILTER,
  GODINA_FILTER,
  MATICNI_BROJ_FILTER,
  NAZIV_PREDUZECE_FILTER,
  PIB_FILTER,
} from '../preseciFilters.constants';
import {
  IFiltersAao1BezOperateraPreseciList,
  IGetAao1BezOperateraSinglePresek,
  IFiltersAao1BezOperateraSinglePresek,
  IGetAao1BezOperateraPreseciList,
  IModalUkljuciIskljuciAao1BezOperateraSinglePresek,
} from './aao1Presek.types';
import i18n from 'translations/i18n.config';
import { IDENTIFICATION_NUMBER_FIELD_RULE, PIB_FIELD_RULE } from 'modules/nriz/nriz.constants';

const GRUPE_AMBALAZE_FILTER: SelectSifarniciFormProps = {
  type_of_form: 'select',
  label: i18n.t('PRESECI.GRUPA_AMBALAZE'),
  name: 'naziv_grupe_ambalaze__ilike',
  options: [
    {
      label: 'Drvo',
      value: 'Drvo',
    },
    {
      label: 'Papir i karton',
      value: 'Papir i karton',
    },
    {
      label: 'Ostalo',
      value: 'Ostalo',
    },
    {
      label: 'Metal',
      value: 'Metal',
    },
    {
      label: 'Staklo',
      value: 'Staklo',
    },
    {
      label: 'Plastika',
      value: 'Plastika',
    },
  ],
};

//#region LISTA P
export const PRESECI_AAO_1_COLUMNS: IPreseciColumnProps<IGetAao1BezOperateraPreseciList>[] = [
  DATUM_SLANJA_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraPreseciList>,
  BROJ_ZAPISA_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraPreseciList>,
  BROJ_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraPreseciList>,
  { width: 200, dataIndex: 'ukupna_kolicina_nepovratne_ambalaze', title: i18n.t('PRESECI.UKUPNA_NEPOVRATNE') },
  { width: 200, dataIndex: 'ukupna_kolicina_povratne_ambalaze', title: i18n.t('PRESECI.UKUPNA_POVRATNE') },
  { width: 200, dataIndex: 'ukupna_kolicina_ambalaze', title: i18n.t('PRESECI.UKUPNA_AMBALAZA') },
  ...(BROJ_I_DATUM_IZVESTAJA_DATUM_PRESEKA_COLUMNS as IPreseciColumnProps<IGetAao1BezOperateraPreseciList>[]),
];

export const AAO1_PRESECI_LIST_FILTERS_INITIAL_STATE: IFiltersAao1BezOperateraPreseciList = {
  datum_slanja__gte: '',
  datum_slanja__lte: '',
  obrazac_preduzece_id: '',
  naziv_grupe_ambalaze__ilike: '',
};

export const AAO1_PRESECI_LIST_FILTERS_OPTIONS: FormConfig = (_form): ISifarniciTypeOfForm[] => [
  DATUM_SLANJA_GTE_FILTER,
  DATUM_SLANJA_LTE_FILTER,
  NAZIV_PREDUZECE_FILTER(),
  GRUPE_AMBALAZE_FILTER,
];

//#endregion LISTA P

//#region PRESEK
export const SINGLE_PRESEK_AAO_1_COLUMNS = (): IPreseciColumnProps<IGetAao1BezOperateraSinglePresek>[] => [
  GODINA_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraSinglePresek>,
  PIB_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraSinglePresek>,
  MATICNI_BROJ_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraSinglePresek>,
  NAZIV_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraSinglePresek>,
  MESTO_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraSinglePresek>,
  OPSTINA_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraSinglePresek>,
  ULICA_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraSinglePresek>,
  TELEFON_PREDUZECA_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraSinglePresek>,
  {
    title: `${i18n.t('PRESECI.GRUPA_AMBALAZA')}`,
    dataIndex: 'naziv_grupe_ambalaze',
    key: 'naziv_grupe_ambalaze',
    width: '200px',
  },
  {
    title: `${i18n.t('PRESECI.UKUPNA_NEPOVRATNE')}`,
    dataIndex: 'ukupna_kolicina_nepovratne_ambalaze_t',
    key: 'ukupna_kolicina_nepovratne_ambalaze_t',
    width: '200px',
  },
  {
    title: `${i18n.t('PRESECI.UKUPNA_POVRATNE')}`,
    dataIndex: 'ukupna_kolicina_povratne_ambalaze_t',
    key: 'ukupna_kolicina_povratne_ambalaze_t',
    width: '200px',
  },
  {
    title: `${i18n.t('PRESECI.UKUPNA_AMBALAZA')}`,
    dataIndex: 'ukupna_kolicina_ambalaze_t',
    key: 'ukupna_kolicina_ambalaze_t',
    width: '200px',
  },
  STATUS_COLUMN as IPreseciColumnProps<IGetAao1BezOperateraSinglePresek>,
];

export const AAO1_SINGLE_PRESEK_FILTER_INITIAL_STATE: IFiltersAao1BezOperateraSinglePresek = {
  maticni_broj__ilike: '',
  godina: '',
  iskljucen: null,
  pib__ilike: '',
  naziv_grupe_ambalaze__ilike: '',
  obrazac_preduzece_id: '',
};

export const AAO1_SINGLE_PRESEK_FILTERS_OPTIONS: FormConfig = (form): ISifarniciTypeOfForm[] => [
  GODINA_FILTER,
  PIB_FILTER({ ilike: true }),
  MATICNI_BROJ_FILTER({ ilike: true }),
  NAZIV_PREDUZECE_FILTER(form),
  GRUPE_AMBALAZE_FILTER,
];
//#endregion PRESEK

//#region MODAL
export const AAO1_MODAL_SINGLE_PRESEK_FORM_INITIAL_STATE: IModalUkljuciIskljuciAao1BezOperateraSinglePresek = {
  maticni_broj: '',
  godina: '',
  pib: '',
  iskljucen: null,
  naziv_grupe_ambalaze: '',
  obrazac_preduzece_id: '',
};

export const AAO1_MODAL_SINGLE_PRESEK_FORM_OPTIONS: FormConfig = (form): ISifarniciTypeOfForm[] => [
  GODINA_FILTER,
  PIB_FILTER({ rules: [PIB_FIELD_RULE] }),
  MATICNI_BROJ_FILTER({ rules: [IDENTIFICATION_NUMBER_FIELD_RULE] }),
  NAZIV_PREDUZECE_FILTER(form),
  { ...GRUPE_AMBALAZE_FILTER, name: 'naziv_grupe_ambalaze' },
];
//#endregion MODAL
