import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { observer } from 'mobx-react-lite';
import { Col, Form, Popconfirm, Row, SelectProps, Tooltip } from 'antd';
import { ColumnProps } from 'antd/es/table';
import { EditOutlined } from '@ant-design/icons';

import {
  EuRegistar,
  EvropskiIzvestajLists,
  EvropskiIzvestajTypeEnum,
} from 'modules/evropskiIzvestaji/evropskiIzvestaji.types';
import { EVROPSKI_IZVESTAJI_COLUMN_MAP } from 'modules/evropskiIzvestaji/evropskiIzvestajiColumns.constants';
import { EVROPSKI_IZVESTAJI_TYPE_OPTIONS } from 'modules/evropskiIzvestaji/evropskiIzvestaji.constants';
import { evropskiIzvestajiStore } from 'modules/evropskiIzvestaji/evropskiIzvestaji.store';
import { LoggerService } from 'services/logger.service';
import { EvropskiIzvestajiFilters } from './components/EvropskiIzvestajiFilters/EvropskiIzvestajiFilters';
import TableData, { ITablePagination } from 'components/TableData/TableData';
import { Content } from 'components/Content/Content';
import { Select } from 'components/Select/Select';
import { Button } from 'components/Button/Button';
import { Input } from 'components/Input/Input';

import { FormListType } from 'typescript/NrizTypes';
import { handleSuccessNotify } from 'utils/succesHandling';
import { handleErrorNotify } from 'utils/errorHandling';
import { modalStore } from 'store';
import { EvropskiIzvestajiForm } from './components/EvropskiIzvestajiForm/EvropskiIzvestajiForm';

import styles from './EvropskiIzvestaji.module.scss';

/**
 * Evropski Izvestaji function component
 */
export const EvropskiIzvestaji = observer(() => {
  const { t } = useTranslation();
  const [izvestajType, setIzvestajType] = useState<EvropskiIzvestajTypeEnum | null>();
  const [selectedEvropskiIzvestaj, setSelectedEvropskiIzvestaj] = useState<EuRegistar | null>(null);
  const [itemForm] = Form.useForm();

  /**
   * Method that handles change status
   * @param { EuRegistar } record
   */
  const handleChangeStatus = (record: EuRegistar) => {
    setSelectedEvropskiIzvestaj(record);
    modalStore.changeModalName('edit-status-evropski-izvestaj-modal');
  };

  /**
   * Method that handles generate Excel
   */
  const handleGenerateExcel = () => {
    modalStore.changeModalName('generate-excel-evropski-izvestaj-modal');
  };

  const columnsByEvropskiIzvestajType = izvestajType
    ? [
        ...EVROPSKI_IZVESTAJI_COLUMN_MAP[izvestajType](evropskiIzvestajiStore.evropskiIzvestaj),
        {
          title: '',
          key: 'operation',
          fixed: 'right',
          width: '70px',
          align: 'center',
          render: (_: any, record: any) => {
            return (
              <Button
                icon={<EditOutlined />}
                name={`edit-evropski-izvestaj-${record?.key}`}
                onClick={() => handleChangeStatus(record)}
              />
            );
          },
        },
      ]
    : [];

  const godina: number = new Date().getFullYear() - 1;

  const currentDate: string = new Date().toLocaleDateString('sr');

  /**
   * Method that handles selected report type
   * @param { EvropskiIzvestajTypeEnum } izvestajType
   */
  const handleChangeIzvestajType: SelectProps['onChange'] = (izvestajType: EvropskiIzvestajTypeEnum) => {
    setIzvestajType(izvestajType);
    evropskiIzvestajiStore
      .checkVisibilityForNewIntersection(izvestajType, godina)
      .catch(error => LoggerService.log(error));
  };

  /**
   * Method that fetches new data on the initial table rendering and after the page changing
   * @param { ITablePagination } pagination
   */
  const refetchData = (pagination: ITablePagination) => {
    if (!izvestajType) return Promise.reject();
    return evropskiIzvestajiStore.getEvropskiIzvestaj(izvestajType, godina, pagination);
  };

  /**
   * Method that handles to create a new intersection
   */
  const handleCreateIntersection = () => {
    if (!izvestajType) return Promise.reject();
    evropskiIzvestajiStore.createIntersection(izvestajType, godina).then();
  };

  /**
   * Use Effect hook that rerender template on izvestajType change
   */
  useEffect(() => {
    evropskiIzvestajiStore.clearObrazacIzvestaj();
  }, [izvestajType]);

  /**
   * Use Effect hook that resets izvestajType on initial rendering
   */
  useEffect(() => {
    setIzvestajType(null);
    itemForm.resetFields();
  }, []);

  const handleChangeStatusFormItemSubmit = () => {
    const values = { ...itemForm.getFieldsValue(true), id: selectedEvropskiIzvestaj?.id };
    LoggerService.log(values);
    if (izvestajType && values.status && values.id)
      evropskiIzvestajiStore.changeStatus(izvestajType, godina, values.id, values.status).then();
    modalStore.clearModal(`edit-status-evropski-izvestaj-modal`);
  };

  /**
   * Handler that generates Excel tables
   */
  const handleGenerateExcelFormItemSubmit = async () => {
    if (!izvestajType) return Promise.reject();
    evropskiIzvestajiStore
      .generateExcel(izvestajType, godina)
      .then(() => {
        handleSuccessNotify('Uspešno su generisane excel tabele.', 'top-right');
        evropskiIzvestajiStore.clearObrazacIzvestaj();
        setIzvestajType(null);
        modalStore.clearModal(`generate-excel-evropski-izvestaj-modal`);
      })
      .catch(() => handleErrorNotify(['Nastala je greška prilikom generisanja Excel tabela!']));
  };

  const initialValues = {
    status: selectedEvropskiIzvestaj?.status,
  };

  const buttons = [
    {
      title: 'EVROPSKI_IZVESTAJI.CANCEL_BUTTON',
    },
    {
      title: 'EVROPSKI_IZVESTAJI.CHANGE_BUTTON',
      onClick: () => handleChangeStatusFormItemSubmit(),
    },
  ];

  const generateExcelButtons = [
    {
      title: 'EVROPSKI_IZVESTAJI.CANCEL_BUTTON',
    },
    {
      title: 'EVROPSKI_IZVESTAJI.CREATE_BUTTON',
      onClick: () => handleGenerateExcelFormItemSubmit(),
    },
  ];

  const cancelSubtrack = () => {
    if (!izvestajType) return Promise.reject();
    evropskiIzvestajiStore.cancelSubtract(izvestajType, godina).then(() => setIzvestajType(null));
  };

  /**
   * Method that returns tsx template
   */
  return (
    <Content
      header={
        <Row gutter={16} id="izvestajHeader">
          <Col span={6}>
            <h1>{t('EVROPSKI_IZVESTAJI.EU_IZVESTAJI_TITLE')}</h1>
          </Col>
          <Col span={4}>
            {t('EVROPSKI_IZVESTAJI.GODINA')}:
            <Input value={godina} name="godina" disabled={true} />
          </Col>
          <Col span={6}>
            <label>{t('EVROPSKI_IZVESTAJI.VRSTA_IZVESTAJA')}:</label>
            <Select
              value={izvestajType}
              options={EVROPSKI_IZVESTAJI_TYPE_OPTIONS}
              name="tip-obrasca"
              onChange={handleChangeIzvestajType}
            />
          </Col>
          <Col span={8} className={styles.printButtonContainer}>
            <Row gutter={12}>
              <Col span={12}>
                <Tooltip>
                  <Popconfirm
                    title=""
                    description={t('EVROPSKI_IZVESTAJI.TOOLTIP_CREATE_INTERSECTION_CONFIRMATION')}
                    placement="bottom"
                    okText={t('YES')}
                    okButtonProps={{ disabled: false }}
                    cancelButtonProps={{ disabled: false }}
                    cancelText={t('NO')}
                    onConfirm={async () => handleCreateIntersection()}
                  >
                    <Button
                      disabled={!(izvestajType && evropskiIzvestajiStore.visibilityOfIntersection?.kreiran)}
                      type="primary"
                      name="evropski-izvestaj-create-intersection"
                      // onClick={handleCreateIntersection}
                    >
                      {t('EVROPSKI_IZVESTAJI.KREIRAJ_PRESEK')}
                    </Button>
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
          </Col>
        </Row>
      }
    >
      {izvestajType && evropskiIzvestajiStore.evropskiIzvestaj && (
        <>
          {evropskiIzvestajiStore.visibilityOfIntersection?.message && (
            <div className={styles.messageBox}>{evropskiIzvestajiStore.visibilityOfIntersection?.message}</div>
          )}
          <EvropskiIzvestajiFilters izvestajType={izvestajType} />
          <Row className={styles.operationBox}>
            <Col>
              <Button name="generate-excel-table" onClick={() => handleGenerateExcel()}>
                Generiši excel tabele
              </Button>
            </Col>
            <Row className={styles.cancelSubtractBox}>
              <Row className={styles.subtractDateBox}>
                <Col>
                  <span>{t('EVROPSKI_IZVESTAJI.INTERSECTION_DATE')}</span>
                </Col>
                <Col>
                  <Input value={currentDate} name="subtract-date" disabled={true} />
                </Col>
              </Row>
              <Col>
                <Tooltip>
                  <Popconfirm
                    title=""
                    description={t('EVROPSKI_IZVESTAJI.TOOLTIP_CANCEL_INTERSECTION_CONFIRMATION')}
                    placement="bottom"
                    okText={t('YES')}
                    okButtonProps={{ disabled: false }}
                    cancelButtonProps={{ disabled: false }}
                    cancelText={t('NO')}
                    onConfirm={async () => cancelSubtrack()}
                  >
                    <Button name="cancel-subtract">{t('EVROPSKI_IZVESTAJI.CANCEL_INTERSECTION')}</Button>
                  </Popconfirm>
                </Tooltip>
              </Col>
            </Row>
          </Row>
          <TableData
            name="evropski-izvestaji1"
            scrollConfig={['#evropskiIzvestajFilters', '#evropskiIzvestajHeader']}
            columns={columnsByEvropskiIzvestajType as ColumnProps<unknown>[]}
            dataSource={evropskiIzvestajiStore.evropskiIzvestaj?.items}
            refetchData={refetchData}
            storePagination={evropskiIzvestajiStore.evropskiIzvestajPagination}
            rowKey="key"
          />
        </>
      )}
      <EvropskiIzvestajiForm
        parentForm={itemForm}
        name="edit-status-evropski-izvestaj"
        title="EVROPSKI_IZVESTAJI.CHANGE_STATUS_FORM_TITLE"
        initialValues={initialValues}
        buttons={buttons}
        configPath={{ formListType: FormListType.EvropskiIzvestaji, formListSubType: EvropskiIzvestajLists.STATUS }}
      ></EvropskiIzvestajiForm>
      <EvropskiIzvestajiForm
        parentForm={itemForm}
        name="generate-excel-evropski-izvestaj"
        title="EVROPSKI_IZVESTAJI.GENERATE_EXCEL_TABLE"
        initialValues={{ locationPath: '' }}
        buttons={generateExcelButtons}
        configPath={{
          formListType: FormListType.EvropskiIzvestaji,
          formListSubType: EvropskiIzvestajLists.GENERATE_EXCEL,
        }}
      ></EvropskiIzvestajiForm>
    </Content>
  );
});
