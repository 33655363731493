import { Dayjs } from 'dayjs';
import { IGetMesto } from 'modules/sifarnici/store/mesto/mesto.types';

export enum DozvolaStatus {
  Aktivna = 'aktivna',
  Izmenjena = 'izmenjena',
  Oduzeta = 'oduzeta',
}

export interface IDozvolaRequest {
  operater_id: string;
  sifra_opstine_id: string;
  registarski_broj_dozvole: number;
  izdavalac_dozvole_id: string;
  broj_dosijea: string;
  vazi_od: Dayjs | string;
  vazi_do: Dayjs | string;
  naziv_postrojenja: string;
  adresa_postrojenja: string;
  opstina_postrojenja_id: string;
  opis_postrojenja: string;
  napomena: string;
  privremena_dozvola: 'da' | 'ne';
}
export interface IDozvolaResponse extends IDozvolaRequest {
  id: string;
  verzija: number;
  nacin_ponistavanja_dozvole_id: string;
  sifra_opstine: IGetMesto;
  opstina_postrojenja: IGetMesto;
}

export interface IDozvolaUpdate extends Partial<IDozvolaResponse> {
  datum: string;
}

export enum PregledDozvolaType {
  VrstaDozvole = 'vrsta_dozvole',
  IndeksniBroj = 'indeksni_broj',
}

export interface IDozvoleFilters {
  opstina_preduzeca_id?: string;
  registarski_broj_dozvole__ilike?: number;
  broj_dosijea__ilike?: string;
  datum_vazi_do__lte?: string;
  datum_vazi_od__gte?: string;
  izdavalac_dozvole_id?: string;
  verzija?: number;
  status_dozvole_aktivna?: boolean;
  status_dozvole_oduzeta?: boolean;
  status_dozvole_izmenjena?: boolean;
}
