import { IPagination } from 'typescript';
import { IOtpadRequest, IOtpadResponse } from './otpad.types';
import { paginationInitialState } from 'modules/nriz/nriz.constants';
import { makeAutoObservable } from 'mobx';
import { otpadService } from './otpad.service';
import to from 'await-to-js';
import { ITablePagination } from 'components/TableData/TableData';
import { SIFARNICI_ROUTES } from 'modules/sifarnici/sifarnici.types';
import { sifarniciStore } from 'modules/sifarnici/sifarnici.store';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';
import { IPaginatedResponse } from 'typescript/NrizTypes';
import { nrizService } from 'modules/nriz/nriz.service';
import { FiltersNameEnum, filtersStore } from 'store/filteri.store';

class OtpadStore {
  otpadList: IOtpadResponse[] = [];
  otpadPagination: IPagination = paginationInitialState;
  selectedOtpad: Partial<IOtpadResponse> = {
    skladistenje: false,
    sakupljanje: false,
    tretman_odlaganje: false,
    tretman_ponovno_iskoriscenje: false,
    transport: false,
  };

  constructor() {
    makeAutoObservable(this, {});
  }

  setOtpadList = (newList: IOtpadResponse[]) => {
    this.otpadList = newList;
  };

  setSelectedOtpad = (otpad: IOtpadResponse) => {
    this.selectedOtpad = otpad;
  };

  fetchOtpadList = async (pagination?: ITablePagination, dozvolaId?: string) => {
    if (!dozvolaId) return new Error('Dozvola ID missing');

    const { page, size } = pagination ?? this.otpadPagination;

    const [err, res] = await to(
      otpadService.fetchOtpadList({
        dozvola_id: dozvolaId,
        page,
        size,
        ...nrizService.pickFields(filtersStore.getFilters[FiltersNameEnum.DOZVOLE_OPERATERI]),
      })
    );

    if (err || !res) return Promise.reject(err);

    this.setOtpadList(res.items);
    return res.items;
  };

  createOtpad = async (payload: IOtpadRequest) => {
    const [err, res] = await to(otpadService.createOtpad(payload));

    if (err || !res) return Promise.reject(err);
    return res;
  };

  updateOtpadInfo = async (payload: Partial<IOtpadResponse>) => {
    if (!payload.id) return Promise.reject(new Error('No id provided!'));
    const [err, res] = await to(otpadService.updateOtpadInfo(payload.id, payload));

    if (err || !res) return Promise.reject(err);
  };

  fetchOtpadById = async (id: string) => {
    const [err, res] = await to(otpadService.fetchOtpadById(id));

    if (err || !res) return Promise.reject(err);
    this.setSelectedOtpad(res);
  };

  deleteOtpad = async (dozvolaId: string) => {
    if (!this.selectedOtpad.id) return Promise.reject(new Error('No otpad ID available!'));
    const [err, res] = await to(otpadService.deleteOtpad(this.selectedOtpad.id));

    if (err || !res) return Promise.reject(err);
    this.fetchOtpadList(this.otpadPagination, dozvolaId);
  };

  fetchIndeksniBrojOtpada = async (
    pagination: IPagination,
    search: string
  ): Promise<IPaginatedResponse<IGetWasteCatalogue>> => {
    const response = await sifarniciStore.fetchSifarnikOptions<IGetWasteCatalogue>(SIFARNICI_ROUTES.WASTE_CATALOGUE, {
      page: pagination.page,
      size: pagination.size,
      search: search,
      order_by: '+index_number',
    });
    return response;
  };
}

export const otpadStore = new OtpadStore();
