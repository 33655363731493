import dayjs from 'dayjs';
import { dozvoleRepo } from './dozvole.repo';
import { IDozvolaRequest, IDozvolaResponse, IDozvolaUpdate } from './dozvole.types';

class DozvoleService {
  fetchDozvoleList = (params: any) => {
    return dozvoleRepo.fetchDozvoleList(params);
  };

  createDozvola = (payload: IDozvolaRequest) => {
    return dozvoleRepo.createDozvola(payload);
  };

  updateDozvolaInfo = (id: string, payload: IDozvolaUpdate) => {
    return dozvoleRepo.updateDozvolaInfo(id, payload);
  };

  fetchDozvolaById = (id: string) => {
    return dozvoleRepo.fetchDozvolaById(id);
  };

  deleteDozvola = (id: string) => {
    return dozvoleRepo.deleteDozvola(id);
  };

  formatPayloadDates<T extends IDozvolaRequest | IDozvolaResponse>(dozvola: T): T {
    const vazi_do = dayjs(dozvola.vazi_do).format('YYYY-MM-DD');
    const vazi_od = dayjs(dozvola.vazi_od).format('YYYY-MM-DD');

    return { ...dozvola, vazi_do, vazi_od };
  }

  formatResponseDates(dozvola: IDozvolaResponse) {
    const vazi_do = dayjs(dozvola.vazi_do);
    const vazi_od = dayjs(dozvola.vazi_od);

    return { ...dozvola, vazi_do, vazi_od };
  }
}

export const dozvoleService = new DozvoleService();
