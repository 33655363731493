import * as Sentry from '@sentry/react';

import { ENVIRONMENT_TYPES } from '../utils/constants/environment-types.const';
import { checkEnvironmentType } from '../utils/helpers/sentry.helper';

/**
 * Logger service
 */
export class LoggerService {
  /**
   * Log logger, If environment isn't production, then throw log in browser console
   * @param { any } value
   * @param { Array<any> } rest
   */
  static log(value: any, ...rest: any[]) {
    if (!checkEnvironmentType(ENVIRONMENT_TYPES.PRODUCTION)) {
      // eslint-disable-next-line no-console
      console.log(value, ...rest);
    }
  }

  /**
   * Error logger. If environment is production then send error on Sentry.
   * If environment isn't production then throw error in browser console.
   * @param { Error | string } error
   */
  static error(error: Error | string) {
    if (checkEnvironmentType(ENVIRONMENT_TYPES.PRODUCTION)) {
      Sentry.captureException(error);
    } else {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }
}
