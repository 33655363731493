import { ApiResponse } from 'modules/axios';
import { preseciRepo } from './preseci.repo';
import { PreseciTypeEnum } from './preseci.constants';
import { IEmptyResponse, IPaginatedApiResponse, IPostResponse } from 'typescript/NrizTypes';
import dayjs from 'dayjs';
import {
  FiltersPreseciList,
  FiltersSinglePresek,
  FiltersWithPaginationPreseciList,
  FiltersWithPaginationSinglePresek,
  GetPreseciListTypes,
  GetSinglePresekTypes,
  ICreatePresekPayload,
  IPosaljiPresekForm,
  IPosaljiPtp2PresekForm,
  IUkljuciIskljuciPreseciPoFilterima,
} from './preseci.types';
import { IGetOtvSinglePresek } from './otvPresek/otvPresek.types';
import { Ptp2ProductType, Ptp2RazlogIskljucenja } from './ptp2Presek/ptp2Presek.types';

class PreseciService {
  fetchPreseciList = (
    preseciType: PreseciTypeEnum,
    filters: FiltersWithPaginationPreseciList
  ): IPaginatedApiResponse<GetPreseciListTypes> => {
    return preseciRepo.fetchPreseciList(preseciType, filters);
  };

  createSinglePresek = (preseciType: PreseciTypeEnum, payload?: ICreatePresekPayload): ApiResponse<IPostResponse> => {
    return preseciRepo.createSinglePresek(preseciType, payload);
  };

  fetchExistingSinglePresekList = <T>(
    preseciType: PreseciTypeEnum,
    datumPreseka: string,
    filters: FiltersWithPaginationSinglePresek
  ): IPaginatedApiResponse<T> => {
    return preseciRepo.fetchExistingSinglePresekList<T>(preseciType, datumPreseka, filters);
  };

  fetchSinglePresekList = <T>(
    preseciType: PreseciTypeEnum,
    filters: FiltersWithPaginationSinglePresek
  ): IPaginatedApiResponse<T> => {
    return preseciRepo.fetchSinglePresekList<T>(preseciType, filters);
  };

  deleteSinglePresek = (preseciType: PreseciTypeEnum): ApiResponse<IEmptyResponse> => {
    return preseciRepo.deleteSinglePresek(preseciType);
  };

  posaljiSinglePresek = (
    preseciType: PreseciTypeEnum,
    brojIzvestaja: IPosaljiPresekForm | IPosaljiPtp2PresekForm
  ): ApiResponse<IEmptyResponse> => {
    return preseciRepo.posaljiSinglePresek(preseciType, brojIzvestaja);
  };

  iskljuciStavkuPresekaPoId = (preseciType: PreseciTypeEnum, id: string): ApiResponse<IEmptyResponse> => {
    return preseciRepo.iskljuciStavkuPresekaPoId(preseciType, id);
  };

  iskljuciStavkePoFilterima = (
    preseciType: PreseciTypeEnum,
    filters: IUkljuciIskljuciPreseciPoFilterima
  ): ApiResponse<IEmptyResponse> => {
    return preseciRepo.iskljuciStavkePoFilterima(preseciType, filters);
  };

  ukljuciStavkuPresekaPoId = (preseciType: PreseciTypeEnum, id: string): ApiResponse<IEmptyResponse> => {
    return preseciRepo.ukljuciStavkuPresekaPoId(preseciType, id);
  };

  ukljuciStavkePoFilterima = (
    preseciType: PreseciTypeEnum,
    filters: IUkljuciIskljuciPreseciPoFilterima
  ): ApiResponse<IEmptyResponse> => {
    return preseciRepo.ukljuciStavkePoFilterima(preseciType, filters);
  };

  exportPreseciListExcel = (presekType: PreseciTypeEnum, filters: FiltersPreseciList): Promise<Blob> => {
    return preseciRepo.exportPreseciListExcel(presekType, filters);
  };

  exportSinglePresekExcel = (presekType: PreseciTypeEnum, filters: FiltersSinglePresek): Promise<Blob> => {
    return preseciRepo.exportSinglePresekExcel(presekType, filters);
  };

  exportExistingSinglePresekListExcel = (
    presekType: PreseciTypeEnum,
    datumPreseka: string,
    filters: FiltersSinglePresek
  ): Promise<Blob> => {
    return preseciRepo.exportExistingSinglePresekListExcel(presekType, datumPreseka, filters);
  };

  modifyFiltersPreseciListPayload = (payload: FiltersPreseciList): FiltersPreseciList => {
    return Object.fromEntries(
      Object.entries(payload).map(([key, value]) => {
        if (key.includes('__gte') && value) {
          return [key, dayjs(value as Date).format('YYYY-MM-DDT00:00:00.000[Z]')];
        }
        if (key.includes('__lte') && value) {
          return [key, dayjs(value as Date).format('YYYY-MM-DDT23:59:59.999[Z]')];
        }
        return [key, value];
      })
    );
  };

  modifyFiltersSinglePresekPayload = (payload: FiltersSinglePresek): FiltersSinglePresek => {
    return Object.fromEntries(
      Object.entries(payload).map(([key, value]) => {
        if (key === 'godina' && value) {
          return [key, dayjs(value).format('YYYY')];
        }
        return [key, value];
      })
    );
  };

  automatskoIskljucivanjeUkljucivanjePoFilterima = (
    presekType: PreseciTypeEnum,
    list: GetSinglePresekTypes[],
    filters: IUkljuciIskljuciPreseciPoFilterima,
    iskljuci?: boolean
  ): GetSinglePresekTypes[] => {
    const changedList: GetSinglePresekTypes[] = [];
    let newItems: GetSinglePresekTypes[] = list.map((item: GetSinglePresekTypes) => {
      const allFiltersMatch = Object.entries(filters).every(([filterKey, filterValue]) => {
        if (!filterValue) return true;
        const itemValue = item[filterKey as keyof GetSinglePresekTypes];
        return itemValue && itemValue.toString().replace(/-/g, '') === filterValue.toString().replace(/-/g, '');
      });

      if (allFiltersMatch) {
        const updatedItem = { ...item, iskljucen: !!iskljuci, automatski_iskljucen: !!iskljuci };
        changedList.push(updatedItem);
        return updatedItem;
      }

      return item;
    });

    changedList.forEach((item: GetSinglePresekTypes) => {
      newItems = this.automatskoIskljucivanjeUkljucivanjeStavki(presekType, newItems, item, iskljuci);
    });

    return newItems;
  };

  checkIfYearIncluded = (presekType: PreseciTypeEnum) => {
    return preseciRepo.checkIfYearIncluded(presekType);
  };

  automatskoIskljucivanjeUkljucivanjeStavki = (
    presekType: PreseciTypeEnum,
    list: GetSinglePresekTypes[],
    payload: GetSinglePresekTypes | IUkljuciIskljuciPreseciPoFilterima,
    iskljuci = false
  ): GetSinglePresekTypes[] => {
    const normalizeId = (id: string | undefined) => id?.replace(/-/g, '');

    return list.map((item: GetSinglePresekTypes) => {
      const isSameCompany = normalizeId(payload.obrazac_preduzece_id) === normalizeId(item.obrazac_preduzece_id);

      if (!isSameCompany) return item;

      switch (presekType) {
        case PreseciTypeEnum.AAO1: {
          if (payload.godina === item.godina) {
            return { ...item, iskljucen: iskljuci, automatski_iskljucen: iskljuci };
          }
          return item;
        }

        case PreseciTypeEnum.OTV: {
          const payloadPostrojenjeId = normalizeId((payload as IGetOtvSinglePresek).postrojenje_id);
          const itemPostrojenjeId = normalizeId((item as IGetOtvSinglePresek).postrojenje_id);
          const payloadId = normalizeId((payload as IGetOtvSinglePresek).id);
          const itemId = normalizeId((item as IGetOtvSinglePresek).id);

          if (payloadId === itemId) {
            return { ...item, iskljucen: iskljuci };
          }

          if (payloadPostrojenjeId === itemPostrojenjeId) {
            return { ...item, automatski_iskljucen: iskljuci };
          }
          return item;
        }

        case PreseciTypeEnum.PTP2: {
          if ('products_nr_id' in payload && 'products_nr_id' in item) {
            const payloadId = normalizeId(payload.obrazac_preduzece_id);
            const itemId = normalizeId(item.obrazac_preduzece_id);
            const payloadProizvodId = normalizeId(payload.products_nr_id);
            const itemProizvodId = normalizeId(item.products_nr_id);
            const { razlog_iskljucenja } = payload;
            if (payload.godina === item.godina && payloadId === itemId && payloadProizvodId === itemProizvodId) {
              return { ...item, iskljucen: true, razlog_iskljucenja };
            }
          }
          return item;
        }

        default:
          return item;
      }
    });
  };

  isSwitchIskljucenoDisabled = (
    presekType: PreseciTypeEnum,
    payload: GetSinglePresekTypes,
    datumPreseka?: string | null | undefined
  ): boolean => {
    switch (presekType) {
      case PreseciTypeEnum.AAO1:
        return !!datumPreseka;

      case PreseciTypeEnum.OTV: {
        if ('automatski_iskljucen' in payload) {
          return !!(payload.automatski_iskljucen || datumPreseka);
        }
        return false;
      }

      default:
        return false;
    }
  };

  automatskoIskljucivanjePtp2 = (product: Ptp2ProductType) => {
    return preseciRepo.automatskoIskljucivanjePtp2(product);
  };

  iskljucivanjePtp2Prijave = (id: string, razlog_iskljucenja: Ptp2RazlogIskljucenja) => {
    return preseciRepo.iskljucivanjePtp2Prijave(id, razlog_iskljucenja);
  };
}
export const preseciService = new PreseciService();
