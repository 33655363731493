import { IGetDRList } from 'modules/sifarnici/store/dRList/dRList.types';
import { IGetWasteCatalogue } from 'modules/sifarnici/store/wasteCatalogue/wasteCatalogue.types';

export interface IOtpadRequest {
  indeksni_broj_otpada_id: string;
  sakupljanje: boolean;
  transport: boolean;
  skladistenje: boolean;
  tretman_odlaganje: boolean;
  tretman_ponovno_iskoriscenje: boolean;
  dozvola_id: string;
  nacin_postupanja_sa_otpadom: INacinPostupanjaSaOtpadom[];
}

export interface IOtpadResponse extends IOtpadRequest {
  id: string;
  indeksni_broj_otpada: IGetWasteCatalogue;
}

export interface INacinPostupanjaSaOtpadom {
  dr_oznaka_id: string;
  dr_oznaka: IGetDRList;
  kapacitet_u_jednom_trenutku: number;
  kolicina_godisnje: number;
}

export enum DozvoleOtpadList {
  NacinPostupanja = 1,
}

export interface IOtpadDozvoleFilters {
  sakupljanje?: boolean;
  transport?: boolean;
  skladistenje?: boolean;
  tretman_odlaganje?: boolean;
  tretman_ponovno_iskoriscenje?: boolean;
  indeksni_broj_otpada_id?: string;
}
