import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { Form, FormInstance } from 'antd';
import dayjs from 'dayjs';

import { isChromeOrEdge } from 'utils/helpers/browser-system.helper';
import { getSaveFilePicker } from 'utils/helpers/file.helper';
import { evropskiIzvestajiStore } from 'modules/evropskiIzvestaji/evropskiIzvestaji.store';
import { Input } from 'components/Input/Input';

import './GenerateExcelForm.scss';

/**
 * Generate Excel Form component
 */
export const GenerateExcelForm = observer(() => {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const { t } = useTranslation();
  const form: FormInstance = Form.useFormInstance();

  const chooseLocationPath = async () => {
    const fileHandle = await getSaveFilePicker(`eu_registar_${dayjs().format('DD-MM-YYYY')}`, 'application/zip', 'zip');
    const writable = await fileHandle.createWritable();
    form?.setFieldValue('locationPath', `./eu_registar_${dayjs().format('DD-MM-YYYY')}`);
    evropskiIzvestajiStore.setWritable(writable);
    setShowMessage(true);
  };

  useEffect(() => {
    setShowMessage(false);
  }, []);

  return (
    <div className="content-box">
      <Form.Item
        name={'locationPath'}
        label={t('EVROPSKI_IZVESTAJI.LOCATION_PATH_LABEL')}
        labelCol={{ span: 12 }}
        wrapperCol={{ span: 12 }}
      >
        <Input
          name="locationPath"
          disabled={showMessage || !isChromeOrEdge()}
          value={form?.getFieldValue('locationPath')}
          onClick={chooseLocationPath}
        />
      </Form.Item>
      {showMessage && <span>{t('EVROPSKI_IZVESTAJI.CHROME_MESSAGE')}</span>}
      {!isChromeOrEdge() && <span>{t('EVROPSKI_IZVESTAJI.NO_CHROME_MESSAGE')}</span>}
    </div>
  );
});
